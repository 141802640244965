export default {

  RESULT_CODE: {
    IA_ERROR_INVALID_DTO: 'Es ist ein Fehler bei der Anfrage aufgetreten (Fehler in DTO).',
    IA_ERROR_SYSTEM_ERROR: 'Es ist ein Fehler bei der Anfrage aufgetreten (Service nicht verfügbar).',
    LIMA_WARNING_USER_NOT_FOUND: 'Dieser Benutzer wurde nicht auf dem LIMA gefunden.',
    OSC_WARNING_NO_ORDERS_FOR_USER: 'Dieser Benutzer hat keine Daten auf dem OSC.',
    IDP_WARN: 'Es ist ein Problem auf dem IDP aufgetreten.',
    IDP_ERROR_NOT_FOUND: 'Der Benutzer wurde nicht auf dem IDP gefunden.',
    OSC_NO_LICENSE_FOR_USER: 'Es wurden leider keine Lizenzen für den Benutzer gefunden.',
    IA_ERROR_PERMISSION_DENIED: 'Self Permission denied in: devices Get For User.',
    HD_NO_COUPON_SEARCH_DETAIL: 'Es wurden leider keine Informationen zu diesem Coupon gefunden.',
    AUA_NO_MANDANT_GROUP: 'Es konnten keine Mandanten Gruppen geladen werden.',
    AUA_NO_PROVIDER_GROUP: 'Es konnten keine Provider geladen werden.',
    AUTH_NOT_AUTHORIZED_LOGOUT: 'Sie wurden automatisch ausgeloggt.',
    AUTH_NO_ROLE_PERMISSION_LOGOUT: 'Sie wurden automatisch ausgeloggt. Sie sind mit Ihrer Rolle nicht berechtigt.',
    AUTH_TOKEN_EXPIRED_LOGOUT: 'Sie wurden automatisch ausgeloggt. Die Session ist abgelaufen, bitte loggen Sie sich erneut ein.',
    AUA_ERROR_FORBIDDEN: 'Sie sind für diese Änderung nicht berechtigt.',
    AUA_ERROR_BAD_REQUEST: 'Request konnte nicht verarbeitet werden.',
    AUA_WARNING_NOT_FOUND: 'Benutzer wurde nicht gefunden.',
    HD_COUPON_SEARCH_ISSUED: 'Es wurden Warnungen bei der Coupon-Suche festgestellt.'
  },

  /**
   * Get a resultCode translation
   * @param resultCode to look up
   */
  getMessage: function(resultCode: string) {
    const message = typeof this.RESULT_CODE[resultCode] === 'undefined' ? resultCode : this.RESULT_CODE[resultCode];
    return message;
  }
};
