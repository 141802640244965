/**
 * ioneaccess Order System Coordinator (OSC) API
 * API for active external 'Order System Coordinator' access. This document defines an interface for pushing orders into the ionaccess order system.
 *
 * OpenAPI spec version: 1.4.6
 * Contact: florian.mischler@ionesoft.ch
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CacheFlushResponse1 } from '../model/cacheFlushResponse1';
import { CouponChangeRequest1 } from '../model/couponChangeRequest1';
import { CouponChangeResponse1 } from '../model/couponChangeResponse1';
import { CouponChangeStateRequest1 } from '../model/couponChangeStateRequest1';
import { CouponChangeStateResponse1 } from '../model/couponChangeStateResponse1';
import { CouponLookUpRequest1 } from '../model/couponLookUpRequest1';
import { CouponLookUpResponse1 } from '../model/couponLookUpResponse1';
import { CouponSearchRequest1 } from '../model/couponSearchRequest1';
import { CouponSearchResponse1 } from '../model/couponSearchResponse1';
import { OrderSystemRegisterRequest1 } from '../model/orderSystemRegisterRequest1';
import { OrderSystemRegisterResponse1 } from '../model/orderSystemRegisterResponse1';
import { OrderSystemUserRelationGetInfoRequest1 } from '../model/orderSystemUserRelationGetInfoRequest1';
import { OrderSystemUserRelationGetInfoResponse1 } from '../model/orderSystemUserRelationGetInfoResponse1';
import { OrderSystemUserRelationNotifyChangesRequest1 } from '../model/orderSystemUserRelationNotifyChangesRequest1';
import { OrderSystemUserRelationNotifyChangesResponse1 } from '../model/orderSystemUserRelationNotifyChangesResponse1';
import { UserDeleteRequest1 } from '../model/userDeleteRequest1';
import { UserDeleteResponse1 } from '../model/userDeleteResponse1';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OSCSystem1Service {

    protected basePath = 'https://igate.beook.work/osc/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Flush cache
     * Provides ability to flush entire cache
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cacheFlush(X_Ia_Correlation_Id: string, observe?: 'body', reportProgress?: boolean): Observable<CacheFlushResponse1>;
    public cacheFlush(X_Ia_Correlation_Id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CacheFlushResponse1>>;
    public cacheFlush(X_Ia_Correlation_Id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CacheFlushResponse1>>;
    public cacheFlush(X_Ia_Correlation_Id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling cacheFlush.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CacheFlushResponse1>(`${this.basePath}/cache/flush`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change different values of coupon w/ code.
     * Change different values of coupon with coupon code and concerning user.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param CouponChangeRequest1 Request for changing coupons
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public couponChange(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CouponChangeRequest1: CouponChangeRequest1, observe?: 'body', reportProgress?: boolean): Observable<CouponChangeResponse1>;
    public couponChange(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CouponChangeRequest1: CouponChangeRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponChangeResponse1>>;
    public couponChange(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CouponChangeRequest1: CouponChangeRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponChangeResponse1>>;
    public couponChange(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CouponChangeRequest1: CouponChangeRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling couponChange.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling couponChange.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling couponChange.');
        }

        if (CouponChangeRequest1 === null || CouponChangeRequest1 === undefined) {
            throw new Error('Required parameter CouponChangeRequest1 was null or undefined when calling couponChange.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CouponChangeResponse1>(`${this.basePath}/coupon/change`,
            CouponChangeRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change state of coupon w/ code.
     * Change state of coupon with coupon code, desired state and concerning user.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param CouponChangeStateRequest1 Request for changing state of coupons
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public couponChangeState(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CouponChangeStateRequest1: CouponChangeStateRequest1, observe?: 'body', reportProgress?: boolean): Observable<CouponChangeStateResponse1>;
    public couponChangeState(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CouponChangeStateRequest1: CouponChangeStateRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponChangeStateResponse1>>;
    public couponChangeState(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CouponChangeStateRequest1: CouponChangeStateRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponChangeStateResponse1>>;
    public couponChangeState(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CouponChangeStateRequest1: CouponChangeStateRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling couponChangeState.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling couponChangeState.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling couponChangeState.');
        }

        if (CouponChangeStateRequest1 === null || CouponChangeStateRequest1 === undefined) {
            throw new Error('Required parameter CouponChangeStateRequest1 was null or undefined when calling couponChangeState.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CouponChangeStateResponse1>(`${this.basePath}/coupon/changeState`,
            CouponChangeStateRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Look up coupons w/ for user.
     * Look up all coupons for user on specific order system.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param CouponLookUpRequest1 Request for looking up coupons for user.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public couponLookUp(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CouponLookUpRequest1: CouponLookUpRequest1, observe?: 'body', reportProgress?: boolean): Observable<CouponLookUpResponse1>;
    public couponLookUp(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CouponLookUpRequest1: CouponLookUpRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponLookUpResponse1>>;
    public couponLookUp(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CouponLookUpRequest1: CouponLookUpRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponLookUpResponse1>>;
    public couponLookUp(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CouponLookUpRequest1: CouponLookUpRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling couponLookUp.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling couponLookUp.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling couponLookUp.');
        }

        if (CouponLookUpRequest1 === null || CouponLookUpRequest1 === undefined) {
            throw new Error('Required parameter CouponLookUpRequest1 was null or undefined when calling couponLookUp.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CouponLookUpResponse1>(`${this.basePath}/coupon/lookUp`,
            CouponLookUpRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search coupon w/ code
     * Search coupon with coupon code
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param CouponSearchRequest1 Request for searching coupons
     * @param quicksearch \&quot;Bool determining if coupon search should only return coupon codes without license orders attached (true) or not (false). quicksearch&#x3D;true: only returns coupon codes,  quicksearch&#x3D;false: returns coupons with all their license order items.\&quot; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public couponSearch(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CouponSearchRequest1: CouponSearchRequest1, quicksearch?: boolean, observe?: 'body', reportProgress?: boolean): Observable<CouponSearchResponse1>;
    public couponSearch(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CouponSearchRequest1: CouponSearchRequest1, quicksearch?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponSearchResponse1>>;
    public couponSearch(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CouponSearchRequest1: CouponSearchRequest1, quicksearch?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponSearchResponse1>>;
    public couponSearch(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CouponSearchRequest1: CouponSearchRequest1, quicksearch?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling couponSearch.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling couponSearch.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling couponSearch.');
        }

        if (CouponSearchRequest1 === null || CouponSearchRequest1 === undefined) {
            throw new Error('Required parameter CouponSearchRequest1 was null or undefined when calling couponSearch.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (quicksearch !== undefined && quicksearch !== null) {
            queryParameters = queryParameters.set('quicksearch', <any>quicksearch);
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CouponSearchResponse1>(`${this.basePath}/coupon/search`,
            CouponSearchRequest1,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Attach user with order system
     * Attach a user with an order system
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param OrderSystemRegisterRequest1 Orders to put
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orderSystemRegister(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderSystemRegisterRequest1: OrderSystemRegisterRequest1, observe?: 'body', reportProgress?: boolean): Observable<OrderSystemRegisterResponse1>;
    public orderSystemRegister(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderSystemRegisterRequest1: OrderSystemRegisterRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderSystemRegisterResponse1>>;
    public orderSystemRegister(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderSystemRegisterRequest1: OrderSystemRegisterRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderSystemRegisterResponse1>>;
    public orderSystemRegister(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderSystemRegisterRequest1: OrderSystemRegisterRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling orderSystemRegister.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling orderSystemRegister.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling orderSystemRegister.');
        }

        if (OrderSystemRegisterRequest1 === null || OrderSystemRegisterRequest1 === undefined) {
            throw new Error('Required parameter OrderSystemRegisterRequest1 was null or undefined when calling orderSystemRegister.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderSystemRegisterResponse1>(`${this.basePath}/orderSystem/register`,
            OrderSystemRegisterRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get info for user w/ optional order system
     * Get info of order system relation (last order system update, needs update) for user w/ optional order system. If no order system is given return all info relations of user.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param OrderSystemUserRelationGetInfoRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orderSystemUserRelationGetInfo(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderSystemUserRelationGetInfoRequest1: OrderSystemUserRelationGetInfoRequest1, observe?: 'body', reportProgress?: boolean): Observable<OrderSystemUserRelationGetInfoResponse1>;
    public orderSystemUserRelationGetInfo(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderSystemUserRelationGetInfoRequest1: OrderSystemUserRelationGetInfoRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderSystemUserRelationGetInfoResponse1>>;
    public orderSystemUserRelationGetInfo(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderSystemUserRelationGetInfoRequest1: OrderSystemUserRelationGetInfoRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderSystemUserRelationGetInfoResponse1>>;
    public orderSystemUserRelationGetInfo(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderSystemUserRelationGetInfoRequest1: OrderSystemUserRelationGetInfoRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling orderSystemUserRelationGetInfo.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling orderSystemUserRelationGetInfo.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling orderSystemUserRelationGetInfo.');
        }

        if (OrderSystemUserRelationGetInfoRequest1 === null || OrderSystemUserRelationGetInfoRequest1 === undefined) {
            throw new Error('Required parameter OrderSystemUserRelationGetInfoRequest1 was null or undefined when calling orderSystemUserRelationGetInfo.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderSystemUserRelationGetInfoResponse1>(`${this.basePath}/orderSystemUserRelation/getInfo`,
            OrderSystemUserRelationGetInfoRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Method to notify changes in orders of a user.
     * Method to notify changes in orders of a user in a specific order system. Used by HD and ExOS.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param OrderSystemUserRelationNotifyChangesRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orderSystemUserRelationNotifyChanges(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderSystemUserRelationNotifyChangesRequest1: OrderSystemUserRelationNotifyChangesRequest1, observe?: 'body', reportProgress?: boolean): Observable<OrderSystemUserRelationNotifyChangesResponse1>;
    public orderSystemUserRelationNotifyChanges(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderSystemUserRelationNotifyChangesRequest1: OrderSystemUserRelationNotifyChangesRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderSystemUserRelationNotifyChangesResponse1>>;
    public orderSystemUserRelationNotifyChanges(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderSystemUserRelationNotifyChangesRequest1: OrderSystemUserRelationNotifyChangesRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderSystemUserRelationNotifyChangesResponse1>>;
    public orderSystemUserRelationNotifyChanges(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderSystemUserRelationNotifyChangesRequest1: OrderSystemUserRelationNotifyChangesRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling orderSystemUserRelationNotifyChanges.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling orderSystemUserRelationNotifyChanges.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling orderSystemUserRelationNotifyChanges.');
        }

        if (OrderSystemUserRelationNotifyChangesRequest1 === null || OrderSystemUserRelationNotifyChangesRequest1 === undefined) {
            throw new Error('Required parameter OrderSystemUserRelationNotifyChangesRequest1 was null or undefined when calling orderSystemUserRelationNotifyChanges.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderSystemUserRelationNotifyChangesResponse1>(`${this.basePath}/orderSystemUserRelation/notifyChanges`,
            OrderSystemUserRelationNotifyChangesRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes user w/ all relations.
     * Deletes the given users and all their relations.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param UserDeleteRequest1 Request with all the users to be deleted.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userDelete(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, UserDeleteRequest1: UserDeleteRequest1, observe?: 'body', reportProgress?: boolean): Observable<UserDeleteResponse1>;
    public userDelete(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, UserDeleteRequest1: UserDeleteRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDeleteResponse1>>;
    public userDelete(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, UserDeleteRequest1: UserDeleteRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDeleteResponse1>>;
    public userDelete(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, UserDeleteRequest1: UserDeleteRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling userDelete.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling userDelete.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling userDelete.');
        }

        if (UserDeleteRequest1 === null || UserDeleteRequest1 === undefined) {
            throw new Error('Required parameter UserDeleteRequest1 was null or undefined when calling userDelete.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserDeleteResponse1>(`${this.basePath}/user/delete`,
            UserDeleteRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
