import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../material/material.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NavigationComponent} from './component/navigation/navigation.component';
import {RouterModule} from '@angular/router';
import {PageActionsComponent} from './component/page-actions/page-actions.component';
import {LoadingSpinnerComponent} from './component/loading-spinner/loading-spinner.component';
import {LineBreakDatePipe} from './pipes/line-break-date.pipe';
import {PopupProgressFeedbackComponent} from './component/popup-progress-feedback/popup-progress-feedback.component';
import {PopupConfirmationDialogComponent} from './component/popup-confirmation-dialog/popup-confirmation-dialog.component';
import {PopupErrorDialogComponent} from './component/popup-error-dialog/popup-error-dialog.component';
import {PopupDialogManagerComponent} from './component/popup-dialog-manager/popup-dialog-manager.component';
import {NotificationComponent} from './component/notification/notification.component';
import {IfRoleDirective} from './directives/ifRole.directive';
import {PopupSetMandantGroupsComponent} from './component/popup-set-mandant-groups/popup-set-mandant-groups.component';

@NgModule({
  declarations: [
    PopupDialogManagerComponent,
    PopupConfirmationDialogComponent,
    PopupErrorDialogComponent,
    PopupProgressFeedbackComponent,
    NavigationComponent,
    PageActionsComponent,
    LoadingSpinnerComponent,
    LineBreakDatePipe,
    NotificationComponent,
    IfRoleDirective,
    PopupSetMandantGroupsComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule
  ],
  exports: [
    PopupDialogManagerComponent,
    PopupErrorDialogComponent,
    PopupConfirmationDialogComponent,
    PopupProgressFeedbackComponent,
    PopupSetMandantGroupsComponent,
    NavigationComponent,
    PageActionsComponent,
    LoadingSpinnerComponent,
    IfRoleDirective,
    LineBreakDatePipe,
    MaterialModule,
    FlexLayoutModule,
    NotificationComponent
  ]
})
export class SharedModule {
}
