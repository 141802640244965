import { Subject } from 'rxjs';
import { AuaApiService } from '../../../service/aua-api.service';
import { map } from 'rxjs/operators';
import { MessengerService } from '../../shared/service/messenger.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../service/aua-api.service";
import * as i2 from "../../shared/service/messenger.service";
var ExtendedSearchService = /** @class */ (function () {
    function ExtendedSearchService(auaApiService, messengerService) {
        this.auaApiService = auaApiService;
        this.messengerService = messengerService;
        this.searchRequestSubject = new Subject();
    }
    ExtendedSearchService.prototype.storeSearchFormGroup = function (formGroup) {
        this.storedSearchFormGroup = formGroup;
    };
    ExtendedSearchService.prototype.storeSearchTableData = function (tableData) {
        this.storedSearchTableData = tableData;
    };
    ExtendedSearchService.prototype.getStoredSearchTableData = function () {
        return this.storedSearchTableData;
    };
    ExtendedSearchService.prototype.getStoredSearchFormGroup = function () {
        return this.storedSearchFormGroup;
    };
    ExtendedSearchService.prototype.getSearchRequestSubject = function () {
        return this.searchRequestSubject;
    };
    ExtendedSearchService.prototype.getProviderList = function () {
        var _this = this;
        return this.auaApiService.getProviders().pipe(map(function (response) {
            var detailResultResponse = response.provider_list_detail_response;
            var returnProviders = [];
            if (typeof detailResultResponse.providers !== 'undefined') {
                returnProviders = detailResultResponse.providers;
            }
            else {
                _this.messengerService.sendNotification('AUA_NO_PROVIDER_GROUP', 'AUA | listProviders');
            }
            return returnProviders;
        }));
    };
    ExtendedSearchService.prototype.getMandantGroupList = function () {
        var _this = this;
        return this.auaApiService.listMandantGroups().pipe(map(function (response) {
            var groupListDetailResponse = response.group_list_detail_response;
            var groups = groupListDetailResponse.groups;
            var returnGroup = [];
            if (typeof groups !== 'undefined') {
                var makeUnique = new Set(groups); // Set eliminiates duplicates.
                var cleanedGroups = Array.from(makeUnique); // Revert back to array
                returnGroup = cleanedGroups.filter(function (e) { return e !== ''; });
            }
            else {
                _this.messengerService.sendNotification('AUA_NO_MANDANT_GROUP', 'AUA | listGroups');
            }
            return returnGroup;
        }));
    };
    /**
     * @param formData with data for search
     */
    ExtendedSearchService.prototype.extendedSearch = function (formData) {
        var request = formData.value;
        return this.auaApiService.extendedSearchUser(request).pipe(map(function (response) {
            var detailResultResponse = response.user_extended_search_detail_response;
            return detailResultResponse.users;
        }));
    };
    ExtendedSearchService.ngInjectableDef = i0.defineInjectable({ factory: function ExtendedSearchService_Factory() { return new ExtendedSearchService(i0.inject(i1.AuaApiService), i0.inject(i2.MessengerService)); }, token: ExtendedSearchService, providedIn: "root" });
    return ExtendedSearchService;
}());
export { ExtendedSearchService };
