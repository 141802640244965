import {NgModule} from '@angular/core';
import {OAuthModule, OAuthModuleConfig} from 'angular-oauth2-oidc';
import {Settings} from '../../models/settings';
import {APP_CONFIGURATION} from '../../service/settings.http.service';
import {AuthCallbackComponent} from './component/auth-callback/auth-callback.component';
import {MatProgressBarModule} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
    declarations: [
        AuthCallbackComponent
    ],
    imports: [
        MatProgressBarModule,
        FlexLayoutModule,
        OAuthModule.forRoot()
    ],
    providers: [
      {
        provide: OAuthModuleConfig,
        useFactory: (config: Settings) => config.oicdClientSettings,
        deps: [APP_CONFIGURATION]
      }
    ]
})
export class AuthenticationModule {
}
