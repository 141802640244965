/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-information.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/component/loading-spinner/loading-spinner.component.ngfactory";
import * as i3 from "../../../shared/component/loading-spinner/loading-spinner.component";
import * as i4 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "@angular/router";
import * as i10 from "@angular/flex-layout/flex";
import * as i11 from "@angular/flex-layout/core";
import * as i12 from "../../../shared/directives/ifRole.directive";
import * as i13 from "../../../authentication/services/auth.service";
import * as i14 from "@angular/common";
import * as i15 from "./user-information.component";
import * as i16 from "../../../coupon-details/service/coupon-details.service";
import * as i17 from "../../service/tab.service";
var styles_UserInformationComponent = [i0.styles];
var RenderType_UserInformationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserInformationComponent, data: {} });
export { RenderType_UserInformationComponent as RenderType_UserInformationComponent };
function View_UserInformationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading-spinner", [], null, null, null, i2.View_LoadingSpinnerComponent_0, i2.RenderType_LoadingSpinnerComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoadingSpinnerComponent, [], { diameterSize: [0, "diameterSize"] }, null)], function (_ck, _v) { var currVal_0 = 20; _ck(_v, 1, 0, currVal_0); }, null); }
function View_UserInformationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "admin-theme"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["color", "primary"], ["mat-button", ""], ["routerLink", "/admin/details"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(3, 16384, null, 0, i9.RouterLink, [i9.Router, i9.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, 0, ["Admin-Bereich"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 2, 0, currVal_2); var currVal_3 = "/admin/details"; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_UserInformationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["fxLayout", "row"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i10.DefaultLayoutDirective, [i1.ElementRef, i11.StyleUtils, [2, i10.LayoutStyleBuilder], i11.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(3, 671744, null, 0, i10.DefaultLayoutAlignDirective, [i1.ElementRef, i11.StyleUtils, [2, i10.LayoutAlignStyleBuilder], i11.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleLogOutClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["logout"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserInformationComponent_3)), i1.ɵdid(11, 212992, null, 0, i12.IfRoleDirective, [i1.TemplateRef, i1.ViewContainerRef, i13.AuthService], { roleName: [0, "roleName"] }, null)], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 3, 0, currVal_1); var currVal_5 = "IA_ADMIN"; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var currVal_2 = _v.context.ngIf.email; _ck(_v, 5, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 8).disabled || null); var currVal_4 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_3, currVal_4); }); }
export function View_UserInformationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "section", [["class", "mat-typography"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserInformationComponent_1)), i1.ɵdid(2, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_UserInformationComponent_2)), i1.ɵdid(5, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i14.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.auaUser$)); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_UserInformationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-information", [], null, null, null, View_UserInformationComponent_0, RenderType_UserInformationComponent)), i1.ɵdid(1, 4308992, null, 0, i15.UserInformationComponent, [i13.AuthService, i16.CouponDetailsService, i17.TabService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserInformationComponentNgFactory = i1.ɵccf("app-user-information", i15.UserInformationComponent, View_UserInformationComponent_Host_0, {}, {}, []);
export { UserInformationComponentNgFactory as UserInformationComponentNgFactory };
