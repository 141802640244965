<div class="hd--login-layout" fxLayout fxLayoutAlign="center center">
    <div fxFlex="50%">
        <div fxLayout="column">
            <div fxFlex>
                <img class="hd--login-logo" [src]="logoURL">
            </div>

            <div fxFlex>
                <button class="hd--login-button" (click)="onLoginClick()" mat-raised-button color="primary">Login
                </button>
            </div>

            <div fxFlex fxFlexAlign="center" class="hd--logout-info" *ngIf="logoutReason">
                <mat-card class="mat-card-notification">
                    <mat-card-content>
                        <div fxLayout fxLayoutAlign="center center">
                            <p class="mat-typography hd--notification-message">{{logoutReason}}</p>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

        </div>
    </div>
</div>

