import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Settings} from '../models/settings';
import {
  SetEmailDtoDetailResponse,
  SetEmailResponse,
  UserDeleteRequest,
  UserDeleteResponse,
  UserGetRequest,
  UserGetResponse,
  UserSetEmailRequest
} from '../modules/api/idp_angular-client';
import {HdIdpApiService} from '../modules/api/idp_angular-client/custom/hd-idp-api.service';
import {HdIdpOperatorApiService} from '../modules/api/idp_angular-client/custom/hd-idp-operator-api.service';
import {MessengerService} from '../modules/shared/service/messenger.service';
import {APP_CONFIGURATION} from './settings.http.service';

@Injectable({
  providedIn: 'root'
})
export class IdpApiService {

  constructor(private idPClientService: HdIdpApiService, private idPOperatorService: HdIdpOperatorApiService, private messengerService: MessengerService, @Inject(APP_CONFIGURATION) public readonly configuration: Settings) {
  }

  /**
   * @param provider
   * @private
   */
  private lookUpProviderBasePath(provider: string): string | undefined {
    const providerMapping = this.configuration.allowEditAccountsForProvider;

    const providerMap = providerMapping.find((item) => {
      return item['provider'] === provider;
    });

    return providerMap['basePath'];
  }

  /**
   * @param provider
   */
  public setProvider(provider: string): boolean {
    const providerBasePath = this.lookUpProviderBasePath(provider);

    if (typeof providerBasePath === 'undefined') {
      console.error(`[IDPApiService] Could not set provider api basepath. provider does not exist in settings.json: ${provider}`);
      return false;
    }

    this.idPClientService.setBasePath(providerBasePath);
    this.idPOperatorService.setBasePath(providerBasePath);

    return true;
  }

  /**
   * Deletes user in IDP.
   * @param subject to delete
   */
  deleteUser(subject: string): Observable<UserDeleteResponse> {
    const userDeleteRequest: UserDeleteRequest[] = [{
      subject
    }];
    return this.idPOperatorService.deleteUser('', '', '', userDeleteRequest);
  }

  /**
   * Set new user email
   * @param subject to change
   * @param email new email
   */
  setEmail(subject: string, email: string): Observable<SetEmailResponse> {

    const setEmailRequest: UserSetEmailRequest[] = [{
      subject,
      email
    }];

    return this.idPClientService.setEmail('', '', '', setEmailRequest).pipe(map((setEmailResponse) => {

      if (setEmailResponse.global_response.result_state === 'WARN') {
        const detailResponses: Array<SetEmailDtoDetailResponse> = setEmailResponse.user_change_email_detail_responses;

        for (const detailResponse of detailResponses) {
          this.messengerService.sendNotification(detailResponse.detail_result_response.result_code, 'IDP | setEmail');
        }
      }

      return setEmailResponse;
    }));
  }
}
