import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from '../../authentication/services/auth.service';
import {Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';

@Directive({
    selector: '[ifRole]'
})
export class IfRoleDirective implements OnInit, OnDestroy {

    authUser$: Subscription;
    @Input('ifRole') roleName: string;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authService: AuthService
    ) {
    }

    ngOnInit(): void {
        /**
         * Gets the user from auth and checks if it contains a admin role.
         * depending on outcome hide element with directive
         */
        this.authUser$ = this.authService.getUser$()
            .pipe(
                tap(() => this.viewContainer.clear()),
                filter((authUser) => {
                  return authUser['roles'].includes(this.roleName)
                })
            )
            .subscribe(() => {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                }
            );
    }

    ngOnDestroy() {
        this.authUser$.unsubscribe();
    }
}
