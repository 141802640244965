import * as tslib_1 from "tslib";
import { OperatorService } from '../api/operator.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../variables";
import * as i3 from "../configuration";
/*
Extends OperatorService to dynamically set the basePath of the api
 */
var HdIdpOperatorApiService = /** @class */ (function (_super) {
    tslib_1.__extends(HdIdpOperatorApiService, _super);
    function HdIdpOperatorApiService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param path
     */
    HdIdpOperatorApiService.prototype.setBasePath = function (path) {
        this.basePath = path;
    };
    /**
     *
     */
    HdIdpOperatorApiService.prototype.getBasePath = function () {
        return this.basePath;
    };
    HdIdpOperatorApiService.ngInjectableDef = i0.defineInjectable({ factory: function HdIdpOperatorApiService_Factory() { return new HdIdpOperatorApiService(i0.inject(i1.HttpClient), i0.inject(i2.BASE_PATH, 8), i0.inject(i3.Configuration, 8)); }, token: HdIdpOperatorApiService, providedIn: "root" });
    return HdIdpOperatorApiService;
}(OperatorService));
export { HdIdpOperatorApiService };
