import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {TabItem} from '../models/TabItem';
import {ActivatedRoute, Router} from '@angular/router';
import {MessengerService} from '../../shared/service/messenger.service';

@Injectable({
    providedIn: 'root'
})
export class TabService {

    tabSubject$: BehaviorSubject<TabItem[]>;
    tabItems: TabItem[] = [];

    activeTabItem: TabItem;
    previouslyActiveItem: TabItem;

    constructor(private activeRoute: ActivatedRoute, private router: Router, private messengerService: MessengerService) {
        this.tabSubject$ = new BehaviorSubject<TabItem[]>(undefined);
    }

    /**
     * Creates new tabItem
     * @param tabItem to set
     * @param setActive initial active state
     */
    setNewTab(tabItem: TabItem, setActive?: boolean) {
        const alreadyExistingTabItem = tabItem.type === 'user' ? this.findTabItemBySubject(tabItem.subject) : this.findTabItemByCouponCode(tabItem.couponCode);

        if (!alreadyExistingTabItem) {
            this.deactivateAllTabs();
            tabItem.isActive = setActive;

            if (tabItem.isActive) {
                if (typeof this.activeTabItem !== 'undefined') {
                    this.previouslyActiveItem = this.activeTabItem;
                }

                this.activeTabItem = tabItem.isActive ? tabItem : undefined;
            }

            this.tabItems.push(tabItem);
            this.tabSubject$.next(this.tabItems);
        }

        this.storeTabs();
    }

    /**
     * Sets tab state to active
     * @param tab TabItem to activate
     */
    setActiveTab(tab: TabItem) {
        this.deactivateAllTabs();

        if (typeof this.activeTabItem !== 'undefined') {
            this.previouslyActiveItem = this.activeTabItem;
        }

        this.activeTabItem = tab;
        tab.isActive = true;

        this.tabSubject$.next(this.tabItems);
        this.storeTabs();
    }

    /**
     * Get subject to subscribe to
     */
    getTabSubject(): Subject<TabItem[]> {
        return this.tabSubject$;
    }

    /**
     * Find TabItem by subject
     * @param subject of tab to find
     */
    findTabItemBySubject(subject: string) {
        return this.tabItems.find(elem => {
                return elem.subject === subject;
            }
        );
    }

    /**
     * Find TabItem by couponCode
     * @param couponCode of tab to find
     */
    findTabItemByCouponCode(couponCode: string) {
        return this.tabItems.find(elem => {
                return elem.couponCode === couponCode;
            }
        );
    }

    /**
     * Find active tabe
     */
    findActiveTab(): TabItem {
        for (const tabItem of this.tabItems) {
            if (tabItem.isActive) {
                return tabItem;
            }
        }
    }

    existsInTabItems(tabItem: TabItem) {
        let exists = false;

        if (typeof tabItem === 'undefined') {
            return exists;
        }

        switch (tabItem.type) {
            case 'user':
                exists = !!this.findTabItemBySubject(tabItem.subject);
                break;
            case 'coupon':
                exists = !!this.findTabItemByCouponCode(tabItem.couponCode);
                break;
        }

        return exists;
    }

    /**
     * Removes Tab from tabItems and emits the new lost from the tabSubject
     * @param tabItem to remove
     */
    removeTab(tabItem: TabItem) {
        let tabItemToRemove: TabItem;
        let compareKey = 'subject';

        switch (tabItem.type) {
            case 'user':
                tabItemToRemove = this.findTabItemBySubject(tabItem.subject);
                compareKey = 'subject';
                break;
            case 'coupon':
                tabItemToRemove = this.findTabItemByCouponCode(tabItem.couponCode);
                compareKey = 'couponCode';
                break;
        }

        // filter out tap
        if (typeof this.tabItems !== 'undefined') {
            this.tabItems = this.tabItems.filter(item => item[compareKey] !== tabItemToRemove[compareKey]);
        }

        this.tabSubject$.next(this.tabItems);
        this.storeTabs();

        // If no tabitems are left - move to extended search
        if (this.tabItems.length <= 0) {
            return this.router.navigateByUrl('/extended-search');
        }

        if (tabItem.isActive) {
            if (typeof this.previouslyActiveItem !== 'undefined' && this.existsInTabItems(this.previouslyActiveItem)) {
                this.navigateToTab(this.previouslyActiveItem);
                this.setActiveTab(this.previouslyActiveItem);
            } else {
                if (this.tabItems.length > 0) {
                    this.navigateToTab(this.tabItems[0]);
                    this.setActiveTab(this.tabItems[0]);
                }
            }
        }
    }

    /**
     * Stores TabItems to session storage
     */
    storeTabs() {
        const location = window.location.hostname;
        sessionStorage.setItem('tabs:' + location, JSON.stringify(this.tabItems));
    }

    /**
     * Loads tabs from session storage
     */
    loadTabs() {
        const location = window.location.hostname;
        const tabString = sessionStorage.getItem('tabs:' + location);
        const tabs = JSON.parse(tabString);

        this.tabItems = tabs ? tabs : [];

        const activeTab = this.findActiveTab();

        if (activeTab) {
            this.activeTabItem = activeTab;
        }

        this.tabSubject$.next(this.tabItems);
    }

    /**
     * Deactivate Tabs
     */
    deactivateAllTabs(): void {
        if (this.tabItems) {
            // duplicate array of current tabs
            const newTabItems = [...this.tabItems];
            for (const tabItem of newTabItems) {
                tabItem.isActive = false;
            }
            this.tabItems = newTabItems;
        }
    }

    /**
     * Clears the tabs storage
     */
    clearTabs(): void {
        this.tabItems = [];
        this.tabSubject$.next(this.tabItems);
        const location = window.location.hostname;
        sessionStorage.removeItem('tabs:' + location);
    }

    /**
     * Open route with tab information
     * @param tabItem holding the url params
     */
    navigateToTab(tabItem: TabItem): void {
        let extras = {};
        let commands = [];

        this.messengerService.clearNotifications();

        switch (tabItem.type) {
            case 'user':
                commands = ['user/details'];
                extras = {
                    relativeTo: this.activeRoute,
                    queryParams: {subject: tabItem.subject}
                };
                break;
            case 'coupon':
                commands = ['coupon/details'];
                extras = {
                    queryParams: {couponCode: tabItem.couponCode}
                };
                break;
        }

        this.router.navigate(
            commands,
            extras
        );
    }

}
