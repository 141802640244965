import { PROSProductAndOrderSystem1Service } from '../modules/api/pros_angular-client';
import * as i0 from "@angular/core";
import * as i1 from "../modules/api/pros_angular-client/api/pROSProductAndOrderSystem1.service";
var ProsApiService = /** @class */ (function () {
    function ProsApiService(prosSystemService) {
        this.prosSystemService = prosSystemService;
    }
    ProsApiService.ngInjectableDef = i0.defineInjectable({ factory: function ProsApiService_Factory() { return new ProsApiService(i0.inject(i1.PROSProductAndOrderSystem1Service)); }, token: ProsApiService, providedIn: "root" });
    return ProsApiService;
}());
export { ProsApiService };
