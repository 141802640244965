<h2 mat-dialog-title>Global Response Error</h2>
<mat-dialog-content class="hd--error-dialog">
    <h3 class="mat-h3">Leider ist ein Fehler aufgetreten!</h3>
    <mat-icon color="warn" class="hd--error-dialog-icon">error</mat-icon>
    <p *ngIf="data.popUpDialogItem.message" class="mat-typography">{{data.popUpDialogItem.message}}</p>
    <p *ngIf="data.popUpDialogItem.detailMessage" class="mat-typography">{{data.popUpDialogItem.detailMessage}}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="mat-elevation-z0" mat-raised-button mat-dialog-close color="warn">Schliessen</button>
</mat-dialog-actions>
