import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {CanActivate, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService) {}

  /**
   * Checks if user is allowed to access otherwise inits. authentication process
   * @return user is allowed to activate route
   */
  canActivate(): Observable<boolean> {
    return new Observable<boolean>(observer => observer.next(this.authService.hasValidToken()));
  }
}
