<ng-container *ngFor="let notification of notifications">
    <mat-card class="mat-card-notification" *ngIf="notification.isShown">
        <mat-card-content>
            <div fxLayout fxLayoutAlign="center center">
                <div fxFlex="0.5 1 50px" class="hd--notification-icon">
                    <mat-icon>warning</mat-icon>
                </div>
                <div fxFlex>
                    <p class="mat-typography hd--notification-message">
                        <span class="hd--notification-context" *ngIf="notification.context">{{notification.context}}</span> {{notification.message}}
                    </p>
                </div>
                <div fxFlex="0.5 1 50px" class="hd--notification-icon">
                    <mat-icon class="close-icon" (click)="onCancelClick(notification)">cancel</mat-icon>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</ng-container>
