import {Component, Inject, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {Settings} from '../../../../models/settings';
import {APP_CONFIGURATION} from '../../../../service/settings.http.service';
import {SEARCH_TYPE} from '../../../quick-search/quick-search.enums';
import {TabItem} from '../../models/TabItem';
import {TabService} from '../../service/tab.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  logoURL: string;
  tabSubject$: Subject<TabItem[]>;
  SEARCH_TYPE = SEARCH_TYPE;

  constructor(private tabService: TabService, @Inject(APP_CONFIGURATION) public readonly configuration: Settings) {
  }

  ngOnInit() {
    this.tabSubject$ = this.tabService.getTabSubject();
    this.tabService.loadTabs();
    this.logoURL = this.configuration.theme.logoURL;
  }

  onCloseAllTabs() {
    this.tabService.clearTabs();
  }
}
