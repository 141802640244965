import { map } from 'rxjs/operators';
import { CouponChangeStateDTO1, OSCClient1Service, OSCPublic1Service, OSCSystem1Service } from '../modules/api/osc_angular-client';
var CouponStateEnum = CouponChangeStateDTO1.CouponStateEnum;
import * as i0 from "@angular/core";
import * as i1 from "../modules/api/osc_angular-client/api/oSCClient1.service";
import * as i2 from "../modules/api/osc_angular-client/api/oSCPublic1.service";
import * as i3 from "../modules/api/osc_angular-client/api/oSCSystem1.service";
import * as i4 from "./settings.http.service";
var OscApiService = /** @class */ (function () {
    function OscApiService(oSCClientService, oSCPublicService, oSCSystem1Service, configuration) {
        this.oSCClientService = oSCClientService;
        this.oSCPublicService = oSCPublicService;
        this.oSCSystem1Service = oSCSystem1Service;
        this.configuration = configuration;
        // Fallback
        this.DEFAULT_ORDER_SYSTEMS = ['IAPROS1', 'HEPMAGENTO1', 'RICHMPERFORMX1', 'PHZHAZURE1', 'DIHKSHOPWARE1'];
        this.DEFAULT_COUPON_ORDER_SYSTEMS = ['IAPROS1'];
        this.couponOrderSystems = [];
        this.orderSystems = [];
        this.couponOrderSystems = typeof this.configuration.couponOrderSystems !== 'undefined' ? this.configuration.couponOrderSystems : this.DEFAULT_COUPON_ORDER_SYSTEMS;
        this.orderSystems = typeof this.configuration.orderSystems !== 'undefined' ? this.configuration.orderSystems : this.DEFAULT_ORDER_SYSTEMS;
    }
    /**
     * Makes orderSyncForUser request
     * @param subjectID for request
     */
    OscApiService.prototype.orderSyncForUser = function (subjectID) {
        var orderSyncForUserDTOs = [];
        for (var _i = 0, _a = this.orderSystems; _i < _a.length; _i++) {
            var ordersystem = _a[_i];
            var order_system_dto = {
                dto_id: '',
                order_system_identifier: ordersystem,
            };
            var orderSyncForUserDTO = {
                dto_id: '',
                subject: subjectID,
                force_sync: true,
                order_system_dto: order_system_dto
            };
            orderSyncForUserDTOs.push(orderSyncForUserDTO);
        }
        var request = {
            order_sync_for_user_dtos: orderSyncForUserDTOs
        };
        return this.oSCClientService.orderSyncForUser('', '', '', request).pipe(map(function (orderSyncForUserResponse) {
            return orderSyncForUserResponse;
        }));
    };
    /**
     * Get enum value from string
     * @param couponState to get enum
     */
    OscApiService.prototype.getCouponStateEnum = function (couponState) {
        var couponStateEnum = null;
        switch (couponState) {
            case 'COUPON_STATE_VALID':
                couponStateEnum = CouponStateEnum.VALID;
                break;
            case 'COUPON_STATE_ACTIVE':
                couponStateEnum = CouponStateEnum.ACTIVE;
                break;
            case 'COUPON_STATE_ASSIGNED':
                couponStateEnum = CouponStateEnum.ASSIGNED;
                break;
            case 'COUPON_STATE_WITHDRAWN':
                couponStateEnum = CouponStateEnum.WITHDRAWN;
                break;
        }
        return couponStateEnum;
    };
    /**
     * Change state of couponCode
     * @param couponCode
     * @param couponState
     * @param subject
     * @param orderSystemDTO
     */
    OscApiService.prototype.changeState = function (couponCode, couponState, subject, orderSystemDTO) {
        var order_system_dto = orderSystemDTO;
        var couponChangeStateDTO = {
            dto_id: '',
            subject: typeof subject === 'undefined' ? '' : subject,
            coupon_code: couponCode,
            coupon_state: this.getCouponStateEnum(couponState),
            order_system_dto: order_system_dto
        };
        var couponChangeStateRequest = {
            coupon_change_state_dtos: [couponChangeStateDTO]
        };
        return this.oSCSystem1Service.couponChangeState('', '', '', couponChangeStateRequest);
    };
    /**
     *
     * @param couponChangeDTO
     */
    OscApiService.prototype.changeCoupon = function (couponChangeDTO) {
        var couponChangeRequest = {
            coupon_change_dtos: [couponChangeDTO]
        };
        console.log('[osc-api-service] Sending request: ', couponChangeDTO);
        return this.oSCSystem1Service.couponChange('', '', '', couponChangeRequest);
    };
    /**
     * Quickseach coupon code
     *
     * @param couponCode
     * @param quickSearch
     */
    OscApiService.prototype.quickSearchCouponCode = function (couponCode, quickSearch) {
        if (quickSearch === void 0) { quickSearch = true; }
        var coupon_search_dtos = [];
        for (var _i = 0, _a = this.couponOrderSystems; _i < _a.length; _i++) {
            var couponOrderSystem = _a[_i];
            var order_system_dto = {
                dto_id: '',
                order_system_identifier: couponOrderSystem
            };
            var couponSearchDTO = {
                dto_id: '',
                coupon_code: couponCode,
                order_system_dto: order_system_dto
            };
            coupon_search_dtos.push(couponSearchDTO);
        }
        var request = {
            coupon_search_dtos: coupon_search_dtos
        };
        return this.oSCSystem1Service.couponSearch('', '', '', request, quickSearch);
    };
    /**
     * Look up coupons associated to user
     * @param subject
     */
    OscApiService.prototype.couponLookUp = function (subject) {
        var coupon_look_up_dtos = [];
        for (var _i = 0, _a = this.couponOrderSystems; _i < _a.length; _i++) {
            var couponOrderSystem = _a[_i];
            var order_system_dto = {
                dto_id: '',
                order_system_identifier: couponOrderSystem
            };
            var couponLookUpDTO = {
                subject: subject,
                order_system_dto: order_system_dto
            };
            coupon_look_up_dtos.push(couponLookUpDTO);
        }
        var request = {
            coupon_look_up_dtos: coupon_look_up_dtos
        };
        return this.oSCSystem1Service.couponLookUp('', '', '', request);
    };
    OscApiService.ngInjectableDef = i0.defineInjectable({ factory: function OscApiService_Factory() { return new OscApiService(i0.inject(i1.OSCClient1Service), i0.inject(i2.OSCPublic1Service), i0.inject(i3.OSCSystem1Service), i0.inject(i4.APP_CONFIGURATION)); }, token: OscApiService, providedIn: "root" });
    return OscApiService;
}());
export { OscApiService };
