<div #flyout class="hd--mobile-flyout" [@openClose]="showFlyout ? 'open' : 'closed'">
    <div>

        <!--- Complete search row -->
        <div fxLayout="column"
             fxLayoutGap="5%"
             fxLayoutAlign="flex-stretch"
             class="hd--search">

            <!-- Extended search button -->
            <div fxFlex="100" fxLayoutAlign="center center">
                <button mat-raised-button
                        fxFill
                        color="primary"
                        [routerLink]="['/extended-search']"
                        routerLinkActive="active">
                    Erweiterte Suche
                </button>
            </div>

            <!-- Searchfields -->
            <div fxFlex>
                <mat-card class="hd--search-card mat-elevation-z0">
                    <app-quick-search [placeholderText]="'Benutzersuche'"></app-quick-search>
                </mat-card>
            </div>
            <div fxFlex>
                <mat-card class="hd--search-card mat-elevation-z0">
                    <app-quick-search [placeholderText]="'Couponsuche'"></app-quick-search>
                </mat-card>
            </div>
            <div fxFlex *ifRole="'IA_ADMIN'">
                <mat-card class="hd--search-card mat-elevation-z0">
                    <app-quick-search [placeholderText]="'Subjektsuche'"
                                      [searchType]="'subject'"></app-quick-search>
                </mat-card>
            </div>

            <!-- User login information -->
            <div fxFlex>
                <!--<app-user-information></app-user-information>-->
            </div>

        </div>
    </div>
</div>
