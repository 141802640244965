import {Inject, Injectable, InjectionToken} from '@angular/core';
import {Settings} from '../models/settings';

export const APP_CONFIGURATION = new InjectionToken<Settings>('APP_CONFIGURATION');

@Injectable({
    providedIn: 'root'
})
export class SettingsHttpService {
    static readonly SETTINGS_URL = 'assets/settings/settings.json';

    // private promiseResolved = new BehaviorSubject<any>(this.settingsService.settings);
    constructor(@Inject(APP_CONFIGURATION) public readonly configuration: Settings) {}
}
