import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {PopUpDialogItem} from '../../models/PopUpDialogItem';

@Component({
    selector: 'app-popup-error-dialog',
    templateUrl: './popup-error-dialog.component.html',
    styleUrls: ['./popup-error-dialog.component.scss']
})

export class PopupErrorDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: PopUpDialogItem) {
    }
}
