import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {UserDTO, UserExtendedSearchDTO} from '../../../api/aua_angular-client';
import {NavigationStart, Router} from '@angular/router';
import {ExtendedSearchService} from '../../service/extended-search.service';

@Component({
    selector: 'app-extended-search-table',
    templateUrl: './extended-search-table.component.html',
    styleUrls: ['./extended-search-table.component.scss']
})
export class ExtendedSearchTableComponent implements OnInit {
    @Input() tableSource: UserExtendedSearchDTO[];

    user: UserDTO;

    displayedColumns: string[] = [
        'email',
        'name',
        'created_at',
        'mandant_groups',
        'providers'
    ];

    displayedMobileColumns: string[] = [
        'name',
        'email'
    ];

    constructor(private router: Router, private extendedSearchService: ExtendedSearchService) {
        this.registerRouteChange();
    }

    ngOnInit() {
        const loadData: UserExtendedSearchDTO[] = this.extendedSearchService.getStoredSearchTableData();
        this.mapInputDataToTable(loadData);
    }

    registerRouteChange() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.extendedSearchService.storeSearchTableData(this.tableSource);
            }
        });
    }

    mapInputDataToTable(userExtendedSearchDTOS: UserExtendedSearchDTO[]) {
        this.tableSource = userExtendedSearchDTOS;
    }
}
