import {Component} from '@angular/core';
import {MatDialog} from '@angular/material';
import {MessengerService} from '../../service/messenger.service';
import {PopupErrorDialogComponent} from '../popup-error-dialog/popup-error-dialog.component';

@Component({
    selector: 'app-popup-dialog-manager',
    templateUrl: './popup-dialog-manager.component.html',
    styleUrls: ['./popup-dialog-manager.component.scss']
})
export class PopupDialogManagerComponent {

    errorDialogRef;

    constructor(public dialog: MatDialog, private messengerService: MessengerService) {
        this.subscribeForPopUps();
    }

    subscribeForPopUps() {
        // Subscribing for error popUp
        this.messengerService.ErrorPopUp$.subscribe(popUpDialogItem => {
            if (!this.errorDialogRef) {
                this.errorDialogRef = this.dialog.open(PopupErrorDialogComponent, {
                    data: {popUpDialogItem}
                });
                this.errorDialogRef.afterClosed().subscribe(popUp => {
                    this.errorDialogRef = undefined;
                });
            }
        });
    }

}
