import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { from, Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { AuaApiService } from '../../../service/aua-api.service';
import { LoginService } from '../../../service/login.service';
import { AUAClientService } from '../../api/aua_angular-client';
import { BILOClient1Service } from '../../api/bilo_angular-client';
import { HdIdpApiService } from '../../api/idp_angular-client/custom/hd-idp-api.service';
import { LIMAClient1Service } from '../../api/lima_angular-client';
import { OSCClient1Service } from '../../api/osc_angular-client';
import { PROSProductAndOrderSystem1Service } from '../../api/pros_angular-client';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "angular-oauth2-oidc";
import * as i3 from "../../api/aua_angular-client/api/aUAClient.service";
import * as i4 from "../../../service/login.service";
import * as i5 from "../../../service/settings.http.service";
import * as i6 from "../../../service/aua-api.service";
import * as i7 from "../../api/bilo_angular-client/api/bILOClient1.service";
import * as i8 from "../../api/idp_angular-client/custom/hd-idp-api.service";
import * as i9 from "../../api/lima_angular-client/api/lIMAClient1.service";
import * as i10 from "../../api/osc_angular-client/api/oSCClient1.service";
import * as i11 from "../../api/pros_angular-client/api/pROSProductAndOrderSystem1.service";
var AuthService = /** @class */ (function () {
    function AuthService(router, oauthService, auaClientService, loginService, configuration, auaApiService, biloApiService, idpApiService, limaApiService, oscApiService, prosApiService) {
        this.router = router;
        this.oauthService = oauthService;
        this.auaClientService = auaClientService;
        this.loginService = loginService;
        this.configuration = configuration;
        this.auaApiService = auaApiService;
        this.biloApiService = biloApiService;
        this.idpApiService = idpApiService;
        this.limaApiService = limaApiService;
        this.oscApiService = oscApiService;
        this.prosApiService = prosApiService;
    }
    AuthService.prototype.startLogin = function () {
        this.oauthService.initImplicitFlow();
    };
    /**
     *
     */
    AuthService.prototype.configureService = function () {
        this.oauthService.configure(this.configuration.oicdClientSettings);
    };
    /**
     *
     */
    AuthService.prototype.checkAuth = function () {
        return this.oauthService.hasValidAccessToken();
    };
    AuthService.prototype.assignTokens = function () {
        var token = this.oauthService.getAccessToken();
        this.auaClientService.configuration.apiKeys['Authorization'] = token;
        this.biloApiService.configuration.apiKeys['Authorization'] = token;
        this.idpApiService.configuration.apiKeys['Authorization'] = token;
        this.limaApiService.configuration.apiKeys['Authorization'] = token;
        this.oscApiService.configuration.apiKeys['Authorization'] = token;
        this.prosApiService.configuration.apiKeys['Authorization'] = token;
    };
    /**
     * Perform action when authentication is confirmed
     */
    AuthService.prototype.completeAuthentication = function () {
        var _this = this;
        this.oauthService.tryLoginImplicitFlow();
        if (this.oauthService.hasValidAccessToken()) {
            this.assignTokens();
            from(this.oauthService.loadUserProfile()).pipe(concatMap(function (result) {
                var subject = result['sub'];
                var roles = result['roles'];
                _this.authUser = { roles: roles, subject: subject };
                return _this.auaApiService.getUser(subject);
            })).subscribe(function (response) {
                if (response.user_get_detail_response.length) {
                    _this.auaUser = response.user_get_detail_response[0].user;
                }
                _this.router.navigate([_this.configuration.postLoginRoute]);
            }, function (error) { return console.error('AuthService: Error during authorisation', error); });
        }
    };
    /**
     * Get user stored by oicd-client
     */
    AuthService.prototype.getUser$ = function () {
        var _this = this;
        if (!this.authUser) {
            return from(this.oauthService.loadUserProfile()).pipe(map(function (result) {
                var subject = result['sub'];
                var roles = result['roles'];
                _this.authUser = { roles: roles, subject: subject };
                return _this.authUser;
            }));
        }
        return new Observable(function (observer) { return observer.next(_this.authUser); });
    };
    /**
     * Returns aua-information of logged in user
     */
    AuthService.prototype.getAuAUser$ = function () {
        var _this = this;
        if (!this.auaUser) {
            return from(this.oauthService.loadUserProfile()).pipe(concatMap(function (result) {
                var subject = result['sub'];
                return _this.auaApiService.getUser(subject);
            }), map(function (result) { return result.user_get_detail_response[0].user; }));
        }
        return new Observable(function (observer) { return observer.next(_this.auaUser); });
    };
    /**
     *
     */
    AuthService.prototype.hasValidToken = function () {
        if (this.oauthService.hasValidAccessToken()) {
            this.assignTokens();
        }
        return this.oauthService.hasValidAccessToken();
    };
    /**
     * Defines Roles that are allowed to login
     * @param roles to check against
     */
    AuthService.prototype.canUse = function (roles) {
        return roles.includes('IA_ADMIN') || roles.includes('IA_SUPPORTER');
    };
    /**
     * Starts signout
     * @param logoutReason general reason
     */
    AuthService.prototype.startSignOut = function (logoutReason) {
        if (logoutReason === void 0) { logoutReason = ''; }
        // Remove stored data
        this.oauthService.logOut();
        sessionStorage.removeItem('tabs');
        if (logoutReason) {
            this.loginService.setLogoutReason(logoutReason);
        }
        this.router.navigate(['/login']);
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.Router), i0.inject(i2.OAuthService), i0.inject(i3.AUAClientService), i0.inject(i4.LoginService), i0.inject(i5.APP_CONFIGURATION), i0.inject(i6.AuaApiService), i0.inject(i7.BILOClient1Service), i0.inject(i8.HdIdpApiService), i0.inject(i9.LIMAClient1Service), i0.inject(i10.OSCClient1Service), i0.inject(i11.PROSProductAndOrderSystem1Service)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
