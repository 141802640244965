import {Component, OnInit, ViewChild} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-mobile-flyout',
    templateUrl: './mobile-flyout.component.html',
    styleUrls: ['./mobile-flyout.component.scss'],
    animations: [
        trigger('openClose', [

                state('open', style({
                    left: '0'
                })),

                state('closed', style({
                    left: '100%'
                })),

                transition('open => closed', [
                    animate('0.2s')
                ]),

                transition('closed => open', [
                    animate('0.2s')
                ]),
            ]
        )
    ]
})
export class MobileFlyoutComponent implements OnInit {
    @ViewChild('flyout') flyout;

    showFlyout = false;

    constructor(private router: Router) {
    }

    ngOnInit() {
        this.registerRouteChange();
    }

    toggle() {
        this.showFlyout = !this.showFlyout;
    }

    registerRouteChange() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.showFlyout = false;
            }
        });
    }
}
