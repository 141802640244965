import {Pipe, PipeTransform} from '@angular/core';
import {SEARCH_TYPE} from '../quick-search.enums';

@Pipe({
    name: 'formatQuickSearch'
})
export class FormatQuickSearchPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        let searchResults = [];
        if (value) {

            // User search
            if (value.users) {
                for (const user of value.users) {
                    let hint = '';
                    let label = '';
                    let identities = [];

                    switch (args) {
                        case SEARCH_TYPE.EMAIL:
                            hint = 'Benutzer E-Mail';
                            label = user.email;
                            identities = user.identities;
                            break;
                        case SEARCH_TYPE.SUBJECT:
                            hint = user.email;
                            label = user.subject;
                            break;
                    }

                    const searchResult = {
                        value: user,
                        hint,
                        label,
                        identities
                    };

                    searchResults.push(searchResult);
                }
            }

            // Coupon search
            if (value.coupons) {
                searchResults = value.coupons;
            }
        }

        return searchResults;
    }
}
