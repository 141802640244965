import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TabItem} from '../../models/TabItem';
import {TabService} from '../../service/tab.service';

@Component({
    selector: 'app-user-tab',
    templateUrl: './header-tab.component.html',
    styleUrls: ['./header-tab.component.scss']
})
export class HeaderTabComponent implements OnInit, OnDestroy {
    @Input() tabItem: TabItem;

    constructor(private tabService: TabService) {
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        // this.tabService.clearTabs();
    }

    onCancelClick() {
        this.tabService.removeTab(this.tabItem);
    }

    onTabClick() {
        this.tabService.setActiveTab(this.tabItem);
        this.tabService.navigateToTab(this.tabItem);
    }
}
