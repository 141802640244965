import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserExtendedSearchDTO} from '../../../api/aua_angular-client';
import {PageNavigationItem} from '../../../shared/models/PageNavigationItem';
import {ExtendedSearchService} from '../../service/extended-search.service';
import {FormGroup} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';

@Component({
    selector: 'app-extended-search',
    templateUrl: './extended-search-user-page.html',
    styleUrls: ['./extended-search-user-page.scss']
})
export class ExtendedSearchUserPageComponent implements OnInit, OnDestroy {

    foundUsers: UserExtendedSearchDTO[];

    searchRequestSubject: Subject<FormGroup>;
    searchRequestSubjectSubscription: Subscription;

    isLoading: boolean;

    pageNavigationItems: PageNavigationItem[] = [
        {name: 'Benutzer', isActive: true},
        {name: 'Coupons', disabled: true},
        {name: 'Tickets', disabled: true}
    ];

    constructor(private extendedSearchService: ExtendedSearchService) {
    }

    ngOnInit() {
        this.isLoading = false;
        this.registerSearch();
    }

    ngOnDestroy(): void {
        this.searchRequestSubjectSubscription.unsubscribe();
    }

    /**
     * Registers for the search service subject
     */
    registerSearch() {
        this.searchRequestSubject = this.extendedSearchService.getSearchRequestSubject();
        this.searchRequestSubjectSubscription = this.searchRequestSubject.pipe(
            tap(() => this.isLoading = true),
            switchMap(formGroup => this.extendedSearchService.extendedSearch(formGroup)))
            .subscribe((users) => {
                this.isLoading = false;
                this.foundUsers = users;
            });
    }
}
