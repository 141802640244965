import {Injectable} from '@angular/core';
import {PROSProductAndOrderSystem1Service} from '../modules/api/pros_angular-client';


@Injectable({
    providedIn: 'root'
})
export class ProsApiService {
    constructor(private prosSystemService: PROSProductAndOrderSystem1Service) {}
}
