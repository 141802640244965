/**
 * ioneaccess Order System Coordinator (OSC) API
 * API for active external 'Order System Coordinator' access. This document defines an interface for pushing orders into the ionaccess order system.
 *
 * OpenAPI spec version: 1.4.6
 * Contact: florian.mischler@ionesoft.ch
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OrderSystemDTO1 } from './orderSystemDTO1';


export interface CouponChangeDTO1 { 
    /**
     * DTO identifier to correlate
     */
    dto_id: string;
    /**
     * coupon code
     */
    coupon_code: string;
    /**
     * expires on this date
     */
    expiration_date?: string;
    unset_expiration_date?: boolean;
    /**
     * valid from this date
     */
    valid_from_date?: string;
    unset_valid_from_date?: boolean;
    /**
     * number of days to be valid
     */
    valid_for_days?: number;
    unset_valid_for_days?: boolean;
    /**
     * number of devices
     */
    max_devices?: number;
    unset_max_devices?: boolean;
    /**
     * State to change to one of COUPON_STATE_VALID, COUPON_STATE_ACTIVE, COUPON_STATE_ASSIGNED, COUPON_STATE_WITHDRAWN
     */
    coupon_state?: CouponChangeDTO1.CouponStateEnum;
    order_system_dto: OrderSystemDTO1;
}
export namespace CouponChangeDTO1 {
    export type CouponStateEnum = 'COUPON_STATE_VALID' | 'COUPON_STATE_ACTIVE' | 'COUPON_STATE_ASSIGNED' | 'COUPON_STATE_WITHDRAWN';
    export const CouponStateEnum = {
        VALID: 'COUPON_STATE_VALID' as CouponStateEnum,
        ACTIVE: 'COUPON_STATE_ACTIVE' as CouponStateEnum,
        ASSIGNED: 'COUPON_STATE_ASSIGNED' as CouponStateEnum,
        WITHDRAWN: 'COUPON_STATE_WITHDRAWN' as CouponStateEnum
    };
}
