import {Injectable} from '@angular/core';
import {filter} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RoutingService {

    constructor( private route: ActivatedRoute) {
    }

    getRouteParameter(param: string): Observable<any> {
        return this.route.queryParams.pipe(filter(params => params[param]));
    }

}
