<!-- Desktop Header -->
<header class="hd--header">

    <!-- header content -->
    <div fxLayout="column" fxLayoutAlign="space-between" class="hd--header-content">

        <div fxFlex="80">

            <div class="hd--header-top-row" fxLayout="row" fxLayoutGap="0.5%">

                <!-- Search Desktop -->
                <div fxFlex>

                    <!--- Complete search row -->
                    <div fxLayout="row" fxLayoutAlign="flex-start center" class="hd--header-search">

                        <!-- Extended search button -->
                        <div fxFlex="0 0 20" fxLayoutAlign="center center">
                            <button mat-stroked-button
                                    class="hd--header-search-button"
                                    [routerLink]="['/extended-search']"
                                    routerLinkActive="active">
                                <mat-icon>search</mat-icon>
                                Erweiterte Suche
                            </button>
                        </div>

                        <!-- Searchfields -->
                        <div fxFlex>
                            <mat-card class="hd--header-search-card mat-elevation-z0">
                                <app-quick-search [placeholderText]="'Suche nach E-Mail'"
                                                  [searchType]="SEARCH_TYPE.EMAIL"></app-quick-search>
                            </mat-card>
                        </div>

                        <div fxFlex>
                            <mat-card class="hd--header-search-card mat-elevation-z0">
                                <app-quick-search [placeholderText]="'Suche nach Coupon'"
                                                  [searchType]="SEARCH_TYPE.COUPON"></app-quick-search>
                            </mat-card>
                        </div>

                        <div fxFlex *ifRole="'IA_ADMIN'">
                            <mat-card class="hd--header-search-card mat-elevation-z0">
                                <app-quick-search [placeholderText]="'Suche nach Subjekt'"
                                                  [searchType]="SEARCH_TYPE.SUBJECT"></app-quick-search>
                            </mat-card>
                        </div>
                    </div>
                </div>

                <!--header logo -->
                <div fxFlex="22" fxLayoutAlign="center center">
                    <img class="hd--header-logo" [src]="logoURL"/>
                </div>

            </div>
        </div>

        <div fxFlex>
            <!-- Tab row -->
            <div class="hd--search-bottom-row" fxLayout="row">

                <div fxFlex fxFlexAlign="flex-end">
                    <div fxLayout="row" fxLayoutGap="0.5%">
                        <button *ngIf="(tabSubject$ | async)?.length" class="mat-typography close-all-tabs-button" (click)="onCloseAllTabs()">Alle schliessen</button>
                        <ng-container *ngFor="let tab of (tabSubject$ | async)">
                            <app-user-tab [tabItem]="tab"></app-user-tab>
                        </ng-container>
                    </div>
                </div>

                <!-- User login information Desktop -->
                <div fxFlex="30" fxLayoutAlign="flex-end center">
                    <div class="hd--header-login-information">
                        <app-user-information></app-user-information>
                    </div>
                </div>
            </div>
        </div>

    </div>
</header>

