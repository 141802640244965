import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuardService} from './modules/authentication/services/auth-guard.service';
import {AuthCallbackComponent} from './modules/authentication/component/auth-callback/auth-callback.component';
import {LoginComponent} from './modules/login/component/login/login.component';
import {ExtendedSearchUserPageComponent} from './modules/extended-search/component/extended-search-page/extended-search-user-page';
import {AdminGuardService} from './modules/authentication/services/admin-guard.service';
import {SiteLayoutComponent} from './_layout/site-layout/site-layout.component';

const routes: Routes = [
    // No Layout Routes
    {
        path: 'auth-callback',
        component: AuthCallbackComponent,
    },
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    // Site Layout Routes
    {
        path: '',
        component: SiteLayoutComponent,
        children: [
            {
                path: 'extended-search',
                component: ExtendedSearchUserPageComponent,
                canActivate: [AuthGuardService]
            },
            {
                path: 'coupon/details',
                loadChildren: './modules/coupon-details/coupon-details.module#CouponDetailsModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'user/devices',
                loadChildren: './modules/user-devices/user-devices.module#UserDevicesModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'user/licenses',
                loadChildren: './modules/user-licenses/user-licenses.module#UserLicensesModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'user/details',
                loadChildren: './modules/user-details/user-details.module#UserDetailsModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'admin/details',
                loadChildren: './modules/admin-details/admin-details.module#AdminDetailsModule',
                canActivate: [AuthGuardService, AdminGuardService]
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
