/**
 * ioneaccess Product and Order-System (PROS) API
 * API for ioneaccess Product and Order-System (PROS) API aka ia_sales_system REST services
 *
 * OpenAPI spec version: 1.2.10
 * Contact: anhi.chang@ionesoft.ch
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AssetDefinitionRemoveResponse1 } from '../model/assetDefinitionRemoveResponse1';
import { AssetProductCreateResponse1 } from '../model/assetProductCreateResponse1';
import { AssetProductGetResponse1 } from '../model/assetProductGetResponse1';
import { AssetProductModifyResponse1 } from '../model/assetProductModifyResponse1';
import { CourseProductCreateResponse1 } from '../model/courseProductCreateResponse1';
import { CourseProductGetResponse1 } from '../model/courseProductGetResponse1';
import { CourseProductModifyResponse1 } from '../model/courseProductModifyResponse1';
import { CourseSeriesCreateResponse1 } from '../model/courseSeriesCreateResponse1';
import { CourseSeriesGetResponse1 } from '../model/courseSeriesGetResponse1';
import { CourseSeriesModifyResponse1 } from '../model/courseSeriesModifyResponse1';
import { CreateAssetProductRequest1 } from '../model/createAssetProductRequest1';
import { CreateCourseProductRequest1 } from '../model/createCourseProductRequest1';
import { CreateCourseSeriesRequest1 } from '../model/createCourseSeriesRequest1';
import { CreateExerciseProductRequest1 } from '../model/createExerciseProductRequest1';
import { CreateIssueProductRequest1 } from '../model/createIssueProductRequest1';
import { CreateOrderRequest1 } from '../model/createOrderRequest1';
import { CreateOrderRequest11 } from '../model/createOrderRequest11';
import { CreateOrderRequest3 } from '../model/createOrderRequest3';
import { CreateTopicProductRequest1 } from '../model/createTopicProductRequest1';
import { ExerciseProductCreateResponse1 } from '../model/exerciseProductCreateResponse1';
import { ExerciseProductGetResponse1 } from '../model/exerciseProductGetResponse1';
import { ExerciseProductModifyResponse1 } from '../model/exerciseProductModifyResponse1';
import { GetAssetProductRequest1 } from '../model/getAssetProductRequest1';
import { GetCourseProductRequest1 } from '../model/getCourseProductRequest1';
import { GetCourseSeriesRequest1 } from '../model/getCourseSeriesRequest1';
import { GetExerciseProductRequest1 } from '../model/getExerciseProductRequest1';
import { GetIssueProductRequest1 } from '../model/getIssueProductRequest1';
import { GetTopicProductRequest1 } from '../model/getTopicProductRequest1';
import { IssueProductCreateResponse1 } from '../model/issueProductCreateResponse1';
import { IssueProductGetResponse1 } from '../model/issueProductGetResponse1';
import { IssueProductModifyResponse1 } from '../model/issueProductModifyResponse1';
import { ModifyAssetProductRequest1 } from '../model/modifyAssetProductRequest1';
import { ModifyCourseProductRequest1 } from '../model/modifyCourseProductRequest1';
import { ModifyCourseSeriesRequest1 } from '../model/modifyCourseSeriesRequest1';
import { ModifyExerciseProductRequest1 } from '../model/modifyExerciseProductRequest1';
import { ModifyIssueProductRequest1 } from '../model/modifyIssueProductRequest1';
import { ModifyTopicProductRequest1 } from '../model/modifyTopicProductRequest1';
import { OrderActivateCouponRequest1 } from '../model/orderActivateCouponRequest1';
import { OrderActivateCouponResponse1 } from '../model/orderActivateCouponResponse1';
import { OrderChangeCouponRequest1 } from '../model/orderChangeCouponRequest1';
import { OrderChangeCouponResponse1 } from '../model/orderChangeCouponResponse1';
import { OrderChangeCouponStateRequest1 } from '../model/orderChangeCouponStateRequest1';
import { OrderChangeCouponStateResponse1 } from '../model/orderChangeCouponStateResponse1';
import { OrderChangeSubjectRequest1 } from '../model/orderChangeSubjectRequest1';
import { OrderChangeSubjectResponse1 } from '../model/orderChangeSubjectResponse1';
import { OrderCreateOrderResponse1 } from '../model/orderCreateOrderResponse1';
import { OrderCreateOrderResponse11 } from '../model/orderCreateOrderResponse11';
import { OrderCreateOrderResponse3 } from '../model/orderCreateOrderResponse3';
import { OrderGenerateForProductRequest1 } from '../model/orderGenerateForProductRequest1';
import { OrderGenerateForProductResponse1 } from '../model/orderGenerateForProductResponse1';
import { OrderGetBySubjectRequest1 } from '../model/orderGetBySubjectRequest1';
import { OrderGetBySubjectResponse1 } from '../model/orderGetBySubjectResponse1';
import { OrderGetOrderSystemRequest1 } from '../model/orderGetOrderSystemRequest1';
import { OrderGetOrderSystemResponse1 } from '../model/orderGetOrderSystemResponse1';
import { OrderLookUpCouponRequest1 } from '../model/orderLookUpCouponRequest1';
import { OrderLookUpCouponResponse1 } from '../model/orderLookUpCouponResponse1';
import { OrderModifyLicenseOrderItemRequest1 } from '../model/orderModifyLicenseOrderItemRequest1';
import { OrderModifyLicenseResponse1 } from '../model/orderModifyLicenseResponse1';
import { OrderQuickSearchCouponCodeRequest1 } from '../model/orderQuickSearchCouponCodeRequest1';
import { OrderQuickSearchCouponCodeResponse1 } from '../model/orderQuickSearchCouponCodeResponse1';
import { ProductLookUpSkuRequest1 } from '../model/productLookUpSkuRequest1';
import { ProductLookUpSkuResponse1 } from '../model/productLookUpSkuResponse1';
import { RemoveAssetDefinitionRequest1 } from '../model/removeAssetDefinitionRequest1';
import { TopicProductCreateResponse1 } from '../model/topicProductCreateResponse1';
import { TopicProductGetResponse1 } from '../model/topicProductGetResponse1';
import { TopicProductModifyResponse1 } from '../model/topicProductModifyResponse1';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PROSProductAndOrderSystem1Service {

    protected basePath = 'https://pros.ionedev.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Activate coupons, return license order items.
     * Activate coupons, return license order items.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param OrderActivateCouponRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateCoupon(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderActivateCouponRequest1?: OrderActivateCouponRequest1, observe?: 'body', reportProgress?: boolean): Observable<OrderActivateCouponResponse1>;
    public activateCoupon(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderActivateCouponRequest1?: OrderActivateCouponRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderActivateCouponResponse1>>;
    public activateCoupon(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderActivateCouponRequest1?: OrderActivateCouponRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderActivateCouponResponse1>>;
    public activateCoupon(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderActivateCouponRequest1?: OrderActivateCouponRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling activateCoupon.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling activateCoupon.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling activateCoupon.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderActivateCouponResponse1>(`${this.basePath}/rpc/orderSystem/v1/order/activateCoupon`,
            OrderActivateCouponRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change coupon and its license order items
     * Change coupon attributes and its all license order items
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param OrderChangeCouponRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeCoupon(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderChangeCouponRequest1?: OrderChangeCouponRequest1, observe?: 'body', reportProgress?: boolean): Observable<OrderChangeCouponResponse1>;
    public changeCoupon(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderChangeCouponRequest1?: OrderChangeCouponRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderChangeCouponResponse1>>;
    public changeCoupon(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderChangeCouponRequest1?: OrderChangeCouponRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderChangeCouponResponse1>>;
    public changeCoupon(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderChangeCouponRequest1?: OrderChangeCouponRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling changeCoupon.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling changeCoupon.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling changeCoupon.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderChangeCouponResponse1>(`${this.basePath}/rpc/orderSystem/v1/order/changeCoupon`,
            OrderChangeCouponRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change coupon state
     * Change coupon state. If COUPON_STATE_ASSIGNED -&gt; subject is required. If COUPON_STATE_ACTIVE or COUPON_STATE_VALID -&gt; subject should be td
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param OrderChangeCouponStateRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeCouponState(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderChangeCouponStateRequest1?: OrderChangeCouponStateRequest1, observe?: 'body', reportProgress?: boolean): Observable<OrderChangeCouponStateResponse1>;
    public changeCouponState(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderChangeCouponStateRequest1?: OrderChangeCouponStateRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderChangeCouponStateResponse1>>;
    public changeCouponState(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderChangeCouponStateRequest1?: OrderChangeCouponStateRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderChangeCouponStateResponse1>>;
    public changeCouponState(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderChangeCouponStateRequest1?: OrderChangeCouponStateRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling changeCouponState.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling changeCouponState.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling changeCouponState.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderChangeCouponStateResponse1>(`${this.basePath}/rpc/orderSystem/v1/order/changeCouponState`,
            OrderChangeCouponStateRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change subject of coupons and license order items
     * Change subject of coupons and license order items. This route fixes the wrong subject
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param OrderChangeSubjectRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeSubject(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderChangeSubjectRequest1?: OrderChangeSubjectRequest1, observe?: 'body', reportProgress?: boolean): Observable<OrderChangeSubjectResponse1>;
    public changeSubject(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderChangeSubjectRequest1?: OrderChangeSubjectRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderChangeSubjectResponse1>>;
    public changeSubject(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderChangeSubjectRequest1?: OrderChangeSubjectRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderChangeSubjectResponse1>>;
    public changeSubject(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderChangeSubjectRequest1?: OrderChangeSubjectRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling changeSubject.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling changeSubject.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling changeSubject.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderChangeSubjectResponse1>(`${this.basePath}/rpc/orderSystem/v1/order/changeSubject`,
            OrderChangeSubjectRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create asset product
     * create asset product
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param CreateAssetProductRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAssetProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateAssetProductRequest1?: CreateAssetProductRequest1, observe?: 'body', reportProgress?: boolean): Observable<AssetProductCreateResponse1>;
    public createAssetProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateAssetProductRequest1?: CreateAssetProductRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AssetProductCreateResponse1>>;
    public createAssetProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateAssetProductRequest1?: CreateAssetProductRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AssetProductCreateResponse1>>;
    public createAssetProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateAssetProductRequest1?: CreateAssetProductRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling createAssetProduct.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling createAssetProduct.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling createAssetProduct.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AssetProductCreateResponse1>(`${this.basePath}/rpc/productSystem/v1/product/createAssetProduct`,
            CreateAssetProductRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * creates course product
     * creates course product. Uploaded symbol
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param CreateCourseProductRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCourseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateCourseProductRequest1?: CreateCourseProductRequest1, observe?: 'body', reportProgress?: boolean): Observable<CourseProductCreateResponse1>;
    public createCourseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateCourseProductRequest1?: CreateCourseProductRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CourseProductCreateResponse1>>;
    public createCourseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateCourseProductRequest1?: CreateCourseProductRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CourseProductCreateResponse1>>;
    public createCourseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateCourseProductRequest1?: CreateCourseProductRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling createCourseProduct.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling createCourseProduct.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling createCourseProduct.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CourseProductCreateResponse1>(`${this.basePath}/rpc/productSystem/v1/product/createCourseProduct`,
            CreateCourseProductRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * creates course series
     * creates course series
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param CreateCourseSeriesRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCourseSeries(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateCourseSeriesRequest1?: CreateCourseSeriesRequest1, observe?: 'body', reportProgress?: boolean): Observable<CourseSeriesCreateResponse1>;
    public createCourseSeries(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateCourseSeriesRequest1?: CreateCourseSeriesRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CourseSeriesCreateResponse1>>;
    public createCourseSeries(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateCourseSeriesRequest1?: CreateCourseSeriesRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CourseSeriesCreateResponse1>>;
    public createCourseSeries(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateCourseSeriesRequest1?: CreateCourseSeriesRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling createCourseSeries.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling createCourseSeries.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling createCourseSeries.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CourseSeriesCreateResponse1>(`${this.basePath}/rpc/productSystem/v1/product/createCourseSeries`,
            CreateCourseSeriesRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create exercise product
     * create exercise product
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param CreateExerciseProductRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createExerciseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateExerciseProductRequest1?: CreateExerciseProductRequest1, observe?: 'body', reportProgress?: boolean): Observable<ExerciseProductCreateResponse1>;
    public createExerciseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateExerciseProductRequest1?: CreateExerciseProductRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExerciseProductCreateResponse1>>;
    public createExerciseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateExerciseProductRequest1?: CreateExerciseProductRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExerciseProductCreateResponse1>>;
    public createExerciseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateExerciseProductRequest1?: CreateExerciseProductRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling createExerciseProduct.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling createExerciseProduct.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling createExerciseProduct.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ExerciseProductCreateResponse1>(`${this.basePath}/rpc/productSystem/v1/product/createExerciseProduct`,
            CreateExerciseProductRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create issue product
     * create issue product
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param CreateIssueProductRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createIssueProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateIssueProductRequest1?: CreateIssueProductRequest1, observe?: 'body', reportProgress?: boolean): Observable<IssueProductCreateResponse1>;
    public createIssueProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateIssueProductRequest1?: CreateIssueProductRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IssueProductCreateResponse1>>;
    public createIssueProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateIssueProductRequest1?: CreateIssueProductRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IssueProductCreateResponse1>>;
    public createIssueProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateIssueProductRequest1?: CreateIssueProductRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling createIssueProduct.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling createIssueProduct.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling createIssueProduct.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<IssueProductCreateResponse1>(`${this.basePath}/rpc/productSystem/v1/product/createIssueProduct`,
            CreateIssueProductRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create coupons and license order items
     * create coupons and its license order items. If LicenseConfigTemplate name given: it should search in mandant
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param CreateOrderRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrder(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateOrderRequest1?: CreateOrderRequest1, observe?: 'body', reportProgress?: boolean): Observable<OrderCreateOrderResponse1>;
    public createOrder(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateOrderRequest1?: CreateOrderRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderCreateOrderResponse1>>;
    public createOrder(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateOrderRequest1?: CreateOrderRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderCreateOrderResponse1>>;
    public createOrder(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateOrderRequest1?: CreateOrderRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling createOrder.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling createOrder.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling createOrder.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderCreateOrderResponse1>(`${this.basePath}/rpc/extern/v1/order/createOrder`,
            CreateOrderRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create coupons and license order items
     * create coupons and its license order items. If LicenseConfigTemplate name given: it should search in mandant
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param CreateOrderRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrder2(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateOrderRequest1?: CreateOrderRequest11, observe?: 'body', reportProgress?: boolean): Observable<OrderCreateOrderResponse11>;
    public createOrder2(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateOrderRequest1?: CreateOrderRequest11, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderCreateOrderResponse11>>;
    public createOrder2(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateOrderRequest1?: CreateOrderRequest11, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderCreateOrderResponse11>>;
    public createOrder2(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateOrderRequest1?: CreateOrderRequest11, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling createOrder2.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling createOrder2.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling createOrder2.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderCreateOrderResponse11>(`${this.basePath}/rpc/extern/v2/order/createOrder`,
            CreateOrderRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create coupons and license order items based on order systems
     * create coupons and its license order items. If LicenseConfigTemplate name given: it should search in mandant
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param CreateOrderRequest3 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrder3(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateOrderRequest3?: CreateOrderRequest3, observe?: 'body', reportProgress?: boolean): Observable<OrderCreateOrderResponse3>;
    public createOrder3(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateOrderRequest3?: CreateOrderRequest3, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderCreateOrderResponse3>>;
    public createOrder3(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateOrderRequest3?: CreateOrderRequest3, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderCreateOrderResponse3>>;
    public createOrder3(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateOrderRequest3?: CreateOrderRequest3, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling createOrder3.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling createOrder3.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling createOrder3.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderCreateOrderResponse3>(`${this.basePath}/rpc/extern/v3/order/createOrder`,
            CreateOrderRequest3,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create topic product
     * create topic product
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param CreateTopicProductRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTopicProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateTopicProductRequest1?: CreateTopicProductRequest1, observe?: 'body', reportProgress?: boolean): Observable<TopicProductCreateResponse1>;
    public createTopicProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateTopicProductRequest1?: CreateTopicProductRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TopicProductCreateResponse1>>;
    public createTopicProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateTopicProductRequest1?: CreateTopicProductRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TopicProductCreateResponse1>>;
    public createTopicProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CreateTopicProductRequest1?: CreateTopicProductRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling createTopicProduct.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling createTopicProduct.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling createTopicProduct.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TopicProductCreateResponse1>(`${this.basePath}/rpc/productSystem/v1/product/createTopicProduct`,
            CreateTopicProductRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create/Generate and get license order items by subject and product reference, return license order items.
     * Create/Generate and get license order items by subject and product reference, return license order items. This ressource is used for free products, where a coupon code is not needed.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param OrderGenerateForProductRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateForProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderGenerateForProductRequest1?: OrderGenerateForProductRequest1, observe?: 'body', reportProgress?: boolean): Observable<OrderGenerateForProductResponse1>;
    public generateForProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderGenerateForProductRequest1?: OrderGenerateForProductRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderGenerateForProductResponse1>>;
    public generateForProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderGenerateForProductRequest1?: OrderGenerateForProductRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderGenerateForProductResponse1>>;
    public generateForProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderGenerateForProductRequest1?: OrderGenerateForProductRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling generateForProduct.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling generateForProduct.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling generateForProduct.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderGenerateForProductResponse1>(`${this.basePath}/rpc/orderSystem/v1/order/generateForProduct`,
            OrderGenerateForProductRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get asset product
     * get asset product
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param GetAssetProductRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAssetProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetAssetProductRequest1?: GetAssetProductRequest1, observe?: 'body', reportProgress?: boolean): Observable<AssetProductGetResponse1>;
    public getAssetProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetAssetProductRequest1?: GetAssetProductRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AssetProductGetResponse1>>;
    public getAssetProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetAssetProductRequest1?: GetAssetProductRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AssetProductGetResponse1>>;
    public getAssetProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetAssetProductRequest1?: GetAssetProductRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling getAssetProduct.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling getAssetProduct.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling getAssetProduct.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AssetProductGetResponse1>(`${this.basePath}/rpc/productSystem/v1/product/getAssetProduct`,
            GetAssetProductRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get license order items by subject, return license order items.
     * Get license order items by subjects, return license order items.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param OrderGetBySubjectRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBySubject(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderGetBySubjectRequest1?: OrderGetBySubjectRequest1, observe?: 'body', reportProgress?: boolean): Observable<OrderGetBySubjectResponse1>;
    public getBySubject(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderGetBySubjectRequest1?: OrderGetBySubjectRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderGetBySubjectResponse1>>;
    public getBySubject(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderGetBySubjectRequest1?: OrderGetBySubjectRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderGetBySubjectResponse1>>;
    public getBySubject(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderGetBySubjectRequest1?: OrderGetBySubjectRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling getBySubject.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling getBySubject.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling getBySubject.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderGetBySubjectResponse1>(`${this.basePath}/rpc/orderSystem/v1/order/getBySubject`,
            OrderGetBySubjectRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get course product
     * get course product
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param GetCourseProductRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCourseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetCourseProductRequest1?: GetCourseProductRequest1, observe?: 'body', reportProgress?: boolean): Observable<CourseProductGetResponse1>;
    public getCourseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetCourseProductRequest1?: GetCourseProductRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CourseProductGetResponse1>>;
    public getCourseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetCourseProductRequest1?: GetCourseProductRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CourseProductGetResponse1>>;
    public getCourseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetCourseProductRequest1?: GetCourseProductRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling getCourseProduct.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling getCourseProduct.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling getCourseProduct.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CourseProductGetResponse1>(`${this.basePath}/rpc/productSystem/v1/product/getCourseProduct`,
            GetCourseProductRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get course series
     * get course series
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param GetCourseSeriesRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCourseSeries(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetCourseSeriesRequest1?: GetCourseSeriesRequest1, observe?: 'body', reportProgress?: boolean): Observable<CourseSeriesGetResponse1>;
    public getCourseSeries(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetCourseSeriesRequest1?: GetCourseSeriesRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CourseSeriesGetResponse1>>;
    public getCourseSeries(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetCourseSeriesRequest1?: GetCourseSeriesRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CourseSeriesGetResponse1>>;
    public getCourseSeries(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetCourseSeriesRequest1?: GetCourseSeriesRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling getCourseSeries.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling getCourseSeries.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling getCourseSeries.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CourseSeriesGetResponse1>(`${this.basePath}/rpc/productSystem/v1/product/getCourseSeries`,
            GetCourseSeriesRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get exercise product
     * get exercise product
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param GetExerciseProductRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getExerciseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetExerciseProductRequest1?: GetExerciseProductRequest1, observe?: 'body', reportProgress?: boolean): Observable<ExerciseProductGetResponse1>;
    public getExerciseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetExerciseProductRequest1?: GetExerciseProductRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExerciseProductGetResponse1>>;
    public getExerciseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetExerciseProductRequest1?: GetExerciseProductRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExerciseProductGetResponse1>>;
    public getExerciseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetExerciseProductRequest1?: GetExerciseProductRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling getExerciseProduct.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling getExerciseProduct.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling getExerciseProduct.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ExerciseProductGetResponse1>(`${this.basePath}/rpc/productSystem/v1/product/getExerciseProduct`,
            GetExerciseProductRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get issue product
     * get issue product
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param GetIssueProductRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIssueProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetIssueProductRequest1?: GetIssueProductRequest1, observe?: 'body', reportProgress?: boolean): Observable<IssueProductGetResponse1>;
    public getIssueProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetIssueProductRequest1?: GetIssueProductRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IssueProductGetResponse1>>;
    public getIssueProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetIssueProductRequest1?: GetIssueProductRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IssueProductGetResponse1>>;
    public getIssueProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetIssueProductRequest1?: GetIssueProductRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling getIssueProduct.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling getIssueProduct.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling getIssueProduct.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<IssueProductGetResponse1>(`${this.basePath}/rpc/productSystem/v1/product/getIssueProduct`,
            GetIssueProductRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all order systems and relevant identity providers
     * Get all order systems and relevant identity providers
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param OrderGetOrderSystemRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderSystem(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderGetOrderSystemRequest1?: OrderGetOrderSystemRequest1, observe?: 'body', reportProgress?: boolean): Observable<OrderGetOrderSystemResponse1>;
    public getOrderSystem(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderGetOrderSystemRequest1?: OrderGetOrderSystemRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderGetOrderSystemResponse1>>;
    public getOrderSystem(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderGetOrderSystemRequest1?: OrderGetOrderSystemRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderGetOrderSystemResponse1>>;
    public getOrderSystem(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderGetOrderSystemRequest1?: OrderGetOrderSystemRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling getOrderSystem.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling getOrderSystem.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling getOrderSystem.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderGetOrderSystemResponse1>(`${this.basePath}/rpc/orderSystem/v1/order/getOrderSystem`,
            OrderGetOrderSystemRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get topic product
     * get topic product
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param GetTopicProductRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTopicProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetTopicProductRequest1?: GetTopicProductRequest1, observe?: 'body', reportProgress?: boolean): Observable<TopicProductGetResponse1>;
    public getTopicProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetTopicProductRequest1?: GetTopicProductRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TopicProductGetResponse1>>;
    public getTopicProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetTopicProductRequest1?: GetTopicProductRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TopicProductGetResponse1>>;
    public getTopicProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, GetTopicProductRequest1?: GetTopicProductRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling getTopicProduct.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling getTopicProduct.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling getTopicProduct.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TopicProductGetResponse1>(`${this.basePath}/rpc/productSystem/v1/product/getTopicProduct`,
            GetTopicProductRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all coupons of the user
     * get all coupons of the user by subject
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param OrderLookUpCouponRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lookUpCoupon(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderLookUpCouponRequest1?: OrderLookUpCouponRequest1, observe?: 'body', reportProgress?: boolean): Observable<OrderLookUpCouponResponse1>;
    public lookUpCoupon(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderLookUpCouponRequest1?: OrderLookUpCouponRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderLookUpCouponResponse1>>;
    public lookUpCoupon(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderLookUpCouponRequest1?: OrderLookUpCouponRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderLookUpCouponResponse1>>;
    public lookUpCoupon(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderLookUpCouponRequest1?: OrderLookUpCouponRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling lookUpCoupon.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling lookUpCoupon.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling lookUpCoupon.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderLookUpCouponResponse1>(`${this.basePath}/rpc/orderSystem/v1/order/lookUpCoupon`,
            OrderLookUpCouponRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get course reference by subjects and skus, return course references.
     * Get course reference by subjects and skus, return course references.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param ProductLookUpSkuRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lookUpSku(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductLookUpSkuRequest1?: ProductLookUpSkuRequest1, observe?: 'body', reportProgress?: boolean): Observable<ProductLookUpSkuResponse1>;
    public lookUpSku(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductLookUpSkuRequest1?: ProductLookUpSkuRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductLookUpSkuResponse1>>;
    public lookUpSku(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductLookUpSkuRequest1?: ProductLookUpSkuRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductLookUpSkuResponse1>>;
    public lookUpSku(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductLookUpSkuRequest1?: ProductLookUpSkuRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling lookUpSku.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling lookUpSku.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling lookUpSku.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProductLookUpSkuResponse1>(`${this.basePath}/rpc/productSystem/v1/product/lookUpSku`,
            ProductLookUpSkuRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * modify asset product
     * modify asset product
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param ModifyAssetProductRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modifyAssetProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyAssetProductRequest1?: ModifyAssetProductRequest1, observe?: 'body', reportProgress?: boolean): Observable<AssetProductModifyResponse1>;
    public modifyAssetProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyAssetProductRequest1?: ModifyAssetProductRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AssetProductModifyResponse1>>;
    public modifyAssetProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyAssetProductRequest1?: ModifyAssetProductRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AssetProductModifyResponse1>>;
    public modifyAssetProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyAssetProductRequest1?: ModifyAssetProductRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling modifyAssetProduct.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling modifyAssetProduct.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling modifyAssetProduct.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AssetProductModifyResponse1>(`${this.basePath}/rpc/productSystem/v1/product/modifyAssetProduct`,
            ModifyAssetProductRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * modify course product
     * modify course product
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param ModifyCourseProductRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modifyCourseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyCourseProductRequest1?: ModifyCourseProductRequest1, observe?: 'body', reportProgress?: boolean): Observable<CourseProductModifyResponse1>;
    public modifyCourseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyCourseProductRequest1?: ModifyCourseProductRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CourseProductModifyResponse1>>;
    public modifyCourseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyCourseProductRequest1?: ModifyCourseProductRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CourseProductModifyResponse1>>;
    public modifyCourseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyCourseProductRequest1?: ModifyCourseProductRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling modifyCourseProduct.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling modifyCourseProduct.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling modifyCourseProduct.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CourseProductModifyResponse1>(`${this.basePath}/rpc/productSystem/v1/product/modifyCourseProduct`,
            ModifyCourseProductRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * modify course series
     * get course series
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param ModifyCourseSeriesRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modifyCourseSeries(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyCourseSeriesRequest1?: ModifyCourseSeriesRequest1, observe?: 'body', reportProgress?: boolean): Observable<CourseSeriesModifyResponse1>;
    public modifyCourseSeries(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyCourseSeriesRequest1?: ModifyCourseSeriesRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CourseSeriesModifyResponse1>>;
    public modifyCourseSeries(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyCourseSeriesRequest1?: ModifyCourseSeriesRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CourseSeriesModifyResponse1>>;
    public modifyCourseSeries(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyCourseSeriesRequest1?: ModifyCourseSeriesRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling modifyCourseSeries.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling modifyCourseSeries.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling modifyCourseSeries.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CourseSeriesModifyResponse1>(`${this.basePath}/rpc/productSystem/v1/product/modifyCourseSeries`,
            ModifyCourseSeriesRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * modify exercise product
     * modify exercise product
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param ModifyExerciseProductRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modifyExerciseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyExerciseProductRequest1?: ModifyExerciseProductRequest1, observe?: 'body', reportProgress?: boolean): Observable<ExerciseProductModifyResponse1>;
    public modifyExerciseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyExerciseProductRequest1?: ModifyExerciseProductRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExerciseProductModifyResponse1>>;
    public modifyExerciseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyExerciseProductRequest1?: ModifyExerciseProductRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExerciseProductModifyResponse1>>;
    public modifyExerciseProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyExerciseProductRequest1?: ModifyExerciseProductRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling modifyExerciseProduct.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling modifyExerciseProduct.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling modifyExerciseProduct.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ExerciseProductModifyResponse1>(`${this.basePath}/rpc/productSystem/v1/product/modifyExerciseProduct`,
            ModifyExerciseProductRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * modify issue product
     * modify issue product
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param ModifyIssueProductRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modifyIssueProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyIssueProductRequest1?: ModifyIssueProductRequest1, observe?: 'body', reportProgress?: boolean): Observable<IssueProductModifyResponse1>;
    public modifyIssueProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyIssueProductRequest1?: ModifyIssueProductRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IssueProductModifyResponse1>>;
    public modifyIssueProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyIssueProductRequest1?: ModifyIssueProductRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IssueProductModifyResponse1>>;
    public modifyIssueProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyIssueProductRequest1?: ModifyIssueProductRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling modifyIssueProduct.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling modifyIssueProduct.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling modifyIssueProduct.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<IssueProductModifyResponse1>(`${this.basePath}/rpc/productSystem/v1/product/modifyIssueProduct`,
            ModifyIssueProductRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change attributes of license order items
     * Change attributes of license order items
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param OrderModifyLicenseOrderItemRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modifyLicenseOrderItem(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderModifyLicenseOrderItemRequest1?: OrderModifyLicenseOrderItemRequest1, observe?: 'body', reportProgress?: boolean): Observable<OrderModifyLicenseResponse1>;
    public modifyLicenseOrderItem(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderModifyLicenseOrderItemRequest1?: OrderModifyLicenseOrderItemRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderModifyLicenseResponse1>>;
    public modifyLicenseOrderItem(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderModifyLicenseOrderItemRequest1?: OrderModifyLicenseOrderItemRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderModifyLicenseResponse1>>;
    public modifyLicenseOrderItem(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderModifyLicenseOrderItemRequest1?: OrderModifyLicenseOrderItemRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling modifyLicenseOrderItem.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling modifyLicenseOrderItem.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling modifyLicenseOrderItem.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderModifyLicenseResponse1>(`${this.basePath}/rpc/orderSystem/v1/order/modifyLicenseOrderItem`,
            OrderModifyLicenseOrderItemRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * modify topic product
     * modify topic product
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param ModifyTopicProductRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modifyTopicProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyTopicProductRequest1?: ModifyTopicProductRequest1, observe?: 'body', reportProgress?: boolean): Observable<TopicProductModifyResponse1>;
    public modifyTopicProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyTopicProductRequest1?: ModifyTopicProductRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TopicProductModifyResponse1>>;
    public modifyTopicProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyTopicProductRequest1?: ModifyTopicProductRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TopicProductModifyResponse1>>;
    public modifyTopicProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ModifyTopicProductRequest1?: ModifyTopicProductRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling modifyTopicProduct.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling modifyTopicProduct.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling modifyTopicProduct.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TopicProductModifyResponse1>(`${this.basePath}/rpc/productSystem/v1/product/modifyTopicProduct`,
            ModifyTopicProductRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all products of the coupon code
     * Get all products of the coupon code
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param OrderQuickSearchCouponCodeRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public quickSearchCouponCode(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderQuickSearchCouponCodeRequest1?: OrderQuickSearchCouponCodeRequest1, observe?: 'body', reportProgress?: boolean): Observable<OrderQuickSearchCouponCodeResponse1>;
    public quickSearchCouponCode(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderQuickSearchCouponCodeRequest1?: OrderQuickSearchCouponCodeRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderQuickSearchCouponCodeResponse1>>;
    public quickSearchCouponCode(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderQuickSearchCouponCodeRequest1?: OrderQuickSearchCouponCodeRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderQuickSearchCouponCodeResponse1>>;
    public quickSearchCouponCode(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderQuickSearchCouponCodeRequest1?: OrderQuickSearchCouponCodeRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling quickSearchCouponCode.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling quickSearchCouponCode.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling quickSearchCouponCode.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderQuickSearchCouponCodeResponse1>(`${this.basePath}/rpc/orderSystem/v1/order/quickSearchCouponCode`,
            OrderQuickSearchCouponCodeRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * remove asset definition
     * remove asset definition by file reference
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param RemoveAssetDefinitionRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeAssetDefinition(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, RemoveAssetDefinitionRequest1?: RemoveAssetDefinitionRequest1, observe?: 'body', reportProgress?: boolean): Observable<AssetDefinitionRemoveResponse1>;
    public removeAssetDefinition(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, RemoveAssetDefinitionRequest1?: RemoveAssetDefinitionRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AssetDefinitionRemoveResponse1>>;
    public removeAssetDefinition(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, RemoveAssetDefinitionRequest1?: RemoveAssetDefinitionRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AssetDefinitionRemoveResponse1>>;
    public removeAssetDefinition(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, RemoveAssetDefinitionRequest1?: RemoveAssetDefinitionRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling removeAssetDefinition.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling removeAssetDefinition.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling removeAssetDefinition.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AssetDefinitionRemoveResponse1>(`${this.basePath}/rpc/productSystem/v1/product/removeAssetDefinition`,
            RemoveAssetDefinitionRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
