import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {ExtendedSearchTableComponent} from './component/extended-search-table/extended-search-table.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ExtendedSearchFormComponent} from './component/extended-search-form/extended-search-form.component';
import {ExtendedSearchUserPageComponent} from './component/extended-search-page/extended-search-user-page';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [ExtendedSearchUserPageComponent, ExtendedSearchTableComponent, ExtendedSearchFormComponent],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        ReactiveFormsModule
    ]
})
export class ExtendedSearchModule {
}
