import {Injectable} from '@angular/core';
import {ClientService} from '../api/client.service';

/*
Extends ClientService to dynamically set the basePath of the api
 */

@Injectable({
  providedIn: 'root'
})
export class HdIdpApiService extends ClientService {

  /**
   *
   * @param path
   */
  setBasePath(path: string) {
    this.basePath = path;
  }

  /**
   *
   */
  getBasePath(): string {
    return this.basePath;
  }
}
