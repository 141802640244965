import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Settings} from '../models/settings';
import {
  CouponChangeDTO1,
  CouponChangeRequest1,
  CouponChangeStateDTO1,
  CouponChangeStateRequest1,
  CouponChangeStateResponse1,
  CouponLookUpDTO1,
  CouponLookUpRequest1,
  CouponLookUpResponse1,
  CouponSearchDTO1,
  CouponSearchRequest1,
  CouponSearchResponse1,
  OrderSyncForUserDTO1,
  OrderSyncForUserRequest1,
  OrderSyncForUserResponse1,
  OrderSystemDTO1,
  OSCClient1Service,
  OSCPublic1Service,
  OSCSystem1Service
} from '../modules/api/osc_angular-client';
import {APP_CONFIGURATION} from './settings.http.service';
import CouponStateEnum = CouponChangeStateDTO1.CouponStateEnum;

@Injectable({
  providedIn: 'root'
})
export class OscApiService {

  // Fallback
  DEFAULT_ORDER_SYSTEMS = ['IAPROS1', 'HEPMAGENTO1', 'RICHMPERFORMX1', 'PHZHAZURE1', 'DIHKSHOPWARE1'];
  DEFAULT_COUPON_ORDER_SYSTEMS = ['IAPROS1'];

  couponOrderSystems: string[] = [];
  orderSystems: string[] = [];

  constructor(
    private oSCClientService: OSCClient1Service,
    private oSCPublicService: OSCPublic1Service,
    private oSCSystem1Service: OSCSystem1Service,
    @Inject(APP_CONFIGURATION) public readonly configuration: Settings,
  ) {
    this.couponOrderSystems = typeof this.configuration.couponOrderSystems !== 'undefined' ? this.configuration.couponOrderSystems : this.DEFAULT_COUPON_ORDER_SYSTEMS;
    this.orderSystems = typeof this.configuration.orderSystems !== 'undefined' ? this.configuration.orderSystems : this.DEFAULT_ORDER_SYSTEMS;
  }

  /**
   * Makes orderSyncForUser request
   * @param subjectID for request
   */
  orderSyncForUser(subjectID: string): Observable<OrderSyncForUserResponse1> {
    const orderSyncForUserDTOs: OrderSyncForUserDTO1[] = [];

    for (const ordersystem of this.orderSystems) {

      const order_system_dto: OrderSystemDTO1 = {
        dto_id: '',
        order_system_identifier: ordersystem,
      };

      const orderSyncForUserDTO: OrderSyncForUserDTO1 = {
        dto_id: '',
        subject: subjectID,
        force_sync: true,
        order_system_dto
      };

      orderSyncForUserDTOs.push(orderSyncForUserDTO);
    }

    const request: OrderSyncForUserRequest1 = {
      order_sync_for_user_dtos: orderSyncForUserDTOs
    };

    return this.oSCClientService.orderSyncForUser('', '', '', request).pipe(map((orderSyncForUserResponse) => {
      return orderSyncForUserResponse;
    }));
  }

  /**
   * Get enum value from string
   * @param couponState to get enum
   */
  getCouponStateEnum(couponState: string) {
    let couponStateEnum: CouponStateEnum = null;

    switch (couponState) {
      case 'COUPON_STATE_VALID':
        couponStateEnum = CouponStateEnum.VALID;
        break;
      case 'COUPON_STATE_ACTIVE':
        couponStateEnum = CouponStateEnum.ACTIVE;
        break;
      case 'COUPON_STATE_ASSIGNED':
        couponStateEnum = CouponStateEnum.ASSIGNED;
        break;
      case 'COUPON_STATE_WITHDRAWN':
        couponStateEnum = CouponStateEnum.WITHDRAWN;
        break;
    }

    return couponStateEnum;
  }

  /**
   * Change state of couponCode
   * @param couponCode
   * @param couponState
   * @param subject
   * @param orderSystemDTO
   */
  changeState(couponCode: string, couponState: string, subject: string, orderSystemDTO: OrderSystemDTO1): Observable<CouponChangeStateResponse1> {

    const order_system_dto: OrderSystemDTO1 = orderSystemDTO;

    const couponChangeStateDTO: CouponChangeStateDTO1 = {
      dto_id: '',
      subject: typeof subject === 'undefined' ? '' : subject,
      coupon_code: couponCode,
      coupon_state: this.getCouponStateEnum(couponState),
      order_system_dto
    };

    const couponChangeStateRequest: CouponChangeStateRequest1 = {
      coupon_change_state_dtos: [couponChangeStateDTO]
    };

    return this.oSCSystem1Service.couponChangeState('', '', '', couponChangeStateRequest);
  }

  /**
   *
   * @param couponChangeDTO
   */
  changeCoupon(couponChangeDTO: CouponChangeDTO1): any {

    const couponChangeRequest: CouponChangeRequest1 = {
      coupon_change_dtos: [couponChangeDTO]
    };

    console.log('[osc-api-service] Sending request: ', couponChangeDTO);
    return this.oSCSystem1Service.couponChange('', '', '', couponChangeRequest);
  }

  /**
   * Quickseach coupon code
   *
   * @param couponCode
   * @param quickSearch
   */
  quickSearchCouponCode(couponCode, quickSearch: boolean = true): Observable<CouponSearchResponse1> {

    const coupon_search_dtos: CouponSearchDTO1[] = [];

    for (const couponOrderSystem of this.couponOrderSystems) {

      const order_system_dto: OrderSystemDTO1 = {
        dto_id: '',
        order_system_identifier: couponOrderSystem
      };

      const couponSearchDTO: CouponSearchDTO1 = {
        dto_id: '',
        coupon_code: couponCode,
        order_system_dto
      };

      coupon_search_dtos.push(couponSearchDTO);
    }

    const request: CouponSearchRequest1 = {
      coupon_search_dtos
    };

    return this.oSCSystem1Service.couponSearch('', '', '', request, quickSearch);
  }

  /**
   * Look up coupons associated to user
   * @param subject
   */
  couponLookUp(subject: string): Observable<CouponLookUpResponse1> {

    let coupon_look_up_dtos: CouponLookUpDTO1[] = [];

    for (const couponOrderSystem of this.couponOrderSystems) {

      const order_system_dto: OrderSystemDTO1 = {
        dto_id: '',
        order_system_identifier: couponOrderSystem
      };

      const couponLookUpDTO: CouponLookUpDTO1 = {
        subject,
        order_system_dto
      };

      coupon_look_up_dtos.push(couponLookUpDTO);
    }

    const request: CouponLookUpRequest1 = {
      coupon_look_up_dtos
    };

    return this.oSCSystem1Service.couponLookUp('', '', '', request);
  }


}
