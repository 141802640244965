import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {
  ProviderDetailResponse,
  UserExtendedSearchDetailResponse,
  UserExtendedSearchDTO,
  UserExtendedSearchRequest, UserExtendedSearchResponse,
} from '../../api/aua_angular-client';
import {Observable, Subject} from 'rxjs';
import {AuaApiService} from '../../../service/aua-api.service';
import {map} from 'rxjs/operators';
import {MessengerService} from '../../shared/service/messenger.service';

@Injectable({
    providedIn: 'root'
})
export class ExtendedSearchService {

    storedSearchFormGroup: FormGroup;
    storedSearchTableData: UserExtendedSearchDTO[];
    searchRequestSubject: Subject<FormGroup> = new Subject<FormGroup>();

    constructor(private auaApiService: AuaApiService, private messengerService: MessengerService) {
    }

    storeSearchFormGroup(formGroup: FormGroup) {
        this.storedSearchFormGroup = formGroup;
    }

    storeSearchTableData(tableData: UserExtendedSearchDTO[]) {
        this.storedSearchTableData = tableData;
    }

    getStoredSearchTableData(): UserExtendedSearchDTO[] {
        return this.storedSearchTableData;
    }

    getStoredSearchFormGroup(): FormGroup {
        return this.storedSearchFormGroup;
    }

    getSearchRequestSubject(): Subject<FormGroup> {
        return this.searchRequestSubject;
    }

    getProviderList() {
        return this.auaApiService.getProviders().pipe(map((response) => {
            const detailResultResponse: ProviderDetailResponse = response.provider_list_detail_response;
            let returnProviders: Array<string> = [];

            if (typeof detailResultResponse.providers !== 'undefined') {
                returnProviders = detailResultResponse.providers;
            } else {
                this.messengerService.sendNotification('AUA_NO_PROVIDER_GROUP', 'AUA | listProviders');
            }

            return returnProviders;
        }));
    }

    getMandantGroupList() {
        return this.auaApiService.listMandantGroups().pipe(map((response) => {
            const groupListDetailResponse = response.group_list_detail_response;
            const groups: Array<string> = groupListDetailResponse.groups;
            let returnGroup: Array<string> = [];

            if (typeof groups !== 'undefined') {
                const makeUnique = new Set(groups); // Set eliminiates duplicates.
                const cleanedGroups: Array<string> = Array.from(makeUnique); // Revert back to array
                returnGroup = cleanedGroups.filter(e => e !== '');
            } else {
                this.messengerService.sendNotification('AUA_NO_MANDANT_GROUP', 'AUA | listGroups');
            }

            return returnGroup;
        }));
    }

    /**
     * @param formData with data for search
     */
    extendedSearch(formData: FormGroup): Observable<UserExtendedSearchDTO[]> {
        const request: UserExtendedSearchRequest = formData.value;
        return this.auaApiService.extendedSearchUser(request).pipe(map((response: UserExtendedSearchResponse) => {
            const detailResultResponse: UserExtendedSearchDetailResponse = response.user_extended_search_detail_response;
            return detailResultResponse.users;
        }));
    }
}
