import { AttachPermissionDTO, AUAClientService, CreatePropertyTemplateRequest, DetachPermissionDTO, DetailResultResponse, GroupDTO, PermissionAttachedResponse, PermissionCreateResponse, PermissionDeleteResponse, PermissionDetachedResponse, PermissionDTO, PermissionListResponse, PermissionSetResponse, PropertyCreateDTO, PropertyCreateTemplatesResponse, PropertyGetTemplatesResponse, PropertyTemplateDTO, ResolveRoleResponse, RoleDTO, RoleListResponse, SetPermissionDTO, TogglePropertyTemplateRequest, UserDeleteIdentityRequest, UserDetailResponse, UserExtendedSearchRequest, UserExternalSearchRequest, UserExternalSearchResponse, UserGetGroupsGlobalResponse, UserGetIdentitiesResponse, UserGetPropertiesResponse, UserGetRequest, UserGetResponse, UserGetRolesResponse, UserQuickSearchRequest, UserQuickSearchResponse, UserSetEmailRequest, UserSetGlobalResponse, UserSetGroupsRequest, UserSetPropertiesResponse, UserSetPropertyRequest, UserSetRolesRequest } from '../modules/api/aua_angular-client';
import { map } from 'rxjs/operators';
import { MessengerService } from '../modules/shared/service/messenger.service';
import { UserSetGroupResponse } from '../modules/api/idp_angular-client';
var SearchTypeEnum = UserQuickSearchRequest.SearchTypeEnum;
import * as i0 from "@angular/core";
import * as i1 from "../modules/api/aua_angular-client/api/aUAClient.service";
import * as i2 from "../modules/shared/service/messenger.service";
var AuaApiService = /** @class */ (function () {
    function AuaApiService(auaClientService, messengerService) {
        this.auaClientService = auaClientService;
        this.messengerService = messengerService;
    }
    /**
     * @param subject
     */
    AuaApiService.prototype.getUser = function (subject) {
        var _this = this;
        var userRequest = [{ subject: subject }];
        return this.auaClientService.getUsers(userRequest, '', '', '').pipe(map(function (userGetResponse) {
            if (userGetResponse.global_response.result_state === 'WARNING') {
                var detailResponse = userGetResponse.user_get_detail_response;
                detailResponse.forEach(function (detailResponse) {
                    var detailResultResponse = detailResponse.detail_result_response;
                    if (detailResultResponse.result_state === 'ERROR' || detailResultResponse.result_state === 'WARNING') {
                        _this.messengerService.sendNotification(detailResultResponse.result_code, 'AUA | getUser');
                    }
                });
            }
            return userGetResponse;
        }));
    };
    /**
     * @param externalId
     * @param provider
     */
    AuaApiService.prototype.externalSearchUser = function (externalId, provider) {
        var userExternalSearchRequest = {
            external_id: externalId,
            provider: provider
        };
        return this.auaClientService.externalSearchUser(userExternalSearchRequest, '', '', '');
    };
    /**
     * @param subject
     */
    AuaApiService.prototype.getUserRoles = function (subject) {
        var _this = this;
        var rolesRequest = { subject: subject };
        return this.auaClientService.getRoles(rolesRequest, '', '', '').pipe(map(function (rolesGetResponse) {
            if (rolesGetResponse.global_response.result_state === 'WARNING') {
                _this.messengerService.sendNotification(rolesGetResponse.global_response.result_code, 'AUA | getUserRoles');
            }
            return rolesGetResponse;
        }));
    };
    /**
     * @param subject
     */
    AuaApiService.prototype.getUserProperties = function (subject) {
        var _this = this;
        var propertyRequest = { subject: subject };
        return this.auaClientService.getPropertiesForUser(propertyRequest, '', '', '').pipe(map(function (propertiesGetResponse) {
            if (propertiesGetResponse.global_response.result_state === 'WARNING') {
                _this.messengerService.sendNotification(propertiesGetResponse.global_response.result_code, 'AUA | getUserProperties');
            }
            return propertiesGetResponse;
        }));
    };
    /**
     * @param subject
     */
    AuaApiService.prototype.getUserIdentities = function (subject) {
        var _this = this;
        var identityRequest = { subject: subject };
        return this.auaClientService.getIdentitiesForUser(identityRequest, '', '', '').pipe(map(function (userGetIdentitesResponse) {
            if (userGetIdentitesResponse.global_response.result_state === 'WARNING') {
                _this.messengerService.sendNotification(userGetIdentitesResponse.global_response.result_code, 'AUA | getUserIdentities');
            }
            return userGetIdentitesResponse;
        }));
    };
    /**
     * @param roles
     */
    AuaApiService.prototype.createRoles = function (roles) {
        return this.auaClientService.createRoles(roles, '', '', '');
    };
    /**
     * @param permissionsToCreate
     */
    AuaApiService.prototype.createPermission = function (permissionsToCreate) {
        return this.auaClientService.createPermissions(permissionsToCreate, '', '', '');
    };
    /**
     * @param permissionsToDetach
     * @param role
     */
    AuaApiService.prototype.detachPermissions = function (permissionsToDetach, role) {
        var detachPermissionRequests = [];
        permissionsToDetach.forEach(function (permissionDTO) {
            var attachPermissionDTO = {
                role: role,
                permission: permissionDTO
            };
            detachPermissionRequests.push(attachPermissionDTO);
        });
        return this.auaClientService.detachPermissions(detachPermissionRequests, '', '', '');
    };
    AuaApiService.prototype.listProperties = function () {
        return this.auaClientService.getPropertyTemplates('', '', '');
    };
    /**
     * @param createPropertyTemplateRequest
     */
    AuaApiService.prototype.createPropertyTemplate = function (createPropertyTemplateRequest) {
        return this.auaClientService.createPropertyTemplates(createPropertyTemplateRequest, '', '', '');
    };
    /**
     * @param propertyTemplateDTO
     */
    AuaApiService.prototype.togglePropertyTemplate = function (propertyTemplateDTO) {
        var togglePropertyTemplateRequest = [{
                template_id: propertyTemplateDTO.id,
                active: propertyTemplateDTO.active
            }];
        return this.auaClientService.togglePropertyTemplates(togglePropertyTemplateRequest, '', '', '');
    };
    AuaApiService.prototype.listPermissions = function () {
        return this.auaClientService.listPermissions('', '', '');
    };
    /**
     * @param permissionsToAttach
     * @param role
     */
    AuaApiService.prototype.attachPermissions = function (permissionsToAttach, role) {
        var attachPermissionRequests = [];
        permissionsToAttach.forEach(function (permissionDTO) {
            var attachPermissionDTO = {
                role: role,
                permission: permissionDTO
            };
            attachPermissionRequests.push(attachPermissionDTO);
        });
        return this.auaClientService.attachPermissions(attachPermissionRequests, '', '', '');
    };
    /**
     * @param permissions
     * @param role
     */
    AuaApiService.prototype.setPermissions = function (permissions, role) {
        var setPermissionDTOs = [{
                role: role,
                permissions: permissions
            }];
        return this.auaClientService.setPermissions(setPermissionDTOs, '', '', '');
    };
    AuaApiService.prototype.listMandantGroups = function () {
        return this.auaClientService.listGroups('', '', '');
    };
    AuaApiService.prototype.listRoles = function () {
        var _this = this;
        return this.auaClientService.listRoles('', '', '').pipe(map(function (roleListResponse) {
            if (roleListResponse.global_response.result_state === 'WARNING') {
                _this.messengerService.sendNotification(roleListResponse.global_response.result_code, 'AUA | listRoles');
            }
            return roleListResponse;
        }));
    };
    /**
     * @param permissionsToDelete
     */
    AuaApiService.prototype.deletePermission = function (permissionsToDelete) {
        return this.auaClientService.deletePermissions(permissionsToDelete, '', '', '');
    };
    /**
     * @param roles
     */
    AuaApiService.prototype.resolveRoles = function (roles) {
        var resolveRolesRequest = roles;
        return this.auaClientService.resolveRoles(resolveRolesRequest, '', '', '');
    };
    /**
     * @param roles
     */
    AuaApiService.prototype.deleteRoles = function (roles) {
        return this.auaClientService.deleteRoles(roles, '', '', '');
    };
    AuaApiService.prototype.getProviders = function () {
        return this.auaClientService.getProviders('', '', '');
    };
    /**
     * @param subject
     */
    AuaApiService.prototype.getMandantGroups = function (subject) {
        var userGetMandantGroupsRequest = {
            subject: subject
        };
        return this.auaClientService.getMandantGroups(userGetMandantGroupsRequest, '', '', '');
    };
    AuaApiService.prototype.getPropertyTemplates = function () {
        var _this = this;
        return this.auaClientService.getPropertyTemplates('', '', '').pipe(map(function (propertyGetTemplatesResponse) {
            if (propertyGetTemplatesResponse.global_response.result_state === 'WARNING') {
                _this.messengerService.sendNotification(propertyGetTemplatesResponse.global_response.result_code, 'AUA | getPropertyTemplates');
            }
            return propertyGetTemplatesResponse;
        }));
    };
    /**
     * @param subject
     * @param mandantGroups
     */
    AuaApiService.prototype.setMandantGroups = function (subject, mandantGroups) {
        var setMandantGroupsRequest = {
            subject: subject,
            mandant_groups: mandantGroups
        };
        return this.auaClientService.setMandantGroups(setMandantGroupsRequest, '', '', '');
    };
    /**
     * @param term
     * @param type
     */
    AuaApiService.prototype.quickSearchUser = function (term, type) {
        if (type === void 0) { type = SearchTypeEnum.EmailOrSubject; }
        var userQuickSearch = {
            search_text: term,
            search_type: type,
            search_in_identities: true
        };
        return this.auaClientService.quickSearchUser(userQuickSearch, '', '', '');
    };
    /**
     * @param userExtendedSearch
     */
    AuaApiService.prototype.extendedSearchUser = function (userExtendedSearch) {
        return this.auaClientService.extendedSearchUser(userExtendedSearch, '', '', '');
    };
    /**
     * @param subject
     * @param properties
     */
    AuaApiService.prototype.setProperties = function (subject, properties) {
        var _this = this;
        var propertyRequest = { subject: subject, properties: properties };
        return this.auaClientService.setProperties(propertyRequest, '', '', '').pipe(map(function (userSetPropertiesResponse) {
            if (userSetPropertiesResponse.global_response.result_state === 'WARNING') {
                var detailResponses = userSetPropertiesResponse.user_set_property_detail_response;
                detailResponses.forEach(function (detailResponse) {
                    var warning = detailResponse.detail_result_response.result_code;
                    _this.messengerService.sendNotification(warning, 'AUA | setProperties');
                });
            }
            return userSetPropertiesResponse;
        }));
    };
    /**
     * @param subject
     * @param roles
     */
    AuaApiService.prototype.setRoles = function (subject, roles) {
        var _this = this;
        var roleSetRequset = { subject: subject, roles: roles };
        return this.auaClientService.setRoles(roleSetRequset, '', '', '').pipe(map(function (userSetGlobalResponse) {
            if (userSetGlobalResponse.global_response.result_state === 'WARNING') {
                _this.messengerService.sendNotification(userSetGlobalResponse.global_response.result_code, 'AUA | setRoles');
            }
            return userSetGlobalResponse;
        }));
    };
    /**
     * @param external_id
     * @param provider
     * @param email
     */
    AuaApiService.prototype.setEmail = function (external_id, provider, email) {
        var setEmailRequest = {
            external_id: external_id,
            provider: provider,
            email: email
        };
        return this.auaClientService.setEmail(setEmailRequest, '', '', '');
    };
    /**
     * @param subject
     */
    AuaApiService.prototype.deleteUser = function (subject) {
        var userDeleteRequests = [{ subject: subject }];
        return this.auaClientService.deleteUsers(userDeleteRequests, '', '', '');
    };
    /**
     * @param external_id
     * @param provider
     */
    AuaApiService.prototype.deleteIdentityForUser = function (external_id, provider) {
        var deleteUserIdentityRequest = {
            external_id: external_id,
            provider: provider
        };
        return this.auaClientService.deleteIdentityForUser(deleteUserIdentityRequest, '', '', '');
    };
    AuaApiService.ngInjectableDef = i0.defineInjectable({ factory: function AuaApiService_Factory() { return new AuaApiService(i0.inject(i1.AUAClientService), i0.inject(i2.MessengerService)); }, token: AuaApiService, providedIn: "root" });
    return AuaApiService;
}());
export { AuaApiService };
