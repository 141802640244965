<div class="hd--advanced-search s-margin-top-small">
    <div fxLayout="row" fxLayoutGap="5%" fxLayout.xs="column" fxLayoutGap.xs="5%">

        <div fxFlex="30" fxFlex.xs="100">
            <h3 class="mat-h3 hd--shadowed s-padding-small">Suche</h3>
            <mat-card class="mat-elevation-z0">
                <app-extended-search-form></app-extended-search-form>
            </mat-card>
        </div>

        <div fxFlex="70" fxFlex.xs="100">
            <h1 class="mat-h3 hd--shadowed s-padding-small">Ergebnisse <span class="mat-typography hint">(Max. 50 Resultate)</span>
            </h1>
            <mat-card class="mat-elevation-z0">
                <app-loading-spinner [diameterSize]="40" *ngIf="isLoading"></app-loading-spinner>
                <app-extended-search-table [hidden]="isLoading" [tableSource]="foundUsers"></app-extended-search-table>
            </mat-card>
        </div>
    </div>
</div>
