import { map } from 'rxjs/operators';
import { SetEmailDtoDetailResponse, SetEmailResponse, UserDeleteRequest, UserDeleteResponse, UserSetEmailRequest } from '../modules/api/idp_angular-client';
import { HdIdpApiService } from '../modules/api/idp_angular-client/custom/hd-idp-api.service';
import { HdIdpOperatorApiService } from '../modules/api/idp_angular-client/custom/hd-idp-operator-api.service';
import { MessengerService } from '../modules/shared/service/messenger.service';
import * as i0 from "@angular/core";
import * as i1 from "../modules/api/idp_angular-client/custom/hd-idp-api.service";
import * as i2 from "../modules/api/idp_angular-client/custom/hd-idp-operator-api.service";
import * as i3 from "../modules/shared/service/messenger.service";
import * as i4 from "./settings.http.service";
var IdpApiService = /** @class */ (function () {
    function IdpApiService(idPClientService, idPOperatorService, messengerService, configuration) {
        this.idPClientService = idPClientService;
        this.idPOperatorService = idPOperatorService;
        this.messengerService = messengerService;
        this.configuration = configuration;
    }
    /**
     * @param provider
     * @private
     */
    IdpApiService.prototype.lookUpProviderBasePath = function (provider) {
        var providerMapping = this.configuration.allowEditAccountsForProvider;
        var providerMap = providerMapping.find(function (item) {
            return item['provider'] === provider;
        });
        return providerMap['basePath'];
    };
    /**
     * @param provider
     */
    IdpApiService.prototype.setProvider = function (provider) {
        var providerBasePath = this.lookUpProviderBasePath(provider);
        if (typeof providerBasePath === 'undefined') {
            console.error("[IDPApiService] Could not set provider api basepath. provider does not exist in settings.json: " + provider);
            return false;
        }
        this.idPClientService.setBasePath(providerBasePath);
        this.idPOperatorService.setBasePath(providerBasePath);
        return true;
    };
    /**
     * Deletes user in IDP.
     * @param subject to delete
     */
    IdpApiService.prototype.deleteUser = function (subject) {
        var userDeleteRequest = [{
                subject: subject
            }];
        return this.idPOperatorService.deleteUser('', '', '', userDeleteRequest);
    };
    /**
     * Set new user email
     * @param subject to change
     * @param email new email
     */
    IdpApiService.prototype.setEmail = function (subject, email) {
        var _this = this;
        var setEmailRequest = [{
                subject: subject,
                email: email
            }];
        return this.idPClientService.setEmail('', '', '', setEmailRequest).pipe(map(function (setEmailResponse) {
            if (setEmailResponse.global_response.result_state === 'WARN') {
                var detailResponses = setEmailResponse.user_change_email_detail_responses;
                for (var _i = 0, detailResponses_1 = detailResponses; _i < detailResponses_1.length; _i++) {
                    var detailResponse = detailResponses_1[_i];
                    _this.messengerService.sendNotification(detailResponse.detail_result_response.result_code, 'IDP | setEmail');
                }
            }
            return setEmailResponse;
        }));
    };
    IdpApiService.ngInjectableDef = i0.defineInjectable({ factory: function IdpApiService_Factory() { return new IdpApiService(i0.inject(i1.HdIdpApiService), i0.inject(i2.HdIdpOperatorApiService), i0.inject(i3.MessengerService), i0.inject(i4.APP_CONFIGURATION)); }, token: IdpApiService, providedIn: "root" });
    return IdpApiService;
}());
export { IdpApiService };
