import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './component/header/header.component';
import {UserInformationComponent} from './component/user-information/user-information.component';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {HeaderTabComponent} from './component/header-tab/header-tab.component';
import {RouterModule} from '@angular/router';
import {MobileFlyoutComponent} from './component/mobile-flyout/mobile-flyout.component';
import {QuickSearchModule} from '../quick-search/quick-search.module';
import {MobileHeaderComponent} from './component/mobile-header/mobile-header.component';

@NgModule({
    declarations: [
        HeaderComponent,
        UserInformationComponent,
        HeaderTabComponent,
        MobileFlyoutComponent,
        MobileHeaderComponent,
    ],
    imports: [
        CommonModule,
        QuickSearchModule,
        ReactiveFormsModule,
        SharedModule,
        RouterModule
    ],
    exports: [
        HeaderComponent,
        MobileHeaderComponent,
        UserInformationComponent,
    ]
})
export class HeaderModule {
}
