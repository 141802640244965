import { AuthService } from './auth.service';
import { AuaApiService } from '../../../service/aua-api.service';
import { flatMap, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "../../../service/aua-api.service";
var AdminGuardService = /** @class */ (function () {
    function AdminGuardService(authService, auaService) {
        this.authService = authService;
        this.auaService = auaService;
    }
    /**
     * Gets AuA user and asks for its permissions
     */
    AdminGuardService.prototype.canActivate = function () {
        var _this = this;
        return this.authService.getAuAUser$().pipe(flatMap(function (auaUser) {
            return _this.auaService.getUserRoles(auaUser.subject).pipe(map(function (rolesList) {
                var rolesListDetailResponse = rolesList.user_get_role_detail_response;
                var roles = rolesListDetailResponse.roles;
                var adminRole = roles.includes('IA_ADMIN');
                return adminRole;
            }));
        }));
    };
    AdminGuardService.ngInjectableDef = i0.defineInjectable({ factory: function AdminGuardService_Factory() { return new AdminGuardService(i0.inject(i1.AuthService), i0.inject(i2.AuaApiService)); }, token: AdminGuardService, providedIn: "root" });
    return AdminGuardService;
}());
export { AdminGuardService };
