<section class="mat-typography">

    <app-loading-spinner [diameterSize]="20" *ngIf="isLoading"></app-loading-spinner>

    <ng-container>
        <ng-container *ngIf="auaUser$ | async as user">
            <div fxLayout="row" fxLayoutAlign="center center">
                <div>
                    {{user.email}}
                </div>
                <div>
                    <button mat-button (click)="handleLogOutClick()">logout</button>
                </div>
                <div *ifRole="'IA_ADMIN'" class="admin-theme">
                    <button mat-button color="primary" routerLink="/admin/details">Admin-Bereich</button>
                </div>
            </div>
        </ng-container>
    </ng-container>

</section>
