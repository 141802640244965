/**
 * ioneaccess License Manager (LIMA) API
 * API for ioneacces License Manager (LIMA)
 *
 * OpenAPI spec version: 1.1.12
 * Contact: florian.mischler@ionesoft.ch
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CacheFlushResponse1 } from '../model/cacheFlushResponse1';
import { DeviceGetForUserRequest1 } from '../model/deviceGetForUserRequest1';
import { DeviceGetForUserResponse1 } from '../model/deviceGetForUserResponse1';
import { DeviceUpdateForUserRequest1 } from '../model/deviceUpdateForUserRequest1';
import { DeviceUpdateForUserResponse1 } from '../model/deviceUpdateForUserResponse1';
import { LicenseGetForUserAndDeviceRequest1 } from '../model/licenseGetForUserAndDeviceRequest1';
import { LicenseGetForUserAndDeviceResponse1 } from '../model/licenseGetForUserAndDeviceResponse1';
import { LicenseGetForUserRequest1 } from '../model/licenseGetForUserRequest1';
import { LicenseGetForUserResponse1 } from '../model/licenseGetForUserResponse1';
import { LicenseObtainLicenseUsageRequest1 } from '../model/licenseObtainLicenseUsageRequest1';
import { LicenseObtainLicenseUsageResponse1 } from '../model/licenseObtainLicenseUsageResponse1';
import { LicensePruneAllLicenseUsagesForUserRequest1 } from '../model/licensePruneAllLicenseUsagesForUserRequest1';
import { LicensePruneAllLicenseUsagesForUserResponse1 } from '../model/licensePruneAllLicenseUsagesForUserResponse1';
import { LicensePruneLicenseUsageRequest1 } from '../model/licensePruneLicenseUsageRequest1';
import { LicensePruneLicenseUsageResponse1 } from '../model/licensePruneLicenseUsageResponse1';
import { LicenseUsageGiveOffHeartbeatRequest1 } from '../model/licenseUsageGiveOffHeartbeatRequest1';
import { LicenseUsageGiveOffHeartbeatResponse1 } from '../model/licenseUsageGiveOffHeartbeatResponse1';
import { LicenseVerifyLicenseUsageRequest1 } from '../model/licenseVerifyLicenseUsageRequest1';
import { LicenseVerifyLicenseUsageResponse1 } from '../model/licenseVerifyLicenseUsageResponse1';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class LIMAClient1Service {

    protected basePath = 'https://igate.beook.work/lima/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get devices for user
     * Get all devices for one user
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param DeviceGetForUserRequest1 User subject
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceGetForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DeviceGetForUserRequest1?: DeviceGetForUserRequest1, observe?: 'body', reportProgress?: boolean): Observable<DeviceGetForUserResponse1>;
    public deviceGetForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DeviceGetForUserRequest1?: DeviceGetForUserRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceGetForUserResponse1>>;
    public deviceGetForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DeviceGetForUserRequest1?: DeviceGetForUserRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeviceGetForUserResponse1>>;
    public deviceGetForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DeviceGetForUserRequest1?: DeviceGetForUserRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling deviceGetForUser.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling deviceGetForUser.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling deviceGetForUser.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DeviceGetForUserResponse1>(`${this.basePath}/device/getForUser`,
            DeviceGetForUserRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unregister device for the given licenses
     * Unregister device for the given licenses
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param DeviceUnregisterForLicensesRequest1 Device ID to unregister
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceUnregisterForLicenses(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DeviceUnregisterForLicensesRequest1: string, observe?: 'body', reportProgress?: boolean): Observable<CacheFlushResponse1>;
    public deviceUnregisterForLicenses(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DeviceUnregisterForLicensesRequest1: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CacheFlushResponse1>>;
    public deviceUnregisterForLicenses(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DeviceUnregisterForLicensesRequest1: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CacheFlushResponse1>>;
    public deviceUnregisterForLicenses(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DeviceUnregisterForLicensesRequest1: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling deviceUnregisterForLicenses.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling deviceUnregisterForLicenses.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling deviceUnregisterForLicenses.');
        }

        if (DeviceUnregisterForLicensesRequest1 === null || DeviceUnregisterForLicensesRequest1 === undefined) {
            throw new Error('Required parameter DeviceUnregisterForLicensesRequest1 was null or undefined when calling deviceUnregisterForLicenses.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (DeviceUnregisterForLicensesRequest1 !== undefined && DeviceUnregisterForLicensesRequest1 !== null) {
            queryParameters = queryParameters.set('DeviceUnregisterForLicensesRequest1', <any>DeviceUnregisterForLicensesRequest1);
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<CacheFlushResponse1>(`${this.basePath}/device/unregisterForLicenses`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update device for user
     * Update device info for current user
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param DeviceUpdateForUserRequest1 Request for updating device info for a user.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceUpdateForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DeviceUpdateForUserRequest1?: DeviceUpdateForUserRequest1, observe?: 'body', reportProgress?: boolean): Observable<DeviceUpdateForUserResponse1>;
    public deviceUpdateForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DeviceUpdateForUserRequest1?: DeviceUpdateForUserRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceUpdateForUserResponse1>>;
    public deviceUpdateForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DeviceUpdateForUserRequest1?: DeviceUpdateForUserRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeviceUpdateForUserResponse1>>;
    public deviceUpdateForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, DeviceUpdateForUserRequest1?: DeviceUpdateForUserRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling deviceUpdateForUser.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling deviceUpdateForUser.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling deviceUpdateForUser.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DeviceUpdateForUserResponse1>(`${this.basePath}/device/updateForUser`,
            DeviceUpdateForUserRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get every license with its license usages of user.
     * Get every information of user, meaning: licenses &amp; license usages.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param LicenseGetForUserRequest1 Parameters for license filter. If no params are set, all licenses for the given user are returned
     * @param consolidate Bool determining if should consolidate or not.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public licenseGetForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseGetForUserRequest1: LicenseGetForUserRequest1, consolidate?: boolean, observe?: 'body', reportProgress?: boolean): Observable<LicenseGetForUserResponse1>;
    public licenseGetForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseGetForUserRequest1: LicenseGetForUserRequest1, consolidate?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LicenseGetForUserResponse1>>;
    public licenseGetForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseGetForUserRequest1: LicenseGetForUserRequest1, consolidate?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LicenseGetForUserResponse1>>;
    public licenseGetForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseGetForUserRequest1: LicenseGetForUserRequest1, consolidate?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling licenseGetForUser.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling licenseGetForUser.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling licenseGetForUser.');
        }

        if (LicenseGetForUserRequest1 === null || LicenseGetForUserRequest1 === undefined) {
            throw new Error('Required parameter LicenseGetForUserRequest1 was null or undefined when calling licenseGetForUser.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (consolidate !== undefined && consolidate !== null) {
            queryParameters = queryParameters.set('consolidate', <any>consolidate);
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LicenseGetForUserResponse1>(`${this.basePath}/license/getForUser`,
            LicenseGetForUserRequest1,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all licenses of user in respect of device.
     * Get all licenses of user in respect of device
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param LicenseGetForUserAndDeviceRequest1 Parameters for license filter. If no params are set, all licenses for the given user are returned
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public licenseGetForUserAndDevice(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseGetForUserAndDeviceRequest1: LicenseGetForUserAndDeviceRequest1, observe?: 'body', reportProgress?: boolean): Observable<LicenseGetForUserAndDeviceResponse1>;
    public licenseGetForUserAndDevice(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseGetForUserAndDeviceRequest1: LicenseGetForUserAndDeviceRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LicenseGetForUserAndDeviceResponse1>>;
    public licenseGetForUserAndDevice(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseGetForUserAndDeviceRequest1: LicenseGetForUserAndDeviceRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LicenseGetForUserAndDeviceResponse1>>;
    public licenseGetForUserAndDevice(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseGetForUserAndDeviceRequest1: LicenseGetForUserAndDeviceRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling licenseGetForUserAndDevice.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling licenseGetForUserAndDevice.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling licenseGetForUserAndDevice.');
        }

        if (LicenseGetForUserAndDeviceRequest1 === null || LicenseGetForUserAndDeviceRequest1 === undefined) {
            throw new Error('Required parameter LicenseGetForUserAndDeviceRequest1 was null or undefined when calling licenseGetForUserAndDevice.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LicenseGetForUserAndDeviceResponse1>(`${this.basePath}/license/getForUserAndDevice`,
            LicenseGetForUserAndDeviceRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Obtain a valid license usage
     * Obtain a valid license usage. This happens on install of a product which is already licensed for the given user.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param LicenseObtainLicenseUsageRequest1 Parameters for obtaining license usage
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public licenseObtainLicenseUsage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseObtainLicenseUsageRequest1: LicenseObtainLicenseUsageRequest1, observe?: 'body', reportProgress?: boolean): Observable<LicenseObtainLicenseUsageResponse1>;
    public licenseObtainLicenseUsage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseObtainLicenseUsageRequest1: LicenseObtainLicenseUsageRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LicenseObtainLicenseUsageResponse1>>;
    public licenseObtainLicenseUsage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseObtainLicenseUsageRequest1: LicenseObtainLicenseUsageRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LicenseObtainLicenseUsageResponse1>>;
    public licenseObtainLicenseUsage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseObtainLicenseUsageRequest1: LicenseObtainLicenseUsageRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling licenseObtainLicenseUsage.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling licenseObtainLicenseUsage.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling licenseObtainLicenseUsage.');
        }

        if (LicenseObtainLicenseUsageRequest1 === null || LicenseObtainLicenseUsageRequest1 === undefined) {
            throw new Error('Required parameter LicenseObtainLicenseUsageRequest1 was null or undefined when calling licenseObtainLicenseUsage.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LicenseObtainLicenseUsageResponse1>(`${this.basePath}/license/obtainLicenseUsage`,
            LicenseObtainLicenseUsageRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete all license usages of given user
     * Liberate all license usage for reuse which were taken by given user.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param LicensePruneAllLicenseUsagesForUserRequest1 Containing user subject for which to remove all license usages
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public licensePruneAllLicenseUsagesForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicensePruneAllLicenseUsagesForUserRequest1: LicensePruneAllLicenseUsagesForUserRequest1, observe?: 'body', reportProgress?: boolean): Observable<LicensePruneAllLicenseUsagesForUserResponse1>;
    public licensePruneAllLicenseUsagesForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicensePruneAllLicenseUsagesForUserRequest1: LicensePruneAllLicenseUsagesForUserRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LicensePruneAllLicenseUsagesForUserResponse1>>;
    public licensePruneAllLicenseUsagesForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicensePruneAllLicenseUsagesForUserRequest1: LicensePruneAllLicenseUsagesForUserRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LicensePruneAllLicenseUsagesForUserResponse1>>;
    public licensePruneAllLicenseUsagesForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicensePruneAllLicenseUsagesForUserRequest1: LicensePruneAllLicenseUsagesForUserRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling licensePruneAllLicenseUsagesForUser.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling licensePruneAllLicenseUsagesForUser.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling licensePruneAllLicenseUsagesForUser.');
        }

        if (LicensePruneAllLicenseUsagesForUserRequest1 === null || LicensePruneAllLicenseUsagesForUserRequest1 === undefined) {
            throw new Error('Required parameter LicensePruneAllLicenseUsagesForUserRequest1 was null or undefined when calling licensePruneAllLicenseUsagesForUser.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LicensePruneAllLicenseUsagesForUserResponse1>(`${this.basePath}/license/pruneAllLicenseUsagesForUser`,
            LicensePruneAllLicenseUsagesForUserRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the license usage for given user and given device
     * Liberate the license usage. This happens on shut down app for floating licenses, and triggered from helpdesk.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param LicensePruneLicenseUsageRequest1 Parameters for pruning license usage
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public licensePruneLicenseUsage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicensePruneLicenseUsageRequest1: LicensePruneLicenseUsageRequest1, observe?: 'body', reportProgress?: boolean): Observable<LicensePruneLicenseUsageResponse1>;
    public licensePruneLicenseUsage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicensePruneLicenseUsageRequest1: LicensePruneLicenseUsageRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LicensePruneLicenseUsageResponse1>>;
    public licensePruneLicenseUsage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicensePruneLicenseUsageRequest1: LicensePruneLicenseUsageRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LicensePruneLicenseUsageResponse1>>;
    public licensePruneLicenseUsage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicensePruneLicenseUsageRequest1: LicensePruneLicenseUsageRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling licensePruneLicenseUsage.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling licensePruneLicenseUsage.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling licensePruneLicenseUsage.');
        }

        if (LicensePruneLicenseUsageRequest1 === null || LicensePruneLicenseUsageRequest1 === undefined) {
            throw new Error('Required parameter LicensePruneLicenseUsageRequest1 was null or undefined when calling licensePruneLicenseUsage.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LicensePruneLicenseUsageResponse1>(`${this.basePath}/license/pruneLicenseUsage`,
            LicensePruneLicenseUsageRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Give off a heartbeat.
     * Give off a heartbeat which shows that the license and its usage are still alive and therefore used by its user.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param LicenseUsageGiveOffHeartbeatRequest1 Parameters for giving off a heartbeat
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public licenseUsageGiveOffHeartbeat(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseUsageGiveOffHeartbeatRequest1: LicenseUsageGiveOffHeartbeatRequest1, observe?: 'body', reportProgress?: boolean): Observable<LicenseUsageGiveOffHeartbeatResponse1>;
    public licenseUsageGiveOffHeartbeat(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseUsageGiveOffHeartbeatRequest1: LicenseUsageGiveOffHeartbeatRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LicenseUsageGiveOffHeartbeatResponse1>>;
    public licenseUsageGiveOffHeartbeat(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseUsageGiveOffHeartbeatRequest1: LicenseUsageGiveOffHeartbeatRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LicenseUsageGiveOffHeartbeatResponse1>>;
    public licenseUsageGiveOffHeartbeat(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseUsageGiveOffHeartbeatRequest1: LicenseUsageGiveOffHeartbeatRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling licenseUsageGiveOffHeartbeat.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling licenseUsageGiveOffHeartbeat.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling licenseUsageGiveOffHeartbeat.');
        }

        if (LicenseUsageGiveOffHeartbeatRequest1 === null || LicenseUsageGiveOffHeartbeatRequest1 === undefined) {
            throw new Error('Required parameter LicenseUsageGiveOffHeartbeatRequest1 was null or undefined when calling licenseUsageGiveOffHeartbeat.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LicenseUsageGiveOffHeartbeatResponse1>(`${this.basePath}/licenseUsage/giveOffHeartbeat`,
            LicenseUsageGiveOffHeartbeatRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verifies a license usage
     * Verifies a license usage. The call originates from ATMA, if a user tries to install an asset. At this point ATMA requires verification from LIMA, if it is a valid license usage.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param LicenseVerifyLicenseUsageRequest1 Parameters for verifying a license usage
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public licenseVerifyLicenseUsage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseVerifyLicenseUsageRequest1: LicenseVerifyLicenseUsageRequest1, observe?: 'body', reportProgress?: boolean): Observable<LicenseVerifyLicenseUsageResponse1>;
    public licenseVerifyLicenseUsage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseVerifyLicenseUsageRequest1: LicenseVerifyLicenseUsageRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LicenseVerifyLicenseUsageResponse1>>;
    public licenseVerifyLicenseUsage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseVerifyLicenseUsageRequest1: LicenseVerifyLicenseUsageRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LicenseVerifyLicenseUsageResponse1>>;
    public licenseVerifyLicenseUsage(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LicenseVerifyLicenseUsageRequest1: LicenseVerifyLicenseUsageRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling licenseVerifyLicenseUsage.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling licenseVerifyLicenseUsage.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling licenseVerifyLicenseUsage.');
        }

        if (LicenseVerifyLicenseUsageRequest1 === null || LicenseVerifyLicenseUsageRequest1 === undefined) {
            throw new Error('Required parameter LicenseVerifyLicenseUsageRequest1 was null or undefined when calling licenseVerifyLicenseUsage.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LicenseVerifyLicenseUsageResponse1>(`${this.basePath}/license/verifyLicenseUsage`,
            LicenseVerifyLicenseUsageRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
