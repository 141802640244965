import { InjectionToken } from '@angular/core';
import * as i0 from "@angular/core";
export var APP_CONFIGURATION = new InjectionToken('APP_CONFIGURATION');
var SettingsHttpService = /** @class */ (function () {
    // private promiseResolved = new BehaviorSubject<any>(this.settingsService.settings);
    function SettingsHttpService(configuration) {
        this.configuration = configuration;
    }
    SettingsHttpService.SETTINGS_URL = 'assets/settings/settings.json';
    SettingsHttpService.ngInjectableDef = i0.defineInjectable({ factory: function SettingsHttpService_Factory() { return new SettingsHttpService(i0.inject(APP_CONFIGURATION)); }, token: SettingsHttpService, providedIn: "root" });
    return SettingsHttpService;
}());
export { SettingsHttpService };
