import * as tslib_1 from "tslib";
import { ClientService } from '../api/client.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../variables";
import * as i3 from "../configuration";
/*
Extends ClientService to dynamically set the basePath of the api
 */
var HdIdpApiService = /** @class */ (function (_super) {
    tslib_1.__extends(HdIdpApiService, _super);
    function HdIdpApiService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param path
     */
    HdIdpApiService.prototype.setBasePath = function (path) {
        this.basePath = path;
    };
    /**
     *
     */
    HdIdpApiService.prototype.getBasePath = function () {
        return this.basePath;
    };
    HdIdpApiService.ngInjectableDef = i0.defineInjectable({ factory: function HdIdpApiService_Factory() { return new HdIdpApiService(i0.inject(i1.HttpClient), i0.inject(i2.BASE_PATH, 8), i0.inject(i3.Configuration, 8)); }, token: HdIdpApiService, providedIn: "root" });
    return HdIdpApiService;
}(ClientService));
export { HdIdpApiService };
