import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {Settings} from './app/models/settings';
import {APP_CONFIGURATION, SettingsHttpService} from './app/service/settings.http.service';
import {environment} from './environments/environment';
import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

fetch(SettingsHttpService.SETTINGS_URL).then(async data => {
  const config = (await data.json()) as Settings;
  platformBrowserDynamic([
    {
      provide: APP_CONFIGURATION,
      useValue: config
    }
  ])
    .bootstrapModule(AppModule)
    .catch(err => console.error('[main.ts] error bootstrapping app', err));
}).catch(err => console.error('[main.ts] error bootstrapping app', err));
