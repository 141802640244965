import {NgModule, ModuleWithProviders, SkipSelf, Optional} from '@angular/core';
import {Configuration} from '../configuration';
import {HttpClient} from '@angular/common/http';
import {DefaultService} from '../api/default.service';
import {DexService} from '../api/dex.service';
import {UserService} from '../api/user.service';
import {HdIdpApiService} from './hd-idp-api.service';
import {HdIdpOperatorApiService} from './hd-idp-operator-api.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    HdIdpApiService,
    DefaultService,
    DexService,
    HdIdpOperatorApiService,
    UserService]
})
export class HDIdpApiModule {

  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<HDIdpApiModule> {
    return {
      ngModule: HDIdpApiModule,
      providers: [{provide: Configuration, useFactory: configurationFactory}]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: HDIdpApiModule,
              @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
        'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
