/**
 * ioneaccess Business Logger (BILO) API
 * API for ioneaccess Business Logger (BILO)
 *
 * OpenAPI spec version: 1.0.1
 * Contact: kenzo.grieco@ionesoft.ch
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { LogCreateRequest1 } from '../model/logCreateRequest1';
import { LogCreateResponse1 } from '../model/logCreateResponse1';
import { LogReadRequest1 } from '../model/logReadRequest1';
import { LogReadResponse1 } from '../model/logReadResponse1';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class BILOClient1Service {

    protected basePath = 'https://igate.beook.work/bilo/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Logs recording.
     * Provides ability to record new log entries.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user.
     * @param X_Ia_Roles Role of caller. One or many roles.
     * @param LogCreateRequest1 Parameters for log recording.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createLog(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LogCreateRequest1: LogCreateRequest1, observe?: 'body', reportProgress?: boolean): Observable<LogCreateResponse1>;
    public createLog(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LogCreateRequest1: LogCreateRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LogCreateResponse1>>;
    public createLog(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LogCreateRequest1: LogCreateRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LogCreateResponse1>>;
    public createLog(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LogCreateRequest1: LogCreateRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling createLog.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling createLog.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling createLog.');
        }

        if (LogCreateRequest1 === null || LogCreateRequest1 === undefined) {
            throw new Error('Required parameter LogCreateRequest1 was null or undefined when calling createLog.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LogCreateResponse1>(`${this.basePath}/log/create`,
            LogCreateRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Log reading.
     * Provides ability to read a set of existing log entries.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates and passes ID to the first call.
     * @param X_Ia_Subject UUID of user.
     * @param X_Ia_Roles Role of caller. One or many roles.
     * @param LogReadRequest1 Parameters for log reading.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readLog(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LogReadRequest1: LogReadRequest1, observe?: 'body', reportProgress?: boolean): Observable<LogReadResponse1>;
    public readLog(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LogReadRequest1: LogReadRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LogReadResponse1>>;
    public readLog(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LogReadRequest1: LogReadRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LogReadResponse1>>;
    public readLog(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, LogReadRequest1: LogReadRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling readLog.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling readLog.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling readLog.');
        }

        if (LogReadRequest1 === null || LogReadRequest1 === undefined) {
            throw new Error('Required parameter LogReadRequest1 was null or undefined when calling readLog.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LogReadResponse1>(`${this.basePath}/log/read`,
            LogReadRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
