/**
 * ioneaccess Product and Order-System (PROS) API
 * API for ioneaccess Product and Order-System (PROS) API aka ia_sales_system REST services
 *
 * OpenAPI spec version: 1.2.10
 * Contact: anhi.chang@ionesoft.ch
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ConversionSynchronizeAssetProductRequest1 } from '../model/conversionSynchronizeAssetProductRequest1';
import { ConversionSynchronizeAssetProductResponse1 } from '../model/conversionSynchronizeAssetProductResponse1';
import { ProductLookUpEproductByProductReferenceRequest1 } from '../model/productLookUpEproductByProductReferenceRequest1';
import { ProductLookUpEproductByProductReferenceResponse1 } from '../model/productLookUpEproductByProductReferenceResponse1';
import { ProductLookUpMandantRequest1 } from '../model/productLookUpMandantRequest1';
import { ProductLookUpMandantResponse1 } from '../model/productLookUpMandantResponse1';
import { ProductPublishProductRequest1 } from '../model/productPublishProductRequest1';
import { ProductPublishProductResponse1 } from '../model/productPublishProductResponse1';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PROSConversionSystem1Service {

    protected basePath = 'https://pros.ionedev.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get all eproducts with given product references
     * Get all eproducts with given product references
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param ProductLookUpEproductByProductReferenceRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lookUpEproductByProductReference(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductLookUpEproductByProductReferenceRequest1?: ProductLookUpEproductByProductReferenceRequest1, observe?: 'body', reportProgress?: boolean): Observable<ProductLookUpEproductByProductReferenceResponse1>;
    public lookUpEproductByProductReference(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductLookUpEproductByProductReferenceRequest1?: ProductLookUpEproductByProductReferenceRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductLookUpEproductByProductReferenceResponse1>>;
    public lookUpEproductByProductReference(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductLookUpEproductByProductReferenceRequest1?: ProductLookUpEproductByProductReferenceRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductLookUpEproductByProductReferenceResponse1>>;
    public lookUpEproductByProductReference(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductLookUpEproductByProductReferenceRequest1?: ProductLookUpEproductByProductReferenceRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling lookUpEproductByProductReference.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling lookUpEproductByProductReference.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling lookUpEproductByProductReference.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProductLookUpEproductByProductReferenceResponse1>(`${this.basePath}/rpc/conversionSystem/v1/conversion/lookUpEproductByProductReference`,
            ProductLookUpEproductByProductReferenceRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all productreferences of the mandant
     * Get all products of the mandant by mandant_identifier
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param ProductLookUpMandantRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lookUpMandant(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductLookUpMandantRequest1?: ProductLookUpMandantRequest1, observe?: 'body', reportProgress?: boolean): Observable<ProductLookUpMandantResponse1>;
    public lookUpMandant(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductLookUpMandantRequest1?: ProductLookUpMandantRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductLookUpMandantResponse1>>;
    public lookUpMandant(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductLookUpMandantRequest1?: ProductLookUpMandantRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductLookUpMandantResponse1>>;
    public lookUpMandant(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductLookUpMandantRequest1?: ProductLookUpMandantRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling lookUpMandant.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling lookUpMandant.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling lookUpMandant.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProductLookUpMandantResponse1>(`${this.basePath}/rpc/conversionSystem/v1/conversion/lookUpMandant`,
            ProductLookUpMandantRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Publish course or issue product
     * Publish product with given publishState, product_reference, course_reference ebookID, issueID, issueReference
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param ProductPublishProductRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publishProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductPublishProductRequest1?: ProductPublishProductRequest1, observe?: 'body', reportProgress?: boolean): Observable<ProductPublishProductResponse1>;
    public publishProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductPublishProductRequest1?: ProductPublishProductRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductPublishProductResponse1>>;
    public publishProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductPublishProductRequest1?: ProductPublishProductRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductPublishProductResponse1>>;
    public publishProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ProductPublishProductRequest1?: ProductPublishProductRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling publishProduct.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling publishProduct.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling publishProduct.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProductPublishProductResponse1>(`${this.basePath}/rpc/conversionSystem/v1/conversion/publishProduct`,
            ProductPublishProductRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * synchronize all definitions
     * Add new AssetProducts if not available and synchronize all assetDefinitons
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param ConversionSynchronizeAssetProductRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public synchronizeAssetProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ConversionSynchronizeAssetProductRequest1?: ConversionSynchronizeAssetProductRequest1, observe?: 'body', reportProgress?: boolean): Observable<ConversionSynchronizeAssetProductResponse1>;
    public synchronizeAssetProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ConversionSynchronizeAssetProductRequest1?: ConversionSynchronizeAssetProductRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ConversionSynchronizeAssetProductResponse1>>;
    public synchronizeAssetProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ConversionSynchronizeAssetProductRequest1?: ConversionSynchronizeAssetProductRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ConversionSynchronizeAssetProductResponse1>>;
    public synchronizeAssetProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, ConversionSynchronizeAssetProductRequest1?: ConversionSynchronizeAssetProductRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling synchronizeAssetProduct.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling synchronizeAssetProduct.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling synchronizeAssetProduct.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (ApiKey) required
        if (this.configuration.apiKeys["X-Ia-Api-Key"]) {
            headers = headers.set('X-Ia-Api-Key', this.configuration.apiKeys["X-Ia-Api-Key"]);
        }

        // authentication (ApiUser) required
        if (this.configuration.apiKeys["X-Ia-Api-User"]) {
            headers = headers.set('X-Ia-Api-User', this.configuration.apiKeys["X-Ia-Api-User"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ConversionSynchronizeAssetProductResponse1>(`${this.basePath}/rpc/conversionSystem/v1/conversion/synchronizeAssetProduct`,
            ConversionSynchronizeAssetProductRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
