<form class="hd--quick-search" [formGroup]="quickSearchForm" (keyup.enter)="onSubmit($event)">

  <!-- AutoCompletion -->
  <mat-form-field class="hd--quick-search-form-field">

    <!-- email-table search results -->
    <ng-container *ngIf="searchType === SEARCH_TYPE.EMAIL; else searchResultSimple">

      <input type="text" minlength="3" placeholder="{{placeholderText}}" aria-label="Text" matInput
             [formControl]="quickSearchForm.controls['search_text']" [matAutocomplete]="auto">

      <mat-autocomplete #auto="matAutocomplete" (optionSelected)='onAutocompleteSelection($event.option.value)'
                        panelWidth='auto'>

        <!-- Loading indicator option -->
        <mat-option *ngIf="isLoading">
          <app-loading-spinner [diameterSize]="20"></app-loading-spinner>
        </mat-option>

        <ng-container *ngFor="let result of (quickSearchResult | formatQuickSearch: searchType)">
          <mat-option [value]="result.value" (click)="onSubmit($event)">
            <div class="hd--quick-search-result-container" fxLayout="row">
              <!-- main label -->
              <section fxFlex="0 0 240px" class="hd-quick-search-main-result">
                <span class="hd--quick-search-hint">{{result.hint}}</span>
                <p class="hd--quick-search-label">{{result.label}}</p>
              </section>

              <!--identities -->
              <ng-container *ngFor="let identity of result.identities">
                <section fxFlex="0 0 240px" class="hd-quick-search-identity">
                  <span class="hd--quick-search-hint">{{identity.provider}}</span>
                  <p class="hd--quick-search-label">{{identity.email}}</p>
                </section>
              </ng-container>
            </div>
          </mat-option>
        </ng-container>

      </mat-autocomplete>

    </ng-container>

    <!-- simple search results -->
    <ng-template #searchResultSimple>

      <input type="text" minlength="3" placeholder="{{placeholderText}}" aria-label="Text" matInput
             [formControl]="quickSearchForm.controls['search_text']" [matAutocomplete]="auto">

      <mat-autocomplete #auto="matAutocomplete" (optionSelected)='onAutocompleteSelection($event.option.value)'>

        <!-- Loading indicator option -->
        <mat-option *ngIf="isLoading">
          <app-loading-spinner [diameterSize]="20"></app-loading-spinner>
        </mat-option>

        <ng-container *ngFor="let result of (quickSearchResult | formatQuickSearch: searchType)">
          <mat-option [value]="result.value" (click)="onSubmit($event)">
            <div class="hd--quick-search-result-container">
              <section class="hd-quick-search-main-result">
                <span class="hd--quick-search-hint">{{result.hint}}</span>
                <p class="hd--quick-search-label">{{result.label}}</p>
              </section>
            </div>
          </mat-option>
        </ng-container>

      </mat-autocomplete>

    </ng-template>

  </mat-form-field>

</form>
