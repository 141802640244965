import { filter } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var RoutingService = /** @class */ (function () {
    function RoutingService(route) {
        this.route = route;
    }
    RoutingService.prototype.getRouteParameter = function (param) {
        return this.route.queryParams.pipe(filter(function (params) { return params[param]; }));
    };
    RoutingService.ngInjectableDef = i0.defineInjectable({ factory: function RoutingService_Factory() { return new RoutingService(i0.inject(i1.ActivatedRoute)); }, token: RoutingService, providedIn: "root" });
    return RoutingService;
}());
export { RoutingService };
