<header class="hd--mobile-header">
    <div class="hd-mobile-header-wrapper" fxLayout="row" fxLayoutAlign="space-between center">

        <!--header logo -->
        <div fxFlex.xs="70">
            <a class="hd--mobile-header-logo-link" [routerLink]="['service-status']">
                <img class="hd--mobile-header-logo" src="{{logoURL}}"/>
            </a>
        </div>

        <!-- Mobile expand button -->
        <div fxFlex.xs="20">
            <button class="hd--mobile-header-burger" fxHide.gt-xs mat-icon-button (click)="onMoblieMenuClick()">
                <mat-icon>more_vert</mat-icon>
            </button>
        </div>
    </div>
</header>

<app-mobile-flyout #flyout></app-mobile-flyout>
