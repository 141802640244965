/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/flex";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "@angular/flex-layout/extended";
import * as i6 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "./header-tab.component";
import * as i9 from "../../service/tab.service";
var styles_HeaderTabComponent = [i0.styles];
var RenderType_HeaderTabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderTabComponent, data: {} });
export { RenderType_HeaderTabComponent as RenderType_HeaderTabComponent };
function View_HeaderTabComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "hd--header-tap mat-typography"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "hd--header-tap-wrapper"], ["fxLayout", "row"], ["fxLayoutAlign", "center center"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onTabClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "active": 0 }), i1.ɵdid(4, 671744, null, 0, i3.DefaultLayoutDirective, [i1.ElementRef, i4.StyleUtils, [2, i3.LayoutStyleBuilder], i4.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(5, 671744, null, 0, i3.DefaultLayoutAlignDirective, [i1.ElementRef, i4.StyleUtils, [2, i3.LayoutAlignStyleBuilder], i4.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), i1.ɵdid(6, 933888, null, 0, i5.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i1.IterableDiffers, i1.KeyValueDiffers, i1.Renderer2, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(7, { "active": 0 }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [["class", "hd--header-tap-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancelClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(11, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "hd--header-tap-wrapper"; var currVal_1 = _ck(_v, 3, 0, _co.tabItem.isActive); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "row"; _ck(_v, 4, 0, currVal_2); var currVal_3 = "center center"; _ck(_v, 5, 0, currVal_3); var currVal_4 = _ck(_v, 7, 0, _co.tabItem.isActive); var currVal_5 = "hd--header-tap-wrapper"; _ck(_v, 6, 0, currVal_4, currVal_5); _ck(_v, 11, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.tabItem.text; _ck(_v, 9, 0, currVal_6); var currVal_7 = i1.ɵnov(_v, 11).inline; var currVal_8 = (((i1.ɵnov(_v, 11).color !== "primary") && (i1.ɵnov(_v, 11).color !== "accent")) && (i1.ɵnov(_v, 11).color !== "warn")); _ck(_v, 10, 0, currVal_7, currVal_8); }); }
export function View_HeaderTabComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderTabComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabItem; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HeaderTabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-tab", [], null, null, null, View_HeaderTabComponent_0, RenderType_HeaderTabComponent)), i1.ɵdid(1, 245760, null, 0, i8.HeaderTabComponent, [i9.TabService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderTabComponentNgFactory = i1.ɵccf("app-user-tab", i8.HeaderTabComponent, View_HeaderTabComponent_Host_0, { tabItem: "tabItem" }, {}, []);
export { HeaderTabComponentNgFactory as HeaderTabComponentNgFactory };
