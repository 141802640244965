import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {debounceTime, filter, finalize, flatMap, switchMap, tap} from 'rxjs/operators';
import {UserSmallDTO} from '../../../api/aua_angular-client';
import {CouponDetailsService} from '../../../coupon-details/service/coupon-details.service';
import {TabService} from '../../../header/service/tab.service';
import {MessengerService} from '../../../shared/service/messenger.service';
import {QuickSearchSelectionItem} from '../../model/QuickSearchSelectionItem';
import {SEARCH_TYPE} from '../../quick-search.enums';
import {QuickSearchService} from '../../service/quick-search.service';

@Component({
  selector: 'app-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss']
})
export class QuickSearchComponent implements OnInit, OnDestroy {
  @Input() placeholderText: string;
  @Input() searchType = SEARCH_TYPE.EMAIL;

  // enum
  SEARCH_TYPE = SEARCH_TYPE;

  quickSearchForm: FormGroup;
  quickSearchResult;

  selection: QuickSearchSelectionItem;

  isLoading: boolean;

  quickSearchSubscription: Subscription;


  constructor(private fb: FormBuilder,
              private quickSearchService: QuickSearchService,
              private messengerService: MessengerService,
              private tabService: TabService,
              private couponDetailsService: CouponDetailsService,
              private router: Router
  ) {
  }

  ngOnInit(): void {
    this.setupForm();
    this.registerUserSearch();
  }

  ngOnDestroy(): void {
    this.quickSearchSubscription.unsubscribe();
  }

  /**
   * Builds reactive form model for the quickSearch
   */
  setupForm(): void {
    // Create Formgroup for search
    this.quickSearchForm = this.fb.group(
      {
        search_text: ['']
      }
    );
  }

  /**
   * Creates realtime search and connects results to reactive form.
   */
  registerUserSearch() {
    this.quickSearchSubscription = this.quickSearchForm.get('search_text').valueChanges
      .pipe(
        tap(() => {
          this.quickSearchResult = [];
        }),
        filter(val => val.length >= 3),
        debounceTime(400),
        tap(() => this.isLoading = true),
        switchMap(term => this.quickSearchService.searchFor(term.trim(), this.searchType).pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )),
        flatMap(response => this.quickSearchService.getQuickSearchResultObservableFromType(response, this.searchType))
      ).subscribe(
        (response) => {

          switch (this.searchType) {
            case 'email':
              this.quickSearchResult = response;
              break;
            case 'subject':
              this.quickSearchResult = response;
              break;
            case 'coupon':
              this.quickSearchResult = this.quickSearchService.formatCouponSearchResponse(response);
              break;
          }
        }, (error) => {
          this.messengerService.sendNotification(error, 'AUA | quickSearch');
        }
      );
  }

  /**
   * Fills selected search result to form input and stores it to the service
   * @param searchResultSelection result that was selected
   */
  onAutocompleteSelection(searchResultSelection: any) {
    this.selection = this.quickSearchService.setQuickSearchSelectionItem(searchResultSelection, this.searchType);
  }

  /**
   * User submits search request.
   * @param event of submission
   */
  onSubmit(event?: KeyboardEvent) {
    const quickSearchSelectionItem = this.quickSearchService.getQuickSearchSelectionItem();

    if (event.type === 'click' || (event.type === 'keyup' && event.key === 'Enter')) {
      if (quickSearchSelectionItem) {
        const searchType = quickSearchSelectionItem.searchType;

        // Search type of email | user
        if (searchType === 'email' || searchType === 'subject') {
          const user: UserSmallDTO = quickSearchSelectionItem.value;
          this.router.navigate(['user/details'], {queryParams: {subject: user.subject}});
        }

        // Search type of coupon
        if (searchType === 'coupon') {
          this.router.navigate(['coupon/details'], {queryParams: {couponCode: quickSearchSelectionItem.key}});
        }
      }
    }

    // Clear quickSearchForm
    this.quickSearchForm.setValue({search_text: ''});
  }
}
