/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./auth-callback.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i5 from "@angular/material/progress-bar";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./auth-callback.component";
import * as i8 from "../../services/auth.service";
var styles_AuthCallbackComponent = [i0.styles];
var RenderType_AuthCallbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthCallbackComponent, data: {} });
export { RenderType_AuthCallbackComponent as RenderType_AuthCallbackComponent };
export function View_AuthCallbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "hd--authenticate-progress"], ["fxLayout", ""], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["fxFlex", "50%"]], null, null, null, null, null)), i1.ɵdid(4, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [["class", "mat-h2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Helpdesk Login..."])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["mode", "buffer"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i4.View_MatProgressBar_0, i4.RenderType_MatProgressBar)), i1.ɵdid(8, 4374528, null, 0, i5.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i6.ANIMATION_MODULE_TYPE], [2, i5.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 2, 0, currVal_1); var currVal_2 = "50%"; _ck(_v, 4, 0, currVal_2); var currVal_6 = "buffer"; _ck(_v, 8, 0, currVal_6); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 8).value; var currVal_4 = i1.ɵnov(_v, 8).mode; var currVal_5 = i1.ɵnov(_v, 8)._isNoopAnimation; _ck(_v, 7, 0, currVal_3, currVal_4, currVal_5); }); }
export function View_AuthCallbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-auth-callback", [], null, null, null, View_AuthCallbackComponent_0, RenderType_AuthCallbackComponent)), i1.ɵdid(1, 114688, null, 0, i7.AuthCallbackComponent, [i8.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthCallbackComponentNgFactory = i1.ɵccf("app-auth-callback", i7.AuthCallbackComponent, View_AuthCallbackComponent_Host_0, {}, {}, []);
export { AuthCallbackComponentNgFactory as AuthCallbackComponentNgFactory };
