<!--form -->
<form class="hd--advanced-search-form" fxLayout="column" [formGroup]="searchForm" (ngSubmit)="onSubmit()">

    <section class="hd--advanced-search-form-hint s-margin-bottom-small">
        <mat-hint>{{singleFormControlHint}}</mat-hint>
    </section>

    <mat-form-field>
        <input placeholder="Subject" matInput formControlName="subject">
    </mat-form-field>
    <mat-form-field>
        <input placeholder="Vorname" matInput formControlName="given_name">
    </mat-form-field>
    <mat-form-field>
        <input placeholder="Nachname" matInput formControlName="family_name">
    </mat-form-field>
    <mat-form-field>
        <input placeholder="Erstellungsdatum" matInput formControlName="created_at">
    </mat-form-field>
    <mat-form-field>
        <input placeholder="Email" matInput formControlName="email">
    </mat-form-field>
    <mat-form-field>
        <mat-select placeholder="Kontoanbieter (Provider)" formControlName="provider">
            <mat-option *ngFor="let provider of providerOptions" [value]="provider.value">
                {{provider.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-select placeholder="Mandant" formControlName="mandant_group">
            <mat-option *ngFor="let mandantGroup of mandantGroupOptions" [value]="mandantGroup.value">
                {{mandantGroup.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <button type="submit" [disabled]="searchForm.pristine" mat-raised-button color="primary">Benutzer suchen</button>
</form>
