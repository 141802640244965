import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SiteLayoutComponent} from './_layout/site-layout/site-layout.component';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTPGlobalResponse} from './interceptor/http-global-response.interceptor';
import {MockRequestsInterceptor} from './interceptor/mock-requests.interceptor';
import {ApiModule} from './modules/api/api.module';
import {AuthenticationModule} from './modules/authentication/authentication.module';
import {ExtendedSearchModule} from './modules/extended-search/extended-search.module';
import {HeaderModule} from './modules/header/header.module';
import {LoginModule} from './modules/login/login.module';
import {MaterialModule} from './modules/material/material.module';
import {PopupConfirmationDialogComponent} from './modules/shared/component/popup-confirmation-dialog/popup-confirmation-dialog.component';
import {PopupErrorDialogComponent} from './modules/shared/component/popup-error-dialog/popup-error-dialog.component';
import {SharedModule} from './modules/shared/shared.module';


/**
 *  Module Imports
 */
@NgModule({
  declarations: [
    AppComponent,
    SiteLayoutComponent
  ],
  imports: [
    MaterialModule,
    ApiModule,
    FlexLayoutModule,
    SharedModule,
    HttpClientModule,
    HeaderModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthenticationModule,
    LoginModule,
    ExtendedSearchModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPGlobalResponse,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MockRequestsInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [PopupErrorDialogComponent, PopupConfirmationDialogComponent]
})

export class AppModule {
}
