import {Injectable} from '@angular/core';
import {GlobalResultResponse} from '../../api/aua_angular-client';
import {Subject} from 'rxjs';
import resultCodes from '../../../app.resultCodes';
import {PopUpDialogItem} from '../models/PopUpDialogItem';


@Injectable({
  providedIn: 'root'
})
export class MessengerService {

  ErrorPopUp$: Subject<any>;
  Notification$: Subject<any>;

  constructor() {
    this.ErrorPopUp$ = new Subject<any>();
    this.Notification$ = new Subject<any>();
  }

  /**
   * Reveals Fullscreen Error Pop up. Can only be closed-
   */
  public sendPopUpError(popUpDialogItem: PopUpDialogItem): void {
    this.ErrorPopUp$.next(popUpDialogItem);
  }

  /**
   * Sends a notification displayed in app-notification component
   *
   * @param resultCode to get message
   * @param context of message
   */
  public sendNotification(resultCode: string, context: string, messageDetails?: string) {

    let message: string = resultCodes.getMessage(resultCode);

    if (messageDetails) {
      message += ' Warnung: ' + messageDetails;
    }

    const data = {
      action: 'display',
      message,
      context
    };

    this.Notification$.next(data);
  }

  /**
   * Send clear-action to component
   */
  public clearNotifications() {
    this.Notification$.next({action: 'clear'});
  }

  /**
   * Sends Global Response to pop up
   * @param globalResponse with messages
   */
  public handleGlobalResponse(globalResponse: GlobalResultResponse) {
    const state: string = globalResponse.result_state;
    switch (state) {
      case 'ERROR':
        const message: string = resultCodes.getMessage(globalResponse.result_code);
        const detailMessage: string = globalResponse.result_message;
        // this.sendPopUpError({message, detailMessage});
        break;
    }
  }
}
