import {Component, OnDestroy, OnInit} from '@angular/core';
import {MessengerService} from '../../service/messenger.service';
import {NotificationItem} from '../../models/NotificationItem';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {

    message: string;

    notificationSubscription;

    notifications: NotificationItem[];

    constructor(private messengerService: MessengerService) {
    }

    ngOnInit() {
        this.notifications = [];

        this.notificationSubscription = this.messengerService.Notification$.subscribe((data) => {

            // Create a new message and push
            const notification: NotificationItem = {
                message: data.message,
                context: data.context,
                action: data.action,
                isShown: true
            };

            if (data.action === 'clear') {
                this.notifications = [];
            } else {
                this.notifications.push(notification);
            }

        });
    }

    ngOnDestroy(): void {
        this.notificationSubscription.unsubscribe();
    }

    onCancelClick(notification) {
        notification.isShown = false;
    }

}
