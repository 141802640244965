import * as i0 from "@angular/core";
var LoginService = /** @class */ (function () {
    function LoginService() {
    }
    LoginService.prototype.setLogoutReason = function (reason) {
        this.logOutReason = reason;
    };
    LoginService.prototype.getLogoutReason = function () {
        return this.logOutReason;
    };
    LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
