/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup-dialog-manager.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./popup-dialog-manager.component";
import * as i3 from "@angular/material/dialog";
import * as i4 from "../../service/messenger.service";
var styles_PopupDialogManagerComponent = [i0.styles];
var RenderType_PopupDialogManagerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopupDialogManagerComponent, data: {} });
export { RenderType_PopupDialogManagerComponent as RenderType_PopupDialogManagerComponent };
export function View_PopupDialogManagerComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_PopupDialogManagerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup-dialog-manager", [], null, null, null, View_PopupDialogManagerComponent_0, RenderType_PopupDialogManagerComponent)), i1.ɵdid(1, 49152, null, 0, i2.PopupDialogManagerComponent, [i3.MatDialog, i4.MessengerService], null, null)], null, null); }
var PopupDialogManagerComponentNgFactory = i1.ɵccf("app-popup-dialog-manager", i2.PopupDialogManagerComponent, View_PopupDialogManagerComponent_Host_0, {}, {}, []);
export { PopupDialogManagerComponentNgFactory as PopupDialogManagerComponentNgFactory };
