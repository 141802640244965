/**
 *  WARNING: IMPORT THIS MODULE IN APP-MODULE ONLY! forRoot() - Modules should only be provided once!
 */
import { Configuration, ConfigurationParameters } from './aua_angular-client';
export function ConfigFactory() {
    var params = {
        apiKeys: {}
    };
    return new Configuration(params);
}
var ɵ0 = function (settings) { return settings.basePathOSC; }, ɵ1 = function (settings) { return settings.basePathIDP; }, ɵ2 = function (settings) { return settings.basePathAuA; }, ɵ3 = function (settings) { return settings.basePathPROS; }, ɵ4 = function (settings) { return settings.basePathLIMA; }, ɵ5 = function (settings) { return settings.basePathBILO; };
var ApiModule = /** @class */ (function () {
    function ApiModule() {
    }
    return ApiModule;
}());
export { ApiModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
