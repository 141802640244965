import {NgModule} from '@angular/core';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatCardModule,
    MatSnackBarModule,
    MatListModule,
    MatIconModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatDialogModule,
    MatSortModule,
    MatCheckboxModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatDividerModule,
    MatRadioModule,
    MatProgressBarModule,
    MatMenuModule, MatSlideToggleModule
} from '@angular/material';
import {CdkTableModule} from '@angular/cdk/table';


@NgModule({
    declarations: [],
    imports: [
        MatFormFieldModule,
        MatTableModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatCardModule,
        MatSnackBarModule,
        MatListModule,
        MatIconModule,
        MatButtonToggleModule,
        MatSelectModule,
        MatDialogModule,
        MatSortModule,
        MatCheckboxModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatDividerModule,
        MatRadioModule,
        CdkTableModule,
        MatMenuModule,
        MatSlideToggleModule
    ],
    exports: [
        MatFormFieldModule,
        MatTableModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatCardModule,
        MatSnackBarModule,
        MatListModule,
        MatIconModule,
        MatButtonToggleModule,
        MatSelectModule,
        MatDialogModule,
        MatSortModule,
        MatCheckboxModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatDividerModule,
        MatRadioModule,
        CdkTableModule,
        MatMenuModule,
        MatSlideToggleModule
    ]
})
export class MaterialModule {
}
