import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import resultCodes from '../../../../app.resultCodes';
import {Settings} from '../../../../models/settings';
import {LoginService} from '../../../../service/login.service';
import {RoutingService} from '../../../../service/routing.service';
import {APP_CONFIGURATION} from '../../../../service/settings.http.service';
import {AuthService} from '../../../authentication/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  // default
  public logoURL = '/assets/ionesoft_HelpDesk.png';

  public logoutReason = '';

  constructor(private router: Router,
              private routingService: RoutingService,
              private loginService: LoginService,
              @Inject(APP_CONFIGURATION) private readonly configuration: Settings,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.requestAppSettings();
    this.getLogoutReason();
  }

  getLogoutReason() {
    const reason = this.loginService.getLogoutReason();
    if (reason !== 'AUTH_USER_LOGOUT') {
      this.logoutReason = resultCodes.getMessage(reason);
    }
  }

  /**
   * Requests app settings from SettingsHttpService
   */
  requestAppSettings() {
    this.logoURL = this.configuration.theme.logoURL;
  }

  onLoginClick() {
    this.authService.startLogin();
  }
}
