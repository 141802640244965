import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ExtendedSearchService} from '../../service/extended-search.service';
import {NavigationStart, Router} from '@angular/router';
import {AuaApiService} from '../../../../service/aua-api.service';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-extended-search-form',
    templateUrl: './extended-search-form.component.html',
    styleUrls: ['./extended-search-form.component.scss']
})
export class ExtendedSearchFormComponent implements OnInit {
    searchForm: FormGroup;

    providerOptions: object [] = [];
    mandantGroupOptions: object [] = [];

    PROVIDER_LABELS = {
        BEOOK_1: 'beook',
        HEP_MAGENTO_1: 'Hep',
        PHZH_AD_1: 'PHZH',
        gitlab: 'GitLab',
        MS: 'MS',
        RICHM_PERFORMA_1: 'Richemont'
    };

    singleFormControlHint: string;

    searchRequestSubject: Subject<FormGroup>;

    constructor(private fb: FormBuilder,
                private extendedSearchService: ExtendedSearchService,
                private auaApiService: AuaApiService,
                private router: Router
    ) {
        this.registerRouteChange();
    }

    ngOnInit() {
        this.searchRequestSubject = this.extendedSearchService.getSearchRequestSubject();
        this.createForm();
        this.loadProviderList();
        this.loadMandantGroupList();
        this.loadExistingFormData();
    }

    /**
     * Creates initial form with formBuilder
     */
    createForm() {
        this.searchForm = this.fb.group({
            subject: [''],
            given_name: [''],
            family_name: [''],
            created_at: [''],
            email: [''],
            provider: [null],
            mandant_group: [null],
        });
    }

    /**
     * Requests & loads available providers
     */
    loadProviderList() {
        this.extendedSearchService.getProviderList().subscribe((providers) => {
            const providerOptions: object [] = [];

            for (const provider of providers) {
                providerOptions.push({
                    name: this.getProviderLabel(provider),
                    value: provider
                });
            }

            providerOptions.unshift({
                name: 'Alle',
                value: ''
            });

            // this.searchForm.controls
            this.providerOptions = providerOptions;
            this.searchForm.get('provider').setValue('');
        });
    }

    /**
     * Requests & laods available mandant groups
     */
    loadMandantGroupList() {
        this.extendedSearchService.getMandantGroupList().subscribe((mandantGroups) => {
            const mandantGroupOptions: object [] = [];

            for (const mandantGroup of mandantGroups) {
                mandantGroupOptions.push({
                    name: mandantGroup,
                    value: mandantGroup
                });
            }

            mandantGroupOptions.unshift({
                name: 'Alle',
                value: ''
            });

            this.mandantGroupOptions = mandantGroupOptions;
            this.searchForm.get('mandant_group').setValue('');
        });
    }

    /**
     * Store current search results if route changes (user navigates away)
     */
    registerRouteChange() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.extendedSearchService.storeSearchFormGroup(this.searchForm);
            }
        });
    }

    /**
     * Loads stored form data
     */
    loadExistingFormData() {
        const loadData: FormGroup = this.extendedSearchService.getStoredSearchFormGroup();
        if (loadData) {
            this.searchForm = loadData;
        }
    }

    /**
     * Get label to provider-id
     * @param provider to look up
     */
    getProviderLabel(provider: string) {
        const label = typeof this.PROVIDER_LABELS[provider] !== 'undefined' ? this.PROVIDER_LABELS[provider] : provider;
        return label;
    }

    /**
     * Checks if user filled out any field of the form
     * @param searchForm to validate
     */
    formIsValid(searchForm: FormGroup): boolean {
        let filledControls = 0;
        let objectKey = '';
        const values = searchForm.value;

        Object.keys(values).map((key) => {
            if (values[key]) {
                filledControls++;
                objectKey = key;
            }
        });

        if (filledControls === 1 && values[objectKey].length >= 3) {
            return true;
        }

        return filledControls > 1;
    }

    /**
     * Validates form and emits serachForm through the searchRequest Subject
     */
    onSubmit() {
        if (this.formIsValid(this.searchForm)) {
            this.searchRequestSubject.next(this.searchForm);
            this.singleFormControlHint = '';
        } else {
            this.singleFormControlHint = 'Einzelne Suchkriterien müssen mindestens 3 Zeichen lang sein';
        }
    }
}
