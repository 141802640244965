import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    private logOutReason: string;

    constructor() {
    }

    setLogoutReason(reason: string) {
        this.logOutReason = reason;
    }

    getLogoutReason() {
        return this.logOutReason;
    }
}
