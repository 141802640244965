import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IdpApiService} from '../../../service/idp-api.service';
import {OscApiService} from '../../../service/osc-api.service';
import {
  CouponChangeDTO1,
  CouponChangeStateResponse1,
  CouponResultDetail1,
  CouponSearchResponse1,
  OrderSyncForUserResponse1,
  OrderSystemDTO1
} from '../../api/osc_angular-client';
import {Coupon as COUPON} from '../coupon.dict';

@Injectable({
  providedIn: 'root'
})
export class CouponDetailsService {

  couponSearchStack: CouponResultDetail1[] = [];

  constructor(
    private idpApiService: IdpApiService,
    private oscApiService: OscApiService
  ) {
  }

  /**
   * Clear couponSearchStack
   */
  clearCoupons() {
    this.couponSearchStack = [];
    sessionStorage.removeItem('couponSearchStack');
  }

  /**
   * Returns human readable string to given couponState
   * @param couponState to check
   */
  getCouponCodeString(couponState: string) {
    let stateTranslation = '';

    switch (couponState) {
      case COUPON.STATE.ACTIVE:
        stateTranslation = 'Aktiv';
        break;
      case COUPON.STATE.VALID:
        stateTranslation = 'Gültig';
        break;
      case COUPON.STATE.ASSIGNED:
        stateTranslation = 'Eingelöst';
        break;
      case COUPON.STATE.WITHDRAWN:
        stateTranslation = 'Zurückgezogen';
        break;
    }

    return stateTranslation;
  }

  /**
   * Change state of coupon
   * @param couponCode
   * @param couponState
   * @param subject
   * @param orderSystemDTO
   */
  changeState(couponCode: string, couponState: string, subject: string, orderSystemDTO: OrderSystemDTO1): Observable<CouponChangeStateResponse1> {
    return this.oscApiService.changeState(couponCode, couponState, subject, orderSystemDTO);
  }

  /**
   *
   * @param couponChangeDTO
   */
  changeCoupon(couponChangeDTO: CouponChangeDTO1) {
    return this.oscApiService.changeCoupon(couponChangeDTO);
  }

  /**
   * Snyc order for user
   * @param subject to sync
   */
  orderSyncForUsers(subject: string): Observable<OrderSyncForUserResponse1> {
    return this.oscApiService.orderSyncForUser(subject);
  }

  /**
   * Using coupon search to get to coupon informations
   *
   * @param couponCode
   * @param quickSearch
   */
  couponSearch(couponCode: string, quickSearch: boolean = true): Observable<CouponSearchResponse1> {
    return this.oscApiService.quickSearchCouponCode(couponCode, quickSearch);
  }
}
