import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {UserManager} from 'oidc-client';
import {from, Observable} from 'rxjs';
import {concatMap, map} from 'rxjs/operators';
import {Settings} from '../../../models/settings';
import {AuaApiService} from '../../../service/aua-api.service';
import {LoginService} from '../../../service/login.service';
import {APP_CONFIGURATION} from '../../../service/settings.http.service';
import {AUAClientService, UserDTO, UserGetResponse} from '../../api/aua_angular-client';
import {BILOClient1Service} from '../../api/bilo_angular-client';
import {HdIdpApiService} from '../../api/idp_angular-client/custom/hd-idp-api.service';
import {LIMAClient1Service} from '../../api/lima_angular-client';
import {OSCClient1Service} from '../../api/osc_angular-client';
import {PROSProductAndOrderSystem1Service} from '../../api/pros_angular-client';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authUser: any;
  private auaUser: UserDTO;

  constructor(private router: Router,
              private oauthService: OAuthService,
              private auaClientService: AUAClientService,
              private loginService: LoginService,
              @Inject(APP_CONFIGURATION) public readonly configuration: Settings,
              private auaApiService: AuaApiService,
              private biloApiService: BILOClient1Service,
              private idpApiService: HdIdpApiService,
              private limaApiService: LIMAClient1Service,
              private oscApiService: OSCClient1Service,
              private prosApiService: PROSProductAndOrderSystem1Service
  ) {
  }

  public startLogin(): void {
    this.oauthService.initImplicitFlow();
  }

  /**
   *
   */
  public configureService(): void {
    this.oauthService.configure(this.configuration.oicdClientSettings);
  }

  /**
   *
   */
  public checkAuth(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  private assignTokens() {
    const token = this.oauthService.getAccessToken();

    this.auaClientService.configuration.apiKeys['Authorization'] = token;
    this.biloApiService.configuration.apiKeys['Authorization'] = token;
    this.idpApiService.configuration.apiKeys['Authorization'] = token;
    this.limaApiService.configuration.apiKeys['Authorization'] = token;
    this.oscApiService.configuration.apiKeys['Authorization'] = token;
    this.prosApiService.configuration.apiKeys['Authorization'] = token;
  }


  /**
   * Perform action when authentication is confirmed
   */
  completeAuthentication(): void {

    this.oauthService.tryLoginImplicitFlow();

    if (this.oauthService.hasValidAccessToken()) {

      this.assignTokens();

      from(this.oauthService.loadUserProfile()).pipe(
        concatMap((result) => {
          const subject = result['sub'];
          const roles = result['roles'];

          this.authUser = {roles, subject};

          return this.auaApiService.getUser(subject);
        })).subscribe((response: UserGetResponse) => {

        if (response.user_get_detail_response.length) {
          this.auaUser = response.user_get_detail_response[0].user;
        }

        this.router.navigate([this.configuration.postLoginRoute]);

      }, error => console.error('AuthService: Error during authorisation', error));
    }
  }

  /**
   * Get user stored by oicd-client
   */
  getUser$(): Observable<any> {
    if (!this.authUser) {
      return from(this.oauthService.loadUserProfile()).pipe(map((result) => {
        const subject = result['sub'];
        const roles = result['roles'];
        this.authUser = {roles, subject};
        return this.authUser;
      }));
    }

    return new Observable<any>(observer => observer.next(this.authUser));
  }

  /**
   * Returns aua-information of logged in user
   */
  getAuAUser$(): Observable<UserDTO> {

    if (!this.auaUser) {
      return from(this.oauthService.loadUserProfile()).pipe(
        concatMap((result) => {
          const subject = result['sub'];
          return this.auaApiService.getUser(subject);
        }),
        map(result => result.user_get_detail_response[0].user)
      );
    }


    return new Observable<UserDTO>(observer => observer.next(this.auaUser));
  }

  /**
   *
   */
  hasValidToken(): boolean {
    if (this.oauthService.hasValidAccessToken()) {
      this.assignTokens();
    }
    return this.oauthService.hasValidAccessToken();
  }

  /**
   * Defines Roles that are allowed to login
   * @param roles to check against
   */
  canUse(roles: Array<string>) {
    return roles.includes('IA_ADMIN') || roles.includes('IA_SUPPORTER');
  }

  /**
   * Starts signout
   * @param logoutReason general reason
   */
  startSignOut(logoutReason: string = ''): void {
    // Remove stored data
    this.oauthService.logOut();
    sessionStorage.removeItem('tabs');

    if (logoutReason) {
      this.loginService.setLogoutReason(logoutReason);
    }

    this.router.navigate(['/login']);
  }
}
