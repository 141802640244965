import { AuthService } from './auth.service';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(authService) {
        this.authService = authService;
    }
    /**
     * Checks if user is allowed to access otherwise inits. authentication process
     * @return user is allowed to activate route
     */
    AuthGuardService.prototype.canActivate = function () {
        var _this = this;
        return new Observable(function (observer) { return observer.next(_this.authService.hasValidToken()); });
    };
    AuthGuardService.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.inject(i1.AuthService)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
