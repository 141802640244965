import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {AuaApiService} from '../../../service/aua-api.service';
import {UserDTO, UserGetRoleDetailResponse, UserGetRolesResponse} from '../../api/aua_angular-client';
import {flatMap, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AdminGuardService implements CanActivate {

    constructor(private authService: AuthService, private auaService: AuaApiService) {
    }

    /**
     * Gets AuA user and asks for its permissions
     */
    canActivate(): Observable<any> {
        return this.authService.getAuAUser$().pipe(
            flatMap((auaUser: UserDTO) => {
                return this.auaService.getUserRoles(auaUser.subject).pipe(
                    map((rolesList: UserGetRolesResponse) => {
                        const rolesListDetailResponse: UserGetRoleDetailResponse = rolesList.user_get_role_detail_response;
                        const roles: Array<string> = rolesListDetailResponse.roles;
                        const adminRole = roles.includes('IA_ADMIN');
                        return adminRole;
                    })
                );
            })
        );
    }

}
