import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {PopUpConfirmationDialogItem} from '../../models/PopUpConfirmationDialogItem';

@Component({
    selector: 'app-confirmation-pop-up',
    templateUrl: './popup-confirmation-dialog.component.html',
    styleUrls: ['./popup-confirmation-dialog.component.scss']
})
export class PopupConfirmationDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<PopupConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PopUpConfirmationDialogItem) {
    }

    onAbortClick() {
        this.dialogRef.close('abort');
    }

    onConfirmClick() {
        this.dialogRef.close(this.data.action);
    }

}
