import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QuickSearchComponent} from './component/quick-search/quick-search.component';
import {SharedModule} from '../shared/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {FormatQuickSearchPipe} from './pipe/format-quick-search-result.pipe';

@NgModule({
    declarations: [QuickSearchComponent, FormatQuickSearchPipe],
    imports: [
        SharedModule,
        ReactiveFormsModule,
        CommonModule
    ],
    exports: [
        QuickSearchComponent
    ]
})
export class QuickSearchModule {
}
