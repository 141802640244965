var _this = this;
import * as tslib_1 from "tslib";
import { enableProdMode } from '@angular/core';
import { APP_CONFIGURATION, SettingsHttpService } from './app/service/settings.http.service';
import { environment } from './environments/environment';
import 'hammerjs';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
fetch(SettingsHttpService.SETTINGS_URL).then(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
    var config;
    return tslib_1.__generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, data.json()];
            case 1:
                config = (_a.sent());
                __NgCli_bootstrap_2.platformBrowser([
                    {
                        provide: APP_CONFIGURATION,
                        useValue: config
                    }
                ])
                    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
                    .catch(function (err) { return console.error('[main.ts] error bootstrapping app', err); });
                return [2 /*return*/];
        }
    });
}); }).catch(function (err) { return console.error('[main.ts] error bootstrapping app', err); });
