import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  AttachPermissionDTO,
  AUAClientService, CreatePropertyTemplateRequest, DetachPermissionDTO, DetailResultResponse, GroupDTO, IdentityDTO,
  PermissionAttachedResponse, PermissionCreateResponse,
  PermissionDeleteResponse, PermissionDetachedResponse,
  PermissionDTO,
  PermissionListResponse, PermissionSetResponse,
  PropertyCreateDTO, PropertyCreateTemplatesResponse,
  PropertyGetTemplatesResponse, PropertyTemplateDTO,
  ResolveRoleResponse, RoleDTO,
  RoleListResponse, SetPermissionDTO, TogglePropertyTemplateRequest, UserDeleteIdentityRequest, UserDetailResponse,
  UserExtendedSearchRequest, UserExternalSearchRequest, UserExternalSearchResponse, UserGetGroupsGlobalResponse,
  UserGetIdentitiesResponse,
  UserGetPropertiesResponse,
  UserGetRequest,
  UserGetResponse,
  UserGetRolesResponse,
  UserQuickSearchRequest,
  UserQuickSearchResponse, UserSetEmailRequest,
  UserSetGlobalResponse, UserSetGroupsRequest,
  UserSetPropertiesResponse,
  UserSetPropertyRequest,
  UserSetRolesRequest
} from '../modules/api/aua_angular-client';
import {map} from 'rxjs/operators';
import {MessengerService} from '../modules/shared/service/messenger.service';
import {forEach} from '@angular/router/src/utils/collection';
import {UserDeleteRequest, UserSetGroupResponse} from '../modules/api/idp_angular-client';
import SearchTypeEnum = UserQuickSearchRequest.SearchTypeEnum;

@Injectable({
  providedIn: 'root'
})
export class AuaApiService {

  constructor(private auaClientService: AUAClientService, private messengerService: MessengerService) {
  }

  /**
   * @param subject
   */
  getUser(subject: string): Observable<UserGetResponse> {
    const userRequest: UserGetRequest[] = [{subject}];
    return this.auaClientService.getUsers(userRequest, '', '', '',).pipe(map((userGetResponse) => {
      if (userGetResponse.global_response.result_state === 'WARNING') {

        const detailResponse: UserDetailResponse[] = userGetResponse.user_get_detail_response;

        detailResponse.forEach(detailResponse => {
          const detailResultResponse: DetailResultResponse = detailResponse.detail_result_response;
          if (detailResultResponse.result_state === 'ERROR' || detailResultResponse.result_state === 'WARNING') {
            this.messengerService.sendNotification(detailResultResponse.result_code, 'AUA | getUser');
          }
        });

      }
      return userGetResponse;
    }));
  }

  /**
   * @param externalId
   * @param provider
   */
  externalSearchUser(externalId: string, provider: string): Observable<UserExternalSearchResponse> {

    const userExternalSearchRequest: UserExternalSearchRequest = {
      external_id: externalId,
      provider: provider
    };

    return this.auaClientService.externalSearchUser(userExternalSearchRequest, '', '', '',);
  }


  /**
   * @param subject
   */
  getUserRoles(subject: string): Observable<UserGetRolesResponse> {
    const rolesRequest: UserGetRequest = {subject};
    return this.auaClientService.getRoles(rolesRequest, '', '', '',).pipe(map((rolesGetResponse) => {
      if (rolesGetResponse.global_response.result_state === 'WARNING') {
        this.messengerService.sendNotification(rolesGetResponse.global_response.result_code, 'AUA | getUserRoles');
      }
      return rolesGetResponse;
    }));
  }

  /**
   * @param subject
   */
  getUserProperties(subject: string): Observable<UserGetPropertiesResponse> {
    const propertyRequest: UserGetRequest = {subject};
    return this.auaClientService.getPropertiesForUser(propertyRequest, '', '', '').pipe(map((propertiesGetResponse) => {
      if (propertiesGetResponse.global_response.result_state === 'WARNING') {
        this.messengerService.sendNotification(propertiesGetResponse.global_response.result_code, 'AUA | getUserProperties');
      }
      return propertiesGetResponse;
    }));
  }

  /**
   * @param subject
   */
  getUserIdentities(subject: string): Observable<UserGetIdentitiesResponse> {
    const identityRequest: UserGetRequest = {subject};
    return this.auaClientService.getIdentitiesForUser(identityRequest, '', '', '').pipe(map((userGetIdentitesResponse) => {
      if (userGetIdentitesResponse.global_response.result_state === 'WARNING') {
        this.messengerService.sendNotification(userGetIdentitesResponse.global_response.result_code, 'AUA | getUserIdentities');
      }
      return userGetIdentitesResponse;
    }));
  }

  /**
   * @param roles
   */
  createRoles(roles: Array<RoleDTO>) {
    return this.auaClientService.createRoles(roles, '', '', '');
  }

  /**
   * @param permissionsToCreate
   */
  createPermission(permissionsToCreate: Array<PermissionDTO>): Observable<PermissionCreateResponse> {
    return this.auaClientService.createPermissions(permissionsToCreate, '', '', '');
  }

  /**
   * @param permissionsToDetach
   * @param role
   */
  detachPermissions(permissionsToDetach: Array<PermissionDTO>, role: string): Observable<PermissionDetachedResponse> {

    const detachPermissionRequests: DetachPermissionDTO[] = [];

    permissionsToDetach.forEach((permissionDTO) => {
      const attachPermissionDTO: DetachPermissionDTO = {
        role,
        permission: permissionDTO
      };

      detachPermissionRequests.push(attachPermissionDTO);
    });

    return this.auaClientService.detachPermissions(detachPermissionRequests, '', '', '');
  }


  listProperties(): Observable<PropertyGetTemplatesResponse> {
    return this.auaClientService.getPropertyTemplates('', '', '');
  }

  /**
   * @param createPropertyTemplateRequest
   */
  createPropertyTemplate(createPropertyTemplateRequest: CreatePropertyTemplateRequest): Observable<PropertyCreateTemplatesResponse> {
    return this.auaClientService.createPropertyTemplates(createPropertyTemplateRequest, '', '', '');
  }

  /**
   * @param propertyTemplateDTO
   */
  togglePropertyTemplate(propertyTemplateDTO: PropertyTemplateDTO) {
    const togglePropertyTemplateRequest: TogglePropertyTemplateRequest[] = [{
      template_id: propertyTemplateDTO.id,
      active: propertyTemplateDTO.active
    }];
    return this.auaClientService.togglePropertyTemplates(togglePropertyTemplateRequest, '', '', '',);
  }

  listPermissions(): Observable<PermissionListResponse> {
    return this.auaClientService.listPermissions('', '', '');
  }

  /**
   * @param permissionsToAttach
   * @param role
   */
  attachPermissions(permissionsToAttach: Array<PermissionDTO>, role: string): Observable<PermissionAttachedResponse> {
    const attachPermissionRequests: Array<AttachPermissionDTO> = [];

    permissionsToAttach.forEach((permissionDTO) => {
      const attachPermissionDTO: AttachPermissionDTO = {
        role,
        permission: permissionDTO
      };

      attachPermissionRequests.push(attachPermissionDTO);
    });

    return this.auaClientService.attachPermissions(attachPermissionRequests, '', '', '');
  }

  /**
   * @param permissions
   * @param role
   */
  setPermissions(permissions: Array<PermissionDTO>, role: string): Observable<PermissionSetResponse> {

    const setPermissionDTOs: SetPermissionDTO[] = [{
      role,
      permissions
    }];

    return this.auaClientService.setPermissions(setPermissionDTOs, '', '', '');
  }

  listMandantGroups() {
    return this.auaClientService.listGroups('', '', '');
  }

  listRoles(): Observable<RoleListResponse> {
    return this.auaClientService.listRoles('', '', '').pipe(map((roleListResponse) => {
      if (roleListResponse.global_response.result_state === 'WARNING') {
        this.messengerService.sendNotification(roleListResponse.global_response.result_code, 'AUA | listRoles');
      }
      return roleListResponse;
    }));
  }

  /**
   * @param permissionsToDelete
   */
  deletePermission(permissionsToDelete: Array<PermissionDTO>): Observable<PermissionDeleteResponse> {
    return this.auaClientService.deletePermissions(permissionsToDelete, '', '', '');
  }

  /**
   * @param roles
   */
  resolveRoles(roles: Array<string>): Observable<ResolveRoleResponse> {
    const resolveRolesRequest: Array<string> = roles;
    return this.auaClientService.resolveRoles(resolveRolesRequest, '', '', '');
  }

  /**
   * @param roles
   */
  deleteRoles(roles: Array<string>) {
    return this.auaClientService.deleteRoles(roles, '', '', '');
  }

  getProviders() {
    return this.auaClientService.getProviders('', '', '');
  }

  /**
   * @param subject
   */
  getMandantGroups(subject: string): Observable<UserGetGroupsGlobalResponse> {
    const userGetMandantGroupsRequest: UserGetRequest = {
      subject
    };
    return this.auaClientService.getMandantGroups(userGetMandantGroupsRequest, '', '', '');
  }

  getPropertyTemplates(): Observable<PropertyGetTemplatesResponse> {
    return this.auaClientService.getPropertyTemplates('', '', '').pipe(map((propertyGetTemplatesResponse) => {
      if (propertyGetTemplatesResponse.global_response.result_state === 'WARNING') {
        this.messengerService.sendNotification(propertyGetTemplatesResponse.global_response.result_code, 'AUA | getPropertyTemplates');
      }

      return propertyGetTemplatesResponse;
    }));
  }

  /**
   * @param subject
   * @param mandantGroups
   */
  setMandantGroups(subject: string, mandantGroups: GroupDTO[]): Observable<UserSetGroupResponse> {
    const setMandantGroupsRequest: UserSetGroupsRequest = {
      subject: subject,
      mandant_groups: mandantGroups
    };

    return this.auaClientService.setMandantGroups(setMandantGroupsRequest, '', '', '');
  }

  /**
   * @param term
   * @param type
   */
  quickSearchUser(term, type = SearchTypeEnum.EmailOrSubject): Observable<UserQuickSearchResponse> {
    const userQuickSearch: UserQuickSearchRequest = {
      search_text: term,
      search_type: type,
      search_in_identities: true
    };
    return this.auaClientService.quickSearchUser(userQuickSearch, '', '', '');
  }

  /**
   * @param userExtendedSearch
   */
  extendedSearchUser(userExtendedSearch: UserExtendedSearchRequest) {
    return this.auaClientService.extendedSearchUser(userExtendedSearch, '', '', '');
  }

  /**
   * @param subject
   * @param properties
   */
  setProperties(subject: string, properties: PropertyCreateDTO[]): Observable<UserSetPropertiesResponse> {
    const propertyRequest: UserSetPropertyRequest = {subject, properties};
    return this.auaClientService.setProperties(propertyRequest, '', '', '').pipe(map((userSetPropertiesResponse: UserSetPropertiesResponse) => {
      if (userSetPropertiesResponse.global_response.result_state === 'WARNING') {

        const detailResponses = userSetPropertiesResponse.user_set_property_detail_response;

        detailResponses.forEach((detailResponse) => {
          const warning = detailResponse.detail_result_response.result_code;
          this.messengerService.sendNotification(warning, 'AUA | setProperties');
        });

      }
      return userSetPropertiesResponse;
    }));
  }

  /**
   * @param subject
   * @param roles
   */
  setRoles(subject: string, roles: string[]): Observable<UserSetGlobalResponse> {
    const roleSetRequset: UserSetRolesRequest = {subject, roles};
    return this.auaClientService.setRoles(roleSetRequset, '', '', '').pipe(map((userSetGlobalResponse) => {
      if (userSetGlobalResponse.global_response.result_state === 'WARNING') {
        this.messengerService.sendNotification(userSetGlobalResponse.global_response.result_code, 'AUA | setRoles');
      }
      return userSetGlobalResponse;
    }));
  }

  /**
   * @param external_id
   * @param provider
   * @param email
   */
  setEmail(external_id: string, provider: string, email: string,) {
    const setEmailRequest: UserSetEmailRequest = {
      external_id,
      provider,
      email
    };
    return this.auaClientService.setEmail(setEmailRequest, '', '', '');
  }

  /**
   * @param subject
   */
  deleteUser(subject: string) {
    const userDeleteRequests: UserGetRequest[] = [{subject}];
    return this.auaClientService.deleteUsers(userDeleteRequests, '', '', '');
  }

  /**
   * @param external_id
   * @param provider
   */
  deleteIdentityForUser(external_id: string, provider: string) {
    const deleteUserIdentityRequest: UserDeleteIdentityRequest = {
      external_id,
      provider
    };
    return this.auaClientService.deleteIdentityForUser(deleteUserIdentityRequest, '', '', '');
  }
}
