/**
 * ioneaccess Identity Provider (IDP) API
 * API for IDP (ioneacces Identity Provider). This document does not cover the OpenID Connect part!
 *
 * OpenAPI spec version: 1.1.0
 * Contact: christian.schlatter@ionesoft.ch
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { UserCreateDTO } from '../model/userCreateDTO';
import { UserGetRequest } from '../model/userGetRequest';
import { UserLoginDTO } from '../model/userLoginDTO';
import { UserLoginResponse } from '../model/userLoginResponse';
import { UserRefreshResponse } from '../model/userRefreshResponse';
import { UserRegisterResponse } from '../model/userRegisterResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DexService {

    protected basePath = 'https://igate.ionedev.com/idp/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Login a new user
     * Login a new user. Only checks if credentials are valid. Service doesn&#39;t return a token.
     * @param user Parameters for user login
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginUser(user: UserLoginDTO, observe?: 'body', reportProgress?: boolean): Observable<UserLoginResponse>;
    public loginUser(user: UserLoginDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserLoginResponse>>;
    public loginUser(user: UserLoginDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserLoginResponse>>;
    public loginUser(user: UserLoginDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user === null || user === undefined) {
            throw new Error('Required parameter user was null or undefined when calling loginUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserLoginResponse>(`${this.basePath}/user/login`,
            user,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Refresh an suer for OAuth flow
     * Refresh an suer for OAuth flow. Check if valid user
     * @param user Parameters for user refreh
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public refreshUser(user: UserGetRequest, observe?: 'body', reportProgress?: boolean): Observable<UserRefreshResponse>;
    public refreshUser(user: UserGetRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserRefreshResponse>>;
    public refreshUser(user: UserGetRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserRefreshResponse>>;
    public refreshUser(user: UserGetRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user === null || user === undefined) {
            throw new Error('Required parameter user was null or undefined when calling refreshUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserRefreshResponse>(`${this.basePath}/user/refresh`,
            user,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Register a new user
     * Register a new user
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param Accept_Language client language
     * @param user Parameters for user registration
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, Accept_Language: string, user?: UserCreateDTO, observe?: 'body', reportProgress?: boolean): Observable<UserRegisterResponse>;
    public registerUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, Accept_Language: string, user?: UserCreateDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserRegisterResponse>>;
    public registerUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, Accept_Language: string, user?: UserCreateDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserRegisterResponse>>;
    public registerUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, Accept_Language: string, user?: UserCreateDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling registerUser.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling registerUser.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling registerUser.');
        }

        if (Accept_Language === null || Accept_Language === undefined) {
            throw new Error('Required parameter Accept_Language was null or undefined when calling registerUser.');
        }


        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }
        if (Accept_Language !== undefined && Accept_Language !== null) {
            headers = headers.set('Accept-Language', String(Accept_Language));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserRegisterResponse>(`${this.basePath}/user/register`,
            user,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
