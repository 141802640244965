import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MessengerService } from '../../shared/service/messenger.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../shared/service/messenger.service";
var TabService = /** @class */ (function () {
    function TabService(activeRoute, router, messengerService) {
        this.activeRoute = activeRoute;
        this.router = router;
        this.messengerService = messengerService;
        this.tabItems = [];
        this.tabSubject$ = new BehaviorSubject(undefined);
    }
    /**
     * Creates new tabItem
     * @param tabItem to set
     * @param setActive initial active state
     */
    TabService.prototype.setNewTab = function (tabItem, setActive) {
        var alreadyExistingTabItem = tabItem.type === 'user' ? this.findTabItemBySubject(tabItem.subject) : this.findTabItemByCouponCode(tabItem.couponCode);
        if (!alreadyExistingTabItem) {
            this.deactivateAllTabs();
            tabItem.isActive = setActive;
            if (tabItem.isActive) {
                if (typeof this.activeTabItem !== 'undefined') {
                    this.previouslyActiveItem = this.activeTabItem;
                }
                this.activeTabItem = tabItem.isActive ? tabItem : undefined;
            }
            this.tabItems.push(tabItem);
            this.tabSubject$.next(this.tabItems);
        }
        this.storeTabs();
    };
    /**
     * Sets tab state to active
     * @param tab TabItem to activate
     */
    TabService.prototype.setActiveTab = function (tab) {
        this.deactivateAllTabs();
        if (typeof this.activeTabItem !== 'undefined') {
            this.previouslyActiveItem = this.activeTabItem;
        }
        this.activeTabItem = tab;
        tab.isActive = true;
        this.tabSubject$.next(this.tabItems);
        this.storeTabs();
    };
    /**
     * Get subject to subscribe to
     */
    TabService.prototype.getTabSubject = function () {
        return this.tabSubject$;
    };
    /**
     * Find TabItem by subject
     * @param subject of tab to find
     */
    TabService.prototype.findTabItemBySubject = function (subject) {
        return this.tabItems.find(function (elem) {
            return elem.subject === subject;
        });
    };
    /**
     * Find TabItem by couponCode
     * @param couponCode of tab to find
     */
    TabService.prototype.findTabItemByCouponCode = function (couponCode) {
        return this.tabItems.find(function (elem) {
            return elem.couponCode === couponCode;
        });
    };
    /**
     * Find active tabe
     */
    TabService.prototype.findActiveTab = function () {
        for (var _i = 0, _a = this.tabItems; _i < _a.length; _i++) {
            var tabItem = _a[_i];
            if (tabItem.isActive) {
                return tabItem;
            }
        }
    };
    TabService.prototype.existsInTabItems = function (tabItem) {
        var exists = false;
        if (typeof tabItem === 'undefined') {
            return exists;
        }
        switch (tabItem.type) {
            case 'user':
                exists = !!this.findTabItemBySubject(tabItem.subject);
                break;
            case 'coupon':
                exists = !!this.findTabItemByCouponCode(tabItem.couponCode);
                break;
        }
        return exists;
    };
    /**
     * Removes Tab from tabItems and emits the new lost from the tabSubject
     * @param tabItem to remove
     */
    TabService.prototype.removeTab = function (tabItem) {
        var tabItemToRemove;
        var compareKey = 'subject';
        switch (tabItem.type) {
            case 'user':
                tabItemToRemove = this.findTabItemBySubject(tabItem.subject);
                compareKey = 'subject';
                break;
            case 'coupon':
                tabItemToRemove = this.findTabItemByCouponCode(tabItem.couponCode);
                compareKey = 'couponCode';
                break;
        }
        // filter out tap
        if (typeof this.tabItems !== 'undefined') {
            this.tabItems = this.tabItems.filter(function (item) { return item[compareKey] !== tabItemToRemove[compareKey]; });
        }
        this.tabSubject$.next(this.tabItems);
        this.storeTabs();
        // If no tabitems are left - move to extended search
        if (this.tabItems.length <= 0) {
            return this.router.navigateByUrl('/extended-search');
        }
        if (tabItem.isActive) {
            if (typeof this.previouslyActiveItem !== 'undefined' && this.existsInTabItems(this.previouslyActiveItem)) {
                this.navigateToTab(this.previouslyActiveItem);
                this.setActiveTab(this.previouslyActiveItem);
            }
            else {
                if (this.tabItems.length > 0) {
                    this.navigateToTab(this.tabItems[0]);
                    this.setActiveTab(this.tabItems[0]);
                }
            }
        }
    };
    /**
     * Stores TabItems to session storage
     */
    TabService.prototype.storeTabs = function () {
        var location = window.location.hostname;
        sessionStorage.setItem('tabs:' + location, JSON.stringify(this.tabItems));
    };
    /**
     * Loads tabs from session storage
     */
    TabService.prototype.loadTabs = function () {
        var location = window.location.hostname;
        var tabString = sessionStorage.getItem('tabs:' + location);
        var tabs = JSON.parse(tabString);
        this.tabItems = tabs ? tabs : [];
        var activeTab = this.findActiveTab();
        if (activeTab) {
            this.activeTabItem = activeTab;
        }
        this.tabSubject$.next(this.tabItems);
    };
    /**
     * Deactivate Tabs
     */
    TabService.prototype.deactivateAllTabs = function () {
        if (this.tabItems) {
            // duplicate array of current tabs
            var newTabItems = this.tabItems.slice();
            for (var _i = 0, newTabItems_1 = newTabItems; _i < newTabItems_1.length; _i++) {
                var tabItem = newTabItems_1[_i];
                tabItem.isActive = false;
            }
            this.tabItems = newTabItems;
        }
    };
    /**
     * Clears the tabs storage
     */
    TabService.prototype.clearTabs = function () {
        this.tabItems = [];
        this.tabSubject$.next(this.tabItems);
        var location = window.location.hostname;
        sessionStorage.removeItem('tabs:' + location);
    };
    /**
     * Open route with tab information
     * @param tabItem holding the url params
     */
    TabService.prototype.navigateToTab = function (tabItem) {
        var extras = {};
        var commands = [];
        this.messengerService.clearNotifications();
        switch (tabItem.type) {
            case 'user':
                commands = ['user/details'];
                extras = {
                    relativeTo: this.activeRoute,
                    queryParams: { subject: tabItem.subject }
                };
                break;
            case 'coupon':
                commands = ['coupon/details'];
                extras = {
                    queryParams: { couponCode: tabItem.couponCode }
                };
                break;
        }
        this.router.navigate(commands, extras);
    };
    TabService.ngInjectableDef = i0.defineInjectable({ factory: function TabService_Factory() { return new TabService(i0.inject(i1.ActivatedRoute), i0.inject(i1.Router), i0.inject(i2.MessengerService)); }, token: TabService, providedIn: "root" });
    return TabService;
}());
export { TabService };
