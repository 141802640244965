/**
 * ioneaccess Order System Coordinator (OSC) API
 * API for active external 'Order System Coordinator' access. This document defines an interface for pushing orders into the ionaccess order system.
 *
 * OpenAPI spec version: 1.4.6
 * Contact: florian.mischler@ionesoft.ch
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CouponActivateRequest1 } from '../model/couponActivateRequest1';
import { CouponActivateResponse1 } from '../model/couponActivateResponse1';
import { OrderCreateForProductRequest1 } from '../model/orderCreateForProductRequest1';
import { OrderCreateForProductResponse1 } from '../model/orderCreateForProductResponse1';
import { OrderSyncForUserRequest1 } from '../model/orderSyncForUserRequest1';
import { OrderSyncForUserResponse1 } from '../model/orderSyncForUserResponse1';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OSCClient1Service {

    protected basePath = 'https://igate.beook.work/osc/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Redeem coupon
     * Activate coupon sent from app
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param CouponActivateRequest1 Request for activating coupons
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public couponActivate(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CouponActivateRequest1: CouponActivateRequest1, observe?: 'body', reportProgress?: boolean): Observable<CouponActivateResponse1>;
    public couponActivate(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CouponActivateRequest1: CouponActivateRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponActivateResponse1>>;
    public couponActivate(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CouponActivateRequest1: CouponActivateRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponActivateResponse1>>;
    public couponActivate(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, CouponActivateRequest1: CouponActivateRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling couponActivate.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling couponActivate.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling couponActivate.');
        }

        if (CouponActivateRequest1 === null || CouponActivateRequest1 === undefined) {
            throw new Error('Required parameter CouponActivateRequest1 was null or undefined when calling couponActivate.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CouponActivateResponse1>(`${this.basePath}/coupon/activate`,
            CouponActivateRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get license order for user by subject, product-reference and order systems.
     * Get license order for user by subject, product-reference and order systems. Needed for free products, which do not need coupon code activation.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param OrderCreateForProductRequest1 User, product-reference and ordersystems.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orderCreateForProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderCreateForProductRequest1: OrderCreateForProductRequest1, observe?: 'body', reportProgress?: boolean): Observable<OrderCreateForProductResponse1>;
    public orderCreateForProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderCreateForProductRequest1: OrderCreateForProductRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderCreateForProductResponse1>>;
    public orderCreateForProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderCreateForProductRequest1: OrderCreateForProductRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderCreateForProductResponse1>>;
    public orderCreateForProduct(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderCreateForProductRequest1: OrderCreateForProductRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling orderCreateForProduct.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling orderCreateForProduct.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling orderCreateForProduct.');
        }

        if (OrderCreateForProductRequest1 === null || OrderCreateForProductRequest1 === undefined) {
            throw new Error('Required parameter OrderCreateForProductRequest1 was null or undefined when calling orderCreateForProduct.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderCreateForProductResponse1>(`${this.basePath}/order/createForProduct`,
            OrderCreateForProductRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get license order for user by subject w/ order systems.
     * Get license order for user by subject w/ order systems.
     * @param X_Ia_Correlation_Id A UUID composed of IP and timestamp. Client creates passes ID to the first call.
     * @param X_Ia_Subject UUID of user
     * @param X_Ia_Roles Role of caller. One or many roles
     * @param OrderSyncForUserRequest1 User w/ ordersystems.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orderSyncForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderSyncForUserRequest1: OrderSyncForUserRequest1, observe?: 'body', reportProgress?: boolean): Observable<OrderSyncForUserResponse1>;
    public orderSyncForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderSyncForUserRequest1: OrderSyncForUserRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderSyncForUserResponse1>>;
    public orderSyncForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderSyncForUserRequest1: OrderSyncForUserRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderSyncForUserResponse1>>;
    public orderSyncForUser(X_Ia_Correlation_Id: string, X_Ia_Subject: string, X_Ia_Roles: string, OrderSyncForUserRequest1: OrderSyncForUserRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Ia_Correlation_Id === null || X_Ia_Correlation_Id === undefined) {
            throw new Error('Required parameter X_Ia_Correlation_Id was null or undefined when calling orderSyncForUser.');
        }

        if (X_Ia_Subject === null || X_Ia_Subject === undefined) {
            throw new Error('Required parameter X_Ia_Subject was null or undefined when calling orderSyncForUser.');
        }

        if (X_Ia_Roles === null || X_Ia_Roles === undefined) {
            throw new Error('Required parameter X_Ia_Roles was null or undefined when calling orderSyncForUser.');
        }

        if (OrderSyncForUserRequest1 === null || OrderSyncForUserRequest1 === undefined) {
            throw new Error('Required parameter OrderSyncForUserRequest1 was null or undefined when calling orderSyncForUser.');
        }

        let headers = this.defaultHeaders;
        if (X_Ia_Correlation_Id !== undefined && X_Ia_Correlation_Id !== null) {
            headers = headers.set('X-Ia-Correlation-Id', String(X_Ia_Correlation_Id));
        }
        if (X_Ia_Subject !== undefined && X_Ia_Subject !== null) {
            headers = headers.set('X-Ia-Subject', String(X_Ia_Subject));
        }
        if (X_Ia_Roles !== undefined && X_Ia_Roles !== null) {
            headers = headers.set('X-Ia-Roles', String(X_Ia_Roles));
        }

        // authentication (AuthorizationHeader) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderSyncForUserResponse1>(`${this.basePath}/order/syncForUser`,
            OrderSyncForUserRequest1,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
