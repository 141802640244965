import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {GlobalResultResponse} from '../modules/api/aua_angular-client';
import {MessengerService} from '../modules/shared/service/messenger.service';
import {AuthService} from '../modules/authentication/services/auth.service';

@Injectable()
export class HTTPGlobalResponse implements HttpInterceptor {
    constructor(private messengerService: MessengerService, private authService: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(req).pipe(
            tap(
                event => {

                    if (event instanceof HttpResponse) {
                        // Handle Global Response
                        if (event) {
                            if (event.body.global_response) {
                                const globalResponse: GlobalResultResponse = event.body.global_response;
                                this.messengerService.handleGlobalResponse(globalResponse);
                            }
                        }
                    }
                },
                error => {
                    // 401 is used for expired tokens
                    if (error.status !== 401) {
                        throw new Error(error.message);
                    } else {
                        // Make sure user gets logged out if token is expired.
                        // oicdmanager event addAccessTokenExpired fails to raise sometimes.
                        if (error.error.indexOf('token is expired')) {
                            this.authService.startSignOut('AUTH_TOKEN_EXPIRED_LOGOUT');
                        }
                    }
                }
            )
        );
    }
}
