<table fxHide.xs class="s-width-full" *ngIf="tableSource" mat-table [dataSource]="tableSource">

    <!-- E-Mail Column -->
    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>E-Mail</th>
        <td mat-cell *matCellDef="let element">
            <a class="hd--ext-search-link" [routerLink]="['/user/details']"
               [queryParams]="{subject: element.subject}">{{element.email}}</a>
        </td>
    </ng-container>

    <!-- Subject Column -->
    <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef>Subject</th>
        <td mat-cell *matCellDef="let element">
            {{element.subject}}
        </td>
    </ng-container>

    <!-- Created_at Column -->
    <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef>Erstellungsdatum</th>
        <td mat-cell *matCellDef="let element">
            <!--{{element.created_at}}-->
        </td>
    </ng-container>

    <!-- Nickname Column -->
    <ng-container matColumnDef="nickname">
        <th mat-header-cell *matHeaderCellDef>Benutzername</th>
        <td mat-cell *matCellDef="let element">
            {{element.nickname}}
        </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">
            {{element.given_name}} {{element.middle_name}} {{element.family_name}}
        </td>
    </ng-container>

    <!-- Mandant Group Column -->
    <ng-container matColumnDef="mandant_groups">
        <th mat-header-cell *matHeaderCellDef>Mandanten-Gruppen</th>
        <td mat-cell *matCellDef="let element">
            <ul class="hd--ext-search-table-list">
                <li class="hd--ext-search-table-list-item" *ngFor="let mandantGroup of element.mandant_groups">
                    {{mandantGroup.name}}
                </li>
            </ul>
        </td>
    </ng-container>

    <!-- Provider Column -->
    <ng-container matColumnDef="providers">
        <th mat-header-cell *matHeaderCellDef>Provider</th>
        <td mat-cell *matCellDef="let element">
            <ul class="hd--ext-search-table-list">
                <li class="hd--ext-search-table-list-item" *ngFor="let provider of element.providers">
                    {{provider}}
                </li>
            </ul>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>


<!--- Mobile Table -->
<mat-table fxHide.gt-xs [dataSource]="tableSource" class="s-width-full">

    <ng-container cdkColumnDef="name">
        <mat-header-cell *cdkHeaderCellDef>name</mat-header-cell>
        <mat-cell *cdkCellDef="let row">
            {{row.given_name}} {{row.middle_name}} {{row.family_name}}
        </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="email">
        <mat-header-cell *cdkHeaderCellDef>email</mat-header-cell>
        <mat-cell *cdkCellDef="let row">
            <a class="hd--ext-search-link" [routerLink]="['/user/details']"
               [queryParams]="{subject: row.subject}">{{row.email}}</a>
        </mat-cell>
    </ng-container>

    <mat-row *cdkRowDef="let row; columns: displayedMobileColumns"></mat-row>
</mat-table>
