/**
 *  WARNING: IMPORT THIS MODULE IN APP-MODULE ONLY! forRoot() - Modules should only be provided once!
 */

import {NgModule} from '@angular/core';
import {Settings} from '../../models/settings';
import {APP_CONFIGURATION} from '../../service/settings.http.service';
import {
  ApiModule as AuaAPIModule,
  BASE_PATH as AUA_BASE_PATH,
  Configuration,
  ConfigurationParameters
} from './aua_angular-client';
import {
  ApiModule as BiloAPIModule,
  BASE_PATH as BILO_BASE_PATH,
} from './bilo_angular-client';
import {
  BASE_PATH as IDP_BASE_PATH,
} from './idp_angular-client';
import {HDIdpApiModule} from './idp_angular-client/custom/hd-idp.module';
import {
  ApiModule as LimaAPIModule,
  BASE_PATH as LIMA_BASE_PATH,
} from './lima_angular-client';
import {
  ApiModule as OscAPIModule,
  BASE_PATH as OSC_BASE_PATH,
} from './osc_angular-client';
import {
  ApiModule as ProsAPIModule,
  BASE_PATH as PROS_BASE_PATH,
} from './pros_angular-client';

export function ConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    apiKeys: {}
  };
  return new Configuration(params);
}

@NgModule({
  declarations: [],
  imports: [
    LimaAPIModule.forRoot(ConfigFactory),
    AuaAPIModule.forRoot(ConfigFactory),
    ProsAPIModule.forRoot(ConfigFactory),
    OscAPIModule.forRoot(ConfigFactory),
    HDIdpApiModule.forRoot(ConfigFactory),
    BiloAPIModule.forRoot(ConfigFactory)
  ],
  providers: [
    {
      provide: OSC_BASE_PATH,
      useFactory: (settings: Settings) => settings.basePathOSC,
      deps: [APP_CONFIGURATION]
    },
    {
      provide: IDP_BASE_PATH,
      useFactory: (settings: Settings) => settings.basePathIDP,
      deps: [APP_CONFIGURATION]
    },
    {
      provide: AUA_BASE_PATH,
      useFactory: (settings: Settings) => settings.basePathAuA,
      deps: [APP_CONFIGURATION]
    },
    {
      provide: PROS_BASE_PATH,
      useFactory: (settings: Settings) => settings.basePathPROS,
      deps: [APP_CONFIGURATION]
    },
    {
      provide: LIMA_BASE_PATH,
      useFactory: (settings: Settings) => settings.basePathLIMA,
      deps: [APP_CONFIGURATION]
    },
    {
      provide: BILO_BASE_PATH,
      useFactory: (settings: Settings) => settings.basePathBILO,
      deps: [APP_CONFIGURATION]
    },
  ]
})
export class ApiModule {
}
