import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Settings} from '../../../../models/settings';
import {APP_CONFIGURATION} from '../../../../service/settings.http.service';
import {MobileFlyoutComponent} from '../mobile-flyout/mobile-flyout.component';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss']
})


export class MobileHeaderComponent implements OnInit {
  @ViewChild(MobileFlyoutComponent) flyout: MobileFlyoutComponent;
  logoURL: string;

  constructor(@Inject(APP_CONFIGURATION) public readonly configuration: Settings) {
    this.logoURL = configuration.theme.logoURL;
  }

  ngOnInit() {
  }

  onMoblieMenuClick() {
    this.flyout.toggle();
  }
}
